import { CssBaseline } from "@mui/material";
import { useNavigate, useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import routes from "./routes";
import React, { useEffect, useState } from "react";
import { async } from "q";

const App = () => {
  const navigate = useNavigate();
  const content = useRoutes(routes);

  useEffect(
    () => {
      async function fetchData() {
        const user = await localStorage.getItem("user_data");
        const location = await localStorage.getItem("location-data");
        console.log(user);
        if (user) {
        } else {
          navigate("/session/signin");
        }

        if (location) {
        } else {
          var data = {
            id: 37,
            area: "Pernambut",
            city: "Vellore",
            country: "8",
            currency: "Rupees",
            currency_equivalent_to_ro: "1",
            remarks: "Testing",
            lat: "19.204834816311962",
            lang: "81.98272705078125",
            location_image:
              "uploads\\location_image-1687927543806-104052375.png"
          };
          localStorage.setItem("location-data", JSON.stringify(data));
        }
      }
      fetchData();
    },
    [window.location.pathname]
  );

  return (
    <div>
      <MatxTheme>
        <CssBaseline />
        {content}
      </MatxTheme>
    </div>
  );
};

export default App;
