import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Fragment } from "react";
import countrydata from "../../../assets/country.js";
import Swal from "sweetalert2";
import axios from "axios";
import {
  CATEGORY_LIST,
  CLIENT_ADD,
  CLIENT_UPDATE
} from "app/utils/ApiRoutes.js";
const AddClient = ({
  open,
  handleClose,
  handleOpen,
  callBack,
  type,
  setType,
  udata
}) => {
  const [clientname, setClientName] = useState("");
  const [famousname, setFamousName] = useState("");
  const [clientimg, setClientImg] = useState("");
  const [ownership, setOwnerShip] = useState("Local");
  const [category, setCategory] = useState(4);
  const [branches, setBranches] = useState("");
  const [website, setWebsite] = useState("");
  const [fax, setFax] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postalcode, setPostalCode] = useState("");
  const [pobox, setPoBox] = useState("");
  const [error, setError] = useState("");

  const [catdata, setCatData] = useState([
    {
      cat_id: 4,
      location_id: null,
      category: "Hypermarkets",
      created_by: 35,
      created_date: "2022-01-14T10:46:49.000Z"
    },
    {
      cat_id: 3,
      location_id: null,
      category: "Bakeries",
      created_by: 35,
      created_date: "2022-01-14T10:46:31.000Z"
    },
    {
      cat_id: 2,
      location_id: null,
      category: "Hotels",
      created_by: 35,
      created_date: "2022-01-02T10:57:45.000Z"
    },
    {
      cat_id: 1,
      location_id: null,
      category: "Restaurants",
      created_by: 35,
      created_date: "2021-12-29T10:22:36.000Z"
    }
  ]);

  const onSubmit = () => {};

  const onClose = () => {
    callBack("close");
    setError("");
    setType("");
    handleClose();
  };

  const ListCategory = () => {
    let config = {
      method: "GET",
      url: CATEGORY_LIST
    };

    axios
      .request(config)
      .then(response => {
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          setCatData(resdata);
        } else {
          setCatData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setCatData([]);
      });
  };

  const isNotValidate = () => {
    if (
      !clientname ||
      !famousname ||
      !clientimg ||
      !ownership ||
      !category ||
      !branches ||
      !website ||
      !fax ||
      !address ||
      !city ||
      // !state ||
      !country ||
      !postalcode ||
      !pobox
    ) {
      return true;
    } else {
      return false;
    }
  };

  const clearData = () => {
    setClientName("");
    setFamousName("");
    setClientImg("");
    // setOwnerShip("");
    // setCategory("");
    setBranches("");
    setWebsite("");
    setFax("");
    setAddress("");
    setCity("");
    setState("");
    setCountry("");
    setPostalCode("");
    setPoBox("");
    setError("");
  };

  useEffect(
    () => {
      if (type == 1) {
        console.log(udata);
        if (udata.client_name) {
          setClientName(udata.client_name);
        }
        if (udata.famous_name) {
          setFamousName(udata.famous_name);
        }

        if (udata.logo) {
          setClientImg(udata.logo);
        }

        if (udata.ownership) {
          setOwnerShip(udata.ownership);
        }
        if (udata.category) {
          setCategory(udata.category);
        }

        if (udata.branches) {
          setBranches(udata.branches);
        }

        if (udata.website) {
          setWebsite(udata.website);
        }

        if (udata.client_address) {
          setAddress(udata.client_address);
        }
        setFax("");

        if (udata.city) {
          setCity(udata.city);
        }
        if (udata.state) {
          setState(udata.state);
        }
        if (udata.country) {
          setCountry(udata.country);
        }

        if (udata.postal_code) {
          setPostalCode(udata.postal_code);
        }

        if (udata.po_box) {
          setPoBox(udata.po_box);
        }
      } else {
        clearData();
      }
    },
    [type]
  );

  const onAddUpdateClient = async () => {
    if (isNotValidate()) {
      setError("Required all fields");
      return;
    } else {
      setError("");
    }

    var userdata = await localStorage.getItem("user_data");
    userdata = JSON.parse(userdata);
    const formData = new FormData();

    formData.append("image", clientimg);
    formData.append("client_name", clientname);
    formData.append("category", category);
    formData.append("client_address", address);
    formData.append("website", website);
    formData.append("ownership", ownership);
    formData.append("branches", branches);
    formData.append("created_by", userdata.id);
    formData.append("city", city);
    formData.append("country", country);
    formData.append("po_box", pobox);
    formData.append("postal_code", postalcode);
    formData.append("famous_name", famousname);
 
   const API_URL = type == 0 ? CLIENT_ADD : CLIENT_UPDATE + udata.client_id;
   
    let config = {
      method: "POST",
      url: API_URL,
      data: formData
    };

    axios
      .request(config)
      .then(response => {
        onClose();
        callBack(1);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
        Client
      </DialogTitle>
      <DialogContent>
        <div className="mt-20">Client Name</div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          type="text"
          margin="dense"
          placeholder="Client Name"
          onChange={e => setClientName(e.target.value)}
          value={clientname}
          focused={false}
        />

        <div className="mt-20">Famous Name</div>
        <TextField
          fullWidth
          autoFocus
          id="number"
          type="text"
          margin="dense"
          placeholder="Famous Name"
          onChange={e => setFamousName(e.target.value)}
          value={famousname}
          focused={false}
        />
        <div className="mt-20">Client Image</div>
        <TextField
          fullWidth
          autoFocus
          id="imge"
          type="file"
          margin="dense"
          onChange={e => setClientImg(e.target.files[0])}
          focused={false}
        />

        <div className="mt-20">Ownership</div>
        <TextField
          id="ownership"
          name="ownership"
          fullWidth
          select
          margin="dense"
          focused={false}
          SelectProps={{
            native: true
          }}
          value={ownership}
          onChange={e => setOwnerShip(e.target.value)}
        >
          <option value={"Local"}> Local </option>
          <option value={"International"}> International </option>
        </TextField>

        <div className="mt-20">Category</div>
        <TextField
          id="category"
          name="category"
          fullWidth
          select
          margin="dense"
          focused={false}
          SelectProps={{
            native: true
          }}
          value={category}
          onChange={e=>setCategory(e.target.value)}
         >
          {catdata.map(item =>
            <option value={item.cat_id}>
              {" "}{item.category}{" "}
            </option>
          )}
        </TextField>
        <div className="mt-20">Branches</div>
        <TextField
          fullWidth
          autoFocus
          id="number"
          type="number"
          margin="dense"
          placeholder="Branches"
          onChange={e => setBranches(e.target.value)}
          value={branches}
          focused={false}
        />
        <div className="mt-20">Website</div>
        <TextField
          fullWidth
          autoFocus
          id="number"
          type="text"
          margin="dense"
          placeholder="Website"
          onChange={e => setWebsite(e.target.value)}
          value={website}
          focused={false}
        />
        
        <div className="mt-20">Fax</div>
        
        <TextField
          fullWidth
          autoFocus
          id="number"
          type="text"
          margin="dense"
          placeholder="Fax"
          onChange={e => setFax(e.target.value)}
          value={fax}
          focused={false}
        />

        <div className="mt-20">Address</div>
        <TextField
          fullWidth
          autoFocus
          id="number"
          type="text"
          margin="dense"
          placeholder="Address"
          onChange={e => setAddress(e.target.value)}
          value={address}
          focused={false}
        />
        <div className="mt-20">City</div>
        <TextField
          fullWidth
          autoFocus
          id="number"
          type="text"
          margin="dense"
          placeholder="City"
          onChange={e => setCity(e.target.value)}
          value={city}
          focused={false}
        />
        <div className="mt-20">State</div>
        <TextField
          fullWidth
          autoFocus
          id="number"
          type="text"
          margin="dense"
          placeholder="State"
          onChange={e => setState(e.target.value)}
          value={state}
          focused={false}
        />
        <div className="mt-20">Country</div>
        <TextField
          fullWidth
          autoFocus
          id="number"
          type="text"
          margin="dense"
          placeholder="Country"
          onChange={e => setCountry(e.target.value)}
          value={country}
          focused={false}
        />
        <div className="mt-20">Postal Code</div>
        <TextField
          fullWidth
          autoFocus
          id="number"
          type="text"
          margin="dense"
          placeholder="Postal Code"
          onChange={e => setPostalCode(e.target.value)}
          value={postalcode}
          focused={false}
        />
        <div className="mt-20">PO Box</div>
        <TextField
          fullWidth
          autoFocus
          id="number"
          type="text"
          margin="dense"
          placeholder="PO Box"
          onChange={e => setPoBox(e.target.value)}
          value={pobox}
          focused={false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => onAddUpdateClient()}
          color="primary"
        >Submit
        </Button>
      </DialogActions>

      <span style={{ textAlign: "center", marginBottom: "5px", color: "red" }}>
        {error}
      </span>
      
    </Dialog>
  );
};

export default AddClient;
