import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Select,
  MenuItem,
  Avatar,
  Fab,
  TablePagination
} from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Fragment } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import {
  CHEMICAL_ADD,
  CHEMICAL_UPDATE,
  CHEMICAL_DELETE,
  CHEMICAL_LIST,
  CHEMICAL_TAKEN,
  GET_TANK,
  GET_TANK_DETAILS,
  LIST_CHEMICAL_HISTORY
} from "app/utils/ApiRoutes.js";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: 0,
  minWidth: "20px",
  width: "40px"
}));

const Chemicals = () => {
  const { palette } = useTheme();
  const [loading, setLoading] = useState(false);
  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [type, setType] = useState(false);
  const [selecteditem, setSelectedItem] = useState();
  const [updatedata, setUpdateData] = useState();
  const [chemicaldata, setChemicalData] = useState([]);
  const [packetsize, setPacketSize] = useState();
  const [packetserror, setPacketsError] = useState("");
  const [lowstack, setLowStack] = useState();

  const handleClickOpen = (type, item) => {
    setType(type);
    if (type == 1) {
      setUpdateData(item);
      setPacketSize(item.packet_size);
      setLowStack(item.low_stack);
    } else {
      setUpdateData();
      setPacketSize();
      setLowStack();
    }
    setPacketsError("");
    setOpen(true);
  };

  const onAddUpdate = async () => {
    setPacketsError("");
    if (!packetsize || !lowstack) {
      setPacketsError("Required all fields");
      return;
    }
    var locationdata = await localStorage.getItem("location-data");
    locationdata = JSON.parse(locationdata);

    setLoading(true)
    var data = {
      location_id: locationdata.id,
      packet_size: packetsize,
      low_stack: lowstack
    };

    const API_URL = type == 0 ? CHEMICAL_ADD : CHEMICAL_UPDATE + updatedata.id;
    let config = {
      method: "POST",
      url: API_URL,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        setLoading(false)
        console.log(response.data);
        ListChemical();
        handleClose();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        setLoading(false)
        var errordata = error.response.data;
        console.log(errordata);
        if (errordata && errordata.message) {
          alert(errordata.message)
        }
      });
  };

  const ListChemical = () => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    let config = {
      method: "GET",
      url: CHEMICAL_LIST + "/" + locid
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.data);
        var resdata = response.data.data;
        if (resdata.length > 0) {
          setChemicalData(resdata);
        } else {
          setChemicalData([]);
        }
      })
      .catch(error => {
        setChemicalData([]);
        console.log(error);
      });
  };

  const ChemicalDelete = id => {
    let config = {
      method: "GET",
      url: CHEMICAL_DELETE + id
    };

    axios
      .request(config)
      .then(response => {
        ListChemical();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Deleted sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDelete = id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        ChemicalDelete(id);
      }
    });
  };

  useEffect(() => {
    ListChemical();
    ListTank();
    ListChemicalHistory();
  }, []);

  const [detailopen, setDetailOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState();
  const handleDetailClose = () => setDetailOpen(false);
  const handleDetailOpen = item => {
    setSelectedDetail(item);
    setDetailOpen(true);
  };

  const [addopen, setAddOpen] = useState(false);
  const handleAddClose = () => setAddOpen(false);
  const handleAddOpen = () => {
    handleDetailClose();
    setAddError("");
    setAddOpen(true);
  };

  const [packetcount, setPacketCount] = useState("");
  const [price, setPrice] = useState("");
  const [date, setDate] = useState("");

  const [takeopen, setTakeOpen] = useState(false);
  const handleTakeClose = () => setTakeOpen(false);
  const handleTakeOpen = () => {
    handleDetailClose();
    setTakenError("");
    setTakeOpen(true);
  };

  const [reqpacket, setReqPacket] = useState();
  const [bagno, setBagNo] = useState();
  const [client, setClient] = useState();
  const [purpose, setPurpose] = useState();
  const [tanktype, setTankType] = useState("");
  const [tanksn, setTankSN] = useState("");

  const [tankdata, setTankData] = useState("");
  const [serialnodata, setSerialNodata] = useState("");

  const [adderror, setAddError] = useState("");
  const [takenerror, setTakenError] = useState("");

  const ListTank = () => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    
    var data = {
      location_id: locid
    };

    let config = {
      method: "POST",
      url: GET_TANK,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.data);
        var resdata = response.data.data;
        if (resdata.length > 0) {
          ListSerialNo(resdata[0].id);
          setTankData(resdata);
        } else {
          setTankData([]);
        }
      }).catch(error => {
        console.log(error);
        setTankData([]);
      });
  };

  const ListSerialNo = ID => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    var data = {
      tank_id: ID,
      location_id: locid
    };
    let config = {
      method: "POST",
      url: GET_TANK_DETAILS,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.data);
        var resdata = response.data.data;
        if (resdata.length > 0) {
          setTankSN(resdata[0].id)
          setSerialNodata(resdata);
        } else {
          setSerialNodata([]);
        }
      })
      .catch(error => {
        console.log(error);
        setSerialNodata([]);
      });
  };

  const AddTakeChemicals = type => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    var userid = userdata && userdata.id ? userdata.id : "";
    if (type == 0) {
      if (!packetcount || !price || !date) {
        setAddError("Required all fields");
        return;
      }
    }
    if (type == 1) {
      if (!reqpacket || !purpose || !tanksn || !bagno) {
        setTakenError("Required all fields");
        return;
      }
    }

if(reqpacket > selectedDetail.stock){
  Swal.fire("Your stock Required higher than Selected Packet ")
  handleAddClose();
  handleTakeClose();
  ListChemical();
  ListChemicalHistory();
  setLoading(false)
return
}

    setLoading(true)

    var addData = {
      no_of_pieces: packetcount,
      price_per_piece: price,
      packet_size: selectedDetail.packet_size,
      date: date,
      employee_id: userid,
      location_area_id: locid,
      add_or_taken: 0,
      jr_id: "",
      purpose: "",
      tank_serial_no: "",
      bag_no: "",
      low_stack: ""
    };

    var takeData = {
      no_of_pieces: reqpacket,
      price_per_piece: "",
      packet_size: selectedDetail.packet_size,
      date: new Date().toISOString(),
      employee_id: userid,
      location_area_id: locid,
      add_or_taken: 1,
      jr_id: "",
      purpose: purpose,
      tank_serial_no: tanksn,
      bag_no: bagno,
      low_stack: ""
    };

    let config = {
      method: "POST",
      url: CHEMICAL_TAKEN,
      data: type == 0 ? addData : takeData
    };

    axios
      .request(config)
      .then(response => {
        setLoading(false)
        console.log(response.data);
        handleAddClose();
        handleTakeClose();
        ListChemical();
        ListChemicalHistory();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        setLoading(false)
        console.log(error);
      });
  };

  const [historyData, setHistoryData] = useState("");
  const ListChemicalHistory = () => {
    
    var locid = locationdata && locationdata.id ? locationdata.id : "";

    let config = {
      method: "GET",
      url: LIST_CHEMICAL_HISTORY+locid
    };

    axios.request(config)
      .then(response => {       
        var resdata = response.data.response.data;    
        if(resdata.length > 0){
          setHistoryData(resdata);
        }else{
          setHistoryData([]);
        }
      }).catch(error => {
         setHistoryData([]);
      });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div>
      <Fragment>
        <ContentBox className="analytics">
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <Title>Chemicals</Title>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                    onClick={() => handleClickOpen(0, "")}
                  >
                    <Icon>add</Icon>
                  </Fab>
                </CardHeader>

                <Box>
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={2}>
                          Chemical
                        </TableCell>
                        <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {chemicaldata &&
                        chemicaldata.map(item =>
                          <TableRow hover>
                            <TableCell
                              align="left"
                              colSpan={2}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <div className="flex-column">
                                {item.packet_size} Kg
                                <div className="text-secondory">
                                  Available Bags:<a className="text-secondory text-orange">
                                    {item.stock}
                                  </a>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell sx={{ px: 0 }} colSpan={2}>
                              <div>
                                <div>
                                  <IconButton>
                                    {/* <Icon
                                      color="primary"
                                      onClick={() => handleClickOpen(1, item)}
                                    >
                                      edit
                                    </Icon> */}
                                  </IconButton>
                                  <IconButton>
                                    <Icon
                                      style={{ color: "red" }}
                                      onClick={() => onDelete(item.id)}
                                    >
                                      delete
                                    </Icon>
                                  </IconButton>
                                  <IconButton>
                                    <Icon
                                      onClick={() => handleDetailOpen(item)}
                                      style={{ color: "#795548" }}
                                    >
                                      remove_red_eye
                                    </Icon>
                                  </IconButton>
                                  <IconButton>
                                    <Icon style={{ color: "#3F51B5" }}>
                                      loop
                                    </Icon>
                                  </IconButton>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
                </Box>
              </Card>
            </Grid>

            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <Title>History</Title>
                  <Fab
                    size="small"
                    color="white"
                    style={{ background: "white", boxShadow: "none" }}
                  />
                </CardHeader>

                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={1} />
                        <TableCell className="pl-0" sx={{ px: 3 }} colSpan={2}>
                          Chemical
                        </TableCell>
                        <TableCell className="pl-0" sx={{ px: 3 }} colSpan={2}>
                          Date
                        </TableCell>
                        <TableCell className="pl-0" sx={{ px: 3 }} colSpan={2}>
                          Purpose
                        </TableCell>
                        <TableCell className="pl-0" sx={{ px: 0 }} colSpan={2}>
                          BY
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody style={{ maxHeight: "40vh", height: "40vh" }}>
                      {historyData &&
                        historyData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map(item =>
                            <TableRow hover>
                              <TableCell
                                align="left"
                                colSpan={1}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                {item.add_or_taken == "0"
                                  ? <IconButton>
                                      <Icon className="text-green">
                                        arrow_upward
                                      </Icon>
                                    </IconButton>
                                  : <IconButton>
                                      <Icon className="text-orange">
                                        arrow_downward
                                      </Icon>
                                    </IconButton>}
                              </TableCell>

                              <TableCell
                                align="left"
                                colSpan={2}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                <div className="flex-column">
                                  {item.packet_size}Kg
                                  <div className="text-secondory">
                                    QTY :{" "}
                                    <a className="text-secondory text-orange">
                                      {item.no_of_pieces}
                                    </a>
                                  </div>
                                </div>
                              </TableCell>

                              <TableCell
                                align="left"
                                colSpan={2}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                {new Date(item.created_at).toLocaleDateString()}
                              </TableCell>

                              <TableCell
                                align="left"
                                colSpan={2}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                {item.purpose.slice(0, 10)}
                              </TableCell>

                              <TableCell
                                align="left"
                                colSpan={2}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                user
                              </TableCell>
                            </TableRow>
                          )}
                    </TableBody>
                  </ProductTable>
                  {/* {!historyData && <h1 className="no-data">No Data Found</h1>} */}

                  <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={historyData.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </ContentBox>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "300px" }}>
            {type == 0 ? "Add" : "Update"} Chemical
          </DialogTitle>

          <DialogContent>
           
            <div className="mt-20">Packet Size (kg)</div>

            <TextField
              fullWidth
              autoFocus
              id="name"
              type="number"
              margin="dense"
              focused={false}
              onChange={e => setPacketSize(e.target.value.split(' ').join(''))}
              value={packetsize}
            />

            <div className="mt-20">Low Stack</div>

            <TextField
              fullWidth
              autoFocus
              id="name"
              type="number"
              margin="dense"
              focused={false}
              onChange={e => setLowStack(e.target.value.split(' ').join(''))}
              value={lowstack}
            />

          </DialogContent>

          {type == 0 && <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => onAddUpdate()}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>}
          <span
            style={{ textAlign: "center", marginBottom: "5px", color: "red" }}
          >
            {packetserror}
          </span>
        </Dialog>

        {/* Detail */}
        <Dialog
          open={detailopen}
          onClose={handleDetailClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{
              width: "300px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            Packet Details
            <IconButton>
              <Icon style={{ color: "red" }} onClick={handleDetailClose}>
                close
              </Icon>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <div style={{ display: "flex" }}>
              <div style={{ width: "150px" }}>Packet Size </div>
              <a>:</a>
              <div style={{ marginLeft: "5px" }}>
                {" "}{selectedDetail && selectedDetail.packet_size}kg{" "}
              </div>
            </div>

            <div style={{ display: "flex" }} className="mt-20">
              <div style={{ width: "150px" }}>Available Packets </div>
              <a>:</a>
              <div style={{ marginLeft: "5px" }}>
                {" "}{selectedDetail && selectedDetail.stock}{" "}
              </div>
            </div>
            <div style={{ display: "flex" }} className="mt-20">
              <div style={{ width: "150px" }}>Overall Weight</div>
              <a>:</a>
              <div style={{ marginLeft: "5px" }}>
                {" "}{selectedDetail && parseInt(selectedDetail.stock) * parseInt(selectedDetail.packet_size)}{" "}Kg
              </div>
            </div>
          </DialogContent>

          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleAddOpen}
            >
              Add
            </Button>

            <Button
              variant="outlined"
              onClick={() => handleTakeOpen()}
              color="primary"
            >
              Take
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add Chemicals */}
        <Dialog
          open={addopen}
          onClose={handleAddClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
            Add Chemical Packets
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">No Of Packets</div>
            <TextField
              fullWidth
              autoFocus
              id="numberofpackets"
              type="number"
              margin="dense"
              focused={false}
              onChange={e => setPacketCount(e.target.value)}
              value={packetcount}
            />
            <div className="mt-20">Price Per Unit</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="number"
              margin="dense"
              focused={false}
              onChange={e => setPrice(e.target.value)}
              value={price}
            />

            <div className="mt-20">Date</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="date"
              margin="dense"
              focused={false}
              onChange={e => setDate(e.target.value)}
              value={date}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleAddClose}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => AddTakeChemicals(0)}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>

          <span
            style={{ textAlign: "center", marginBottom: "5px", color: "red" }}
          >
            {adderror}
          </span>
        </Dialog>

        {/* Take Chemicals */}
        <Dialog
          open={takeopen}
          onClose={handleTakeClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
            Take Chemical Packets
          </DialogTitle>

          <DialogContent>
            <div style={{ display: "flex" }}>
              <div style={{ width: "150px" }}>Packet Size </div>
              <a>:</a>
              <div style={{ marginLeft: "5px" }}>
                {" "}{selectedDetail && selectedDetail.packet_size}kg{" "}
              </div>
            </div>

            <div style={{ display: "flex" }} className="mt-20">
              <div style={{ width: "150px" }}>Available Packets </div>
              <a>:</a>
              <div style={{ marginLeft: "5px" }}>
                {" "}{selectedDetail && selectedDetail.stock}{" "}
              </div>
            </div>

            <div style={{ display: "flex" }} className="mt-20">
              <div style={{ width: "150px" }}>Overall Weight </div>
              <a>:</a>
              <div style={{ marginLeft: "5px" }}>
                {" "}{selectedDetail &&
                  parseInt(selectedDetail.stock) *
                    parseInt(selectedDetail.packet_size)}Kg{" "}
              </div>
            </div>

            <div style={{ display: "flex" }} className="mt-20">
              <div>
                <div className="mt-20">How many packets do you need?</div>
                <TextField
                  fullWidth
                  autoFocus
                  id="numberofpackets"
                  type="number"
                  margin="dense"
                  focused={false}
                  onChange={e => setReqPacket(e.target.value)}
                  value={reqpacket}
                />
              </div>
              <div style={{ marginLeft: "20px" }}>
                <div className="mt-20">Bag No</div>
                <TextField
                  fullWidth
                  autoFocus
                  id="name"
                  type="text"
                  margin="dense"
                  focused={false}
                  onChange={e => setBagNo(e.target.value)}
                  value={bagno}
                />
              </div>
            </div>

            <div className="mt-20">Tank Type</div>
            <TextField
              id="tank-type"
              name="tank-type"
              fullWidth
              select
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={tanktype}
              onChange={e => {
                setTankType(e.target.value);
                ListSerialNo(e.target.value);
              }}
            >
              {tankdata &&
                tankdata.map(item =>
                  <option value={item.id}>
                    {" "}{item.name}{" "}
                  </option>
                )}
            </TextField>

            <div className="mt-20">Tank Serial No</div>
            <TextField
              id="tank-sn"
              name="tank-sn"
              fullWidth
              select
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={tanksn}
              onChange={e => setTankSN(e.target.value)}
            >
              {serialnodata &&
                serialnodata.map(item =>
                  <option value={item.id}>
                    {" "}{item.serial_no}{" "}
                  </option>
                )}
            </TextField>

            <div className="mt-20">Client</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              focused={false}
              onChange={e => setClient(e.target.value)}
              value={client}
            />

            <div className="mt-20">Purpose</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              focused={false}
              onChange={e => setPurpose(e.target.value)}
              value={purpose}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleTakeClose}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => AddTakeChemicals(1)}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
          <span
            style={{ textAlign: "center", marginBottom: "5px", color: "red" }}
          >
            {takenerror}
          </span>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default Chemicals;
