import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Select,
  MenuItem,
  Avatar,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Paragraph } from "app/components/Typography";
import { Fragment } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  UNIT_ADD,
  UNIT_LIST,
  UNIT_DELETE,
  UNIT_UPDATE
} from "app/utils/ApiRoutes.js";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const Units = () => {
  const { palette } = useTheme();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [unit, setUnit] = useState("");
  const [spareUnit, setspareUnit] = useState("");
  const [OnEdit, setOnEdit] = useState(0);

  useEffect(() => {
    ApiFetch();
  }, []);

  const ApiFetch = () => {
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: UNIT_LIST
    };

    axios
      .request(config)
      .then(response => {
        const data = response.data.data;
        setspareUnit(data);
      
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDeleteUnit = id => {
    var config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: UNIT_DELETE + id
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Deleted sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        console.log(error);
      });
    ApiFetch();
  };

  const onDelete = (type, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        onDeleteUnit(type, id);
      }
    });
  };

  const onAddUpdate = (type = 0) => {
    
    var data = {
      unit_name: unit
    };
    
    const API_URL = OnEdit == 0 ? UNIT_ADD : UNIT_UPDATE + OnEdit;
    
    var config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: API_URL,
      data: data
    };

    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setLoading(false)
        console.log(response.data);
        ApiFetch();
        handleClose();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        setLoading(false)
        Swal.fire(error.response.data.message)
        handleClose();
      }); 
  };

  const onAddUnitOpen = data => {
    if (data) {
      setUnit(data.unit_name);
    } else {
      setUnit("");
    }
    handleClickOpen();
  };

  return (
    <div>
      <Fragment>
        <ContentBox className="analytics">
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <Title>Units</Title>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                    onClick={() => {
                      setOnEdit(0);
                      handleClickOpen();
                    }}
                  >
                    <Icon>add</Icon>
                  </Fab>
                </CardHeader>

                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={2}>
                          Unit
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={2}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {spareUnit &&
                        spareUnit.map(item =>
                          <TableRow hover>
                            <TableCell
                              align="left"
                              colSpan={2}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <div className="flex-column">
                                {item.unit_name}
                              </div>
                            </TableCell>

                            <TableCell sx={{ px: 0 }} colSpan={2}>
                              <IconButton
                                onClick={() => {
                                  setOnEdit(item.id);
                                  onAddUnitOpen(item);
                                }}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  onDelete(item.id);
                                }}
                              >
                                <Icon style={{ color: "red" }}>delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </ContentBox>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "300px" }}>
            Unit
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">Unit</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Unit"
              onChange={e => setUnit(e.target.value)}
              value={unit}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => onAddUpdate()}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default Units;
