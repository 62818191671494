import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Select,
  MenuItem,
  Avatar,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Fragment } from "react";
import countrydata from "../../../assets/country.js";
import {
  SUPPLIER_ADD,
  SUPPLIER_DELETE,
  SUPPLIER_UPDATE,
  SUPPLIER_LIST,
  SUPPLIERDETAIL_ADD,
  SUPPLIERDETAIL_DELETE,
  SUPPLIERDETAIL_UPDATE,
  SUPPLIERDETAIL_LIST
} from "app/utils/ApiRoutes.js";
import Swal from "sweetalert2";
import axios from "axios";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const Suppliers = () => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [supplierData, setSupplierData] = useState([]);
  const [selectSupplier, setSelectSupplier] = useState([]);
  const [ContactList, setContactList] = useState([]);
  const [company, setCompany] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [address, setAddress] = useState("");
  const [pobox, setPobox] = useState("");
  const [pc, setPc] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [index, setIndex] = useState(0);
  const [contactOpen, setContactOpen] = useState(false);
  const handleClickContactOpen = () => setContactOpen(true);
  const handleContactClose = () => setContactOpen(false);
  const [contact, setContact] = useState("");
  const [position, setPosition] = useState("");
  const [OnEdit, setOnEdit] = useState(0);

  const handleAddItem = () => {
    setDataArray([...dataArray, { id: index, name: "" }]);
    setIndex(index + 1);
  };

  const handleDeleteItem = id => {
    const updatedDataArray = dataArray.filter(item => item.id !== id);
    setDataArray(updatedDataArray);
  };

  const handleChangeItem = (id, name) => {
    const updatedDataArray = dataArray.map(
      item => (item.id === id ? { ...item, name: name } : item)
    );
    setDataArray(updatedDataArray);
  };

  const PhoneInput = () => {
    return (
      <div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <div>Phone</div>
          <Fab
            size="small"
            color="secondary"
            aria-label="Add"
            className="button"
            style={{ marginLeft: "20px" }}
          >
            <Icon onClick={() => handleAddItem()}>add</Icon>
          </Fab>
        </div>
        {dataArray.map(Item =>
          <div
            key={Item.id}
            id={Item.id}
            style={{ display: "flex", alignItems: "center" }}
          >
            <TextField
              fullWidth
              autoFocus
              id={`co_host_${Item.id}`}
              type="text"
              margin="dense"
              placeholder="Phone"
              onChange={e => handleChangeItem(Item.id, e.target.value)}
              value={Item.name}
              focused={false}
            />
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "2%", height: "40px", minWidth: "20px" }}
              onClick={() => handleDeleteItem(Item.id)}
            >
              X
            </Button>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    ApiFetch();
  }, []);

  const handleSelectChange = event => {
    const selectedValue = event.target.value;
    setCountry(selectedValue);
  };

  const ApiFetch = (id = 0) => {
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: SUPPLIER_LIST
    };

    // handleContactClose handleClose
    axios
      .request(config)
      .then(response => {
        const data = response.data.data;
        setSupplierData(data);
        if (id == 0) {
          setSelectSupplier(data[0]);
          getSuplierList(data[0].id);
        } else {
          getSuplierList(id);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onAddSupplierOpen = data => {
    if (data) {
      setCompany(data.company_name);
      setAbbreviation(data.abbreviation);
      setAddress(data.company_address);
      setPobox(data.po_box);
      setPc(data.company_pc);
      setPhone(data.phone_number);
      setCountry(data.country);
    } else {
      setCompany("");
      setAbbreviation("");
      setAddress("");
      setPobox("");
      setPc("");
      setPhone("");
      setCountry("");
    }

    handleClickOpen();
  };

  const onAddSupplierContackOpen = data => {
    if (data) {
      setDataArray(JSON.parse(data.phone_number));
      setContact(data.name);
      setPosition(data.position);
    } else {
      setContact("");
      setPosition("");
      setDataArray([]);
    }
    handleClickContactOpen();
  };

  const getSuplierList = id => {
    const API_URL = SUPPLIERDETAIL_LIST + id;

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: API_URL
    };

    axios.request(config)
      .then(response => {
        console.log(response.data.data);
        setContactList(response.data.data);
      }).catch(error => {
        console.log(error);
      });
  };

  const onDeleteSupplier = async (type, id) => {
    if (type == "0"){
      
      var config = {
        method: "GET",
        maxBodyLength: Infinity,
        url: SUPPLIER_DELETE + id
      };

    }else{
      
      var config = {
        method: "GET",
        maxBodyLength: Infinity,
        url: SUPPLIERDETAIL_DELETE + id
      };
    
    }

    axios.request(config)
      .then(response => {
        ApiFetch(selectSupplier.id);
        console.log(response.data);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Deleted sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDelete = (type, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        onDeleteSupplier(type, id);
      }
    });

  };

  const onSubmitContact = async type => {
    var userdata = await localStorage.getItem("user_data");
    userdata = JSON.parse(userdata);
    var locationdata = await localStorage.getItem("location-data");
    locationdata = JSON.parse(locationdata);

    var data = {
      employee_id: userdata.id,
      location_id: locationdata.id,
      company_name: company,
      abbreviation: abbreviation,
      company_address: address,
      po_box: pobox,
      company_pc: pc,
      phone_number: phone,
      country: country
    };

    if (type == 0) {

      const API_URL = OnEdit == 0 ? SUPPLIER_ADD : SUPPLIER_UPDATE + OnEdit;
      var config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: API_URL,
        data: data
      };

    }else{
      
      var c_data = {
        supplier_id: selectSupplier.id,
        contact: dataArray,
        contact_person: contact,
        position: position
      };

      const API_URL =
        OnEdit == 0 ? SUPPLIERDETAIL_ADD : SUPPLIERDETAIL_UPDATE + OnEdit;

      var config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: API_URL,
        data: c_data
      };
    }
    setLoading(true);
    axios
      .request(config)
      .then(response => {
        setLoading(false);
        handleClose();
        handleContactClose();
        ApiFetch(selectSupplier.id);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
        console.log(response.data);
      })
      .catch(error => {
        setLoading(false);
        handleClose();
        handleContactClose();
        Swal.fire(error.response.data.message)
        console.log(error);
      });
  };

  return (
    <div>
      <Fragment>
        <ContentBox className="analytics">
          <Grid container spacing={3}>
            <Grid item lg={7} md={5} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <Title>Suppliers</Title>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                  >
                    <Icon
                      onClick={() => {
                        setOnEdit(0);
                        onAddSupplierOpen();
                      }}
                    >
                      add
                    </Icon>
                  </Fab>
                </CardHeader>
                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={4}>
                          Supplier
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={4}>
                          Address
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={3}>
                          Phone
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={2}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {supplierData &&
                        supplierData.map((item, index) =>
                          <TableRow
                            hover
                            onClick={() => {
                              setSelectSupplier(item);
                              getSuplierList(item.id);
                            }}
                          >
                            <TableCell
                              align="left"
                              colSpan={4}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <div className="flex-column">
                                {item.company_name}
                                <a className="text-secondory text-orange">
                                  ({item.abbreviation})
                                </a>
                              </div>
                            </TableCell>

                            <TableCell
                              align="left"
                              colSpan={4}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <div className="flex-column">
                                <a>
                                  {" "}{item.company_address}
                                </a>
                                <a className="text-secondory">
                                  {item.country}
                                </a>
                              </div>
                            </TableCell>

                            <TableCell sx={{ px: 0 }} align="left" colSpan={3}>
                              {item.phone_number}
                            </TableCell>

                            <TableCell sx={{ px: 0 }} colSpan={2}>
                              <IconButton
                                onClick={() => {
                                  setOnEdit(item.id);
                                  onAddSupplierOpen(item);
                                }}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>

                              <IconButton
                                onClick={() => {
                                  onDelete("0", item.id);
                                }}
                              >
                                <Icon style={{ color: "red" }}>delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={5} md={7} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <div className="row-flex-center">
                    <Title style={{ marginLeft: "10px" }}>
                      {selectSupplier && selectSupplier.company_name}
                    </Title>
                  </div>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                  >
                    <Icon
                      onClick={() => {
                        setOnEdit(0);
                        onAddSupplierContackOpen();
                      }}
                    >
                      add
                    </Icon>
                  </Fab>
                </CardHeader>
                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={4}>
                          Contact Name
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={3}>
                          Phone
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={2}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ContactList &&
                        ContactList.map(item =>
                          <TableRow hover>
                            <TableCell
                              align="left"
                              colSpan={4}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <div className="flex-column">
                                {item.name}
                                <a className="text-secondory text-green">
                                  {item.position}
                                </a>
                              </div>
                            </TableCell>
                            <TableCell sx={{ px: 0 }} align="left" colSpan={3}>
                              {/* {item && Array.isArray(item.phone_number) ? item.phone_number.map((val) => val.name).join(', ') : ''} */}
                              {item &&
                              item.phone_number &&
                              typeof item.phone_number === "string"
                                ? (() => {
                                    try {
                                      const phoneNumberArray = JSON.parse(
                                        item.phone_number
                                      );
                                      if (Array.isArray(phoneNumberArray)) {
                                        const names = phoneNumberArray.map(
                                          val => val.name
                                        );
                                        if (names.length > 0) {
                                          return names.join(", ");
                                        }
                                      }
                                    } catch (error) {
                                      console.log(
                                        "Error parsing phone number JSON:",
                                        error
                                      );
                                    }
                                    return "";
                                  })()
                                : ""}
                            </TableCell>
                            <TableCell sx={{ px: 0 }} colSpan={2}>
                              <IconButton
                                onClick={() => {
                                  setOnEdit(item.id);
                                  onAddSupplierContackOpen(item);
                                }}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>
                              <IconButton
                                onClick={() => onDelete("1", item.id)}
                              >
                                <Icon style={{ color: "red" }}>delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
                  {/* {!branchData && <h1 className="no-data">No Data Found</h1>} */}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </ContentBox>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
            Supplier
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">Company Name</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              placeholder="Company Name"
              onChange={e => setCompany(e.target.value)}
              value={company}
              focused={false}
            />

            <div className="mt-20">Abbreviation</div>
            <TextField
              fullWidth
              autoFocus
              id="number"
              type="text"
              margin="dense"
              placeholder="Abbreviation"
              onChange={e => setAbbreviation(e.target.value)}
              value={abbreviation}
              focused={false}
            />

            <div className="mt-20">Address</div>
            <TextField
              fullWidth
              autoFocus
              id="number"
              type="text"
              margin="dense"
              placeholder="Address"
              onChange={e => setAddress(e.target.value)}
              value={address}
              focused={false}
            />

            <div className="mt-20">PO Box</div>
            <TextField
              fullWidth
              autoFocus
              id="number"
              type="text"
              margin="dense"
              placeholder="PO Box"
              onChange={e => setPobox(e.target.value)}
              value={pobox}
              focused={false}
            />

            <div className="mt-20">PC</div>
            <TextField
              fullWidth
              autoFocus
              id="number"
              type="text"
              margin="dense"
              placeholder="PC"
              onChange={e => setPc(e.target.value)}
              value={pc}
              focused={false}
            />
            <div className="mt-20">Phone</div>
            <TextField
              fullWidth
              autoFocus
              id="number"
              type="text"
              margin="dense"
              placeholder="Phone"
              onChange={e => setPhone(e.target.value)}
              value={phone}
              focused={false}
            />

            <div className="mt-20">Select Country</div>
            <select
              className="select-drop"
              value={country}
              onChange={handleSelectChange}
            >
              {countrydata.map((item, index) =>
                <option value={item.name}>
                  {item.name}
                </option>
              )}
            </select>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => onSubmitContact(0)}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={contactOpen}
          onClose={setContactOpen}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
            Contact
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">Contact Name</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              placeholder="Contact Name"
              onChange={e => setContact(e.target.value)}
              value={contact}
              focused={false}
            />
            <div className="mt-20">Position</div>

            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              placeholder="Position"
              onChange={e => setPosition(e.target.value)}
              value={position}
              focused={false}
            />

            {PhoneInput()}
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleContactClose}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => onSubmitContact(2)}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default Suppliers;
