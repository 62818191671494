import React, { useState, useEffect, useRef } from "react";
import { styled, darken, alpha, lighten } from "@mui/material/styles";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  IconButton,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import AddClient from "../Common/AddClient";
import AddBranch from "../Common/AddBranch";
import Swal from "sweetalert2";
import axios from "axios";

import {
  BRANCH_LIST,
  CLIENT_LIST,
  CONTACT_LIST,
  FEEDBACK_ADD,
  FEEDBACK_DELETE,
  FEEDBACK_UPDATE,
  FEEDBACK_LIST,
  ADD_JOB_RECEIPT
} from "app/utils/ApiRoutes.js";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const CardFooder = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "center"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1)
}));

const fbdata = [
  {
    sno: 1,
    client: "LULU MUSCAT HYPERMARKETING",
    branch: "Muscat",
    feedback: "Not Insterested",
    date: "20-07-2023",
    s_date: "30-08-2023",
    type: "Scheduled Another Meeting"
  },
  {
    sno: 2,
    client: "Al Kawther Bakery",
    branch: "Al Kawther - Liwa",
    feedback: "Very Soon",
    date: "29-07-2023",
    s_date: "24-08-2023",
    type: "Scheduled Another Meeting"
  }
];

const Feedback = ({}) => {
  const navigate = useNavigate();

  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpenFeedback = (type, item) => {
    if (type == 1) {
      setOnEdit(1);
      setUpdateID(item.feedback_id);
      setClientID(parseInt(item.client_id));
      ListBranch(parseInt(item.client_id), parseInt(item.branch_id), parseInt(item.contact_id));
      setClientName(item.client_name);

      // setBranchID(item.branch_id);
      // setBranchName(item.branch_name);
      // setContactID(item.contact_id);
      // setContactName(item.contact_name);
      setFeadBack(getFeedbackID(item.feedback))
      setComments(item.comments)
      setAction(getActionID(item.action));
      setDate(item.schedule_date.split("T")[0]);
      setTime(item.time);
    } else {
      setFeadBack("Schedule Another Meeting");
      setDate();
      setTime();
      setOnEdit(0);
    }
    setOpen(true);
  };

  const [updatID, setUpdateID] = useState();
  const [clientId, setClientID] = useState();
  const [clientName, setClientName] = useState("");
  const [branchId, setBranchID] = useState("");
  const [branchName, setBranchName] = useState("");
  const [contactId, setContactID] = useState("");
  const [contactName, setContactName] = useState("");
  const [feedback, setFeadBack] = useState(1);
  const [comments, setComments] = useState("");
  const [action, setAction] = useState("Schedule Another Meeting");
  const [date, setDate] = useState();
  const [time, setTime] = useState();

  const [callback, setCallBack] = useState("");
  const [clientopen, setClientOpen] = useState();
  const onClientOpen = () => setClientOpen(true);
  const onClientClose = () => setClientOpen(false);

  const [branchopen, setBranchOpen] = useState();
  const onBranchOpen = () => setBranchOpen(true);
  const onBranchClose = () => setBranchOpen(false);

  const [clientdata, setClientData] = useState([]);
  const [branchdata, setBranchData] = useState([]);
  const [contactdata, setContactData] = useState([]);
  const [feedbackdata, setFeedbackData] = useState([]);
  const [OnEdit, setOnEdit] = useState(0);
  const [error, setError] = useState("");
  useEffect(() => {
    ListClient();
    ListFeedback();
  }, []);

  const ListClient = () => {
    let config = {
      method: "GET",
      url: CLIENT_LIST
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          ListBranch(resdata[0].client_id);
          setClientID(resdata[0].client_id);
          setClientName(resdata[0].client_name);
          setClientData(resdata);
        } else {
          setClientData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setClientData([]);
      });
  };

  const ListBranch = (id, BRANCHID, CONTACTID) => {
    let config = {
      method: "GET",
      url: BRANCH_LIST + id
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          if (BRANCHID) {
            var data = resdata.filter(item => item.branch_id === BRANCHID);
            if (data.length > 0) {
              setBranchID(data[0].branch_id);
              setBranchName(data[0].branch_name);
              ListContact(data[0].branch_id, CONTACTID);
            } else {
              setBranchID(resdata[0].branch_id);
              setBranchName(resdata[0].branch_name);
              ListContact(resdata[0].branch_id);
            }
          } else {
            setBranchID(resdata[0].branch_id);
            setBranchName(resdata[0].branch_name);
            ListContact(resdata[0].branch_id);
          }

          setBranchData(resdata);
        } else {
          setBranchData([]);
        }
      })
      .catch(error => {
        setBranchData([]);
      });
  };

  const ListContact = (id, CONTACTID) => {
    let config = {
      method: "GET",
      url: CONTACT_LIST + id
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          if (CONTACTID) {
            var data = resdata.filter(item => item.branch_id === CONTACTID);
            if (data.length > 0) {
              setContactID(data[0].contact_id);
              setContactName(data[0].contact_name);
            } else {
              setContactID(resdata[0].contact_id);
              setContactName(resdata[0].contact_name);
            }
          } else {
            setContactID(resdata[0].contact_id);
            setContactName(resdata[0].contact_name);
          }
          setContactData(resdata);
        } else {
          setContactData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setContactData([]);
      });
  };

  const isNotValidate = () => {
    console.log("clientId :", clientId);
    console.log("clientName :", clientName);
    console.log("branchId :", branchId);
    console.log("branchName :", branchName);
    console.log("contactId :", contactId);
    console.log("contactName :", contactName);
    console.log("date :", date);
    console.log("time :", time);
    if (
      !clientId ||
      !clientName ||
      !branchId ||
      !branchName ||
      !contactId ||
      !contactName ||
      !date ||
      !time
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onAddUpdate = (type, ID) => {
    if (isNotValidate()) {
      setError("Required all fields");
      return;
    } else {
      setError("");
    }
    var userId = userdata ? userdata.id : "";
    var locId = locationdata ? locationdata.id : "";
    const data = {
      user_id: userId,
      location_id: locId,
      client_id: clientId,
      client_name: clientName,
      branch_id: branchId,
      branch_name: branchName,
      contact_id: contactId,
      contact_name: contactName,
      refer: "",
      feedback: getFeedback(feedback),
      comments: comments,
      action: getActionValue(action),
      schedule_date: date,
      time: time
    };

    const API_URL = OnEdit == 0 ? FEEDBACK_ADD : FEEDBACK_UPDATE + updatID;
    var config = {
      method: "POST",
      url: API_URL,
      data: data
    };

    setLoading(true);
    axios
      .request(config)
      .then(response => {
        setLoading(false);
        console.log(response.data);
        if (OnEdit == 0 && getActionValue(action) == 2) {
          AddJobReceipt();
        }
        handleClose();
        ListFeedback();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        Swal.fire(error.response.data.message)
      });
  };

  const EmojiRating = () =>
    <div className="smileys-container">
      <span
        className={`smiley ${feedback === 1 ? "smiley-selected" : ""}`}
        onClick={() => handleRatingChange(1)}
      >
        😠
        <a className="smiley-text">Not Intrested</a>
      </span>
      <span
        className={`smiley ${feedback === 2 ? "smiley-selected" : ""}`}
        onClick={() => handleRatingChange(2)}
      >
        😞
        <a className="smiley-text">In 2 M</a>
      </span>
      <span
        className={`smiley ${feedback === 3 ? "smiley-selected" : ""}`}
        onClick={() => handleRatingChange(3)}
      >
        😐
        <a className="smiley-text">In The Feature</a>
      </span>
      <span
        className={`smiley ${feedback === 4 ? "smiley-selected" : ""}`}
        onClick={() => handleRatingChange(4)}
      >
        🙂
        <a className="smiley-text">Very Soon</a>
      </span>
      <span
        className={`smiley ${feedback === 5 ? "smiley-selected" : ""}`}
        onClick={() => handleRatingChange(5)}
      >
        😄
        <a className="smiley-text">Very Intrested</a>
      </span>
    </div>;

  const handleRatingChange = newRating => {
    setFeadBack(newRating);
  };

  const onSubmit = () => {
    onAddUpdate(0, "");
  };

  const ListFeedback = () => {
    var userId = userdata ? userdata.id : "";
    var userRole = userdata ? userdata.role : "";
    var locId = locationdata ? locationdata.id : "";
    const data = {
      user_id: userId,
      role: userRole,
      location_id: locId,
      groupby_enabled: "0",
      is_filtered: "0",
      filteration_key: "0",
      start_date: "",
      end_date: ""
    };
    let config = {
      method: "POST",
      url: FEEDBACK_LIST,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.feedback_data);
        var resdata = response.data.feedback_data;
        if (resdata.length > 0) {
          setFeedbackData(resdata);
        } else {
          setFeedbackData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setFeedbackData([]);
      });
  };

  const getFeedback = id => {
    switch (id) {
      case 1:
        return "Not Intrested";
      case 2:
        return "In 2 M";
      case 3:
        return "In The Future";
      case 4:
        return "Very soon";
      case 5:
        return "Very Interested";
      default:
        return 1;
    }
  };

  const getFeedbackID = id => {
    switch (id) {
      case "Not Intrested":
        return 1;
      case "In 2 M":
        return 2;
      case "In The Future":
        return 3;
      case "Very soon":
        return 4;
      case "Very Interested":
        return 5;
      default:
        return 1;
    }
  };

  const getActionValue = value => {
    switch (value) {
      case "Schedule Another Meeting":
        return 1;
      case "Schedule A Demo":
        return 2;
      case "Referred A Person":
        return 3;
      case "Sign A Contract":
        return 4;
      case "Follow Up":
        return 5;
      default:
        return 1;
    }
  };

  const getActionID = value => {
    switch (value) {
      case 1:
        return "Schedule Another Meeting";
      case 2:
        return "Schedule A Demo";
      case 3:
        return "Referred A Person";
      case 4:
        return "Sign A Contract";
      case 5:
        return "Follow Up";
      default:
        return 1;
    }
  };

  const getAction = value => {
    switch (value) {
      case 1:
        return "Schedule Another Meeting";
      case 2:
        return "Schedule A Demo";
      case 3:
        return "Referred A Person";
      case 4:
        return "Sign A Contract";
      case 5:
        return "Follow Up";
      default:
        return "";
    }
  };

  const Feedback_Delete = id => {
    let config = {
      method: "GET",
      url: FEEDBACK_DELETE + id
    };

    axios
      .request(config)
      .then(response => {
        ListFeedback();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Deleted sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDelete = id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        Feedback_Delete(id);
      }
    });
  };

  const onChangeClient = e => {
    setClientID(e.target.value);
    var data = clientdata.filter(
      item => item.client_id === parseInt(e.target.value)
    );
    setClientName(data[0].client_name);
    ListBranch(e.target.value);
  };

  const onChangeBranch = e => {
    setBranchID(e.target.value);
    var data = branchdata.filter(
      item => item.branch_id === parseInt(e.target.value)
    );
    ListContact(e.target.value);
    setBranchName(data[0].branch_name);
  };

  const AddJobReceipt = () => {
    const formData = new FormData();

    var data = {
      employee_id: userdata.id,
      location_id: locationdata.id,
      receipt_no: "",

      type: "Demo",
      date: date,
      removal_date: "",

      client_id: clientId,
      branch_id: branchId,

      tank_type: "",
      tank_serial_no: "",
      temperature: "",
      chemicals: "",

      comp_by_name: "",
      comp_by_position: "",

      accepted_by_name: "",
      accepted_by_position: "",

      remark: "Demo",
      b_integer: "",
      payment: 0,
      status: 0,
      price: ""
    };

    formData.append("data", JSON.stringify(data));

    let config = {
      method: "POST",
      url: ADD_JOB_RECEIPT,
      data: formData
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Container>
      <Grid container spacing={3} mt={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
            <CardHeader>
              <Title>Feedbacks</Title>
              <Fab
                size="small"
                color="secondary"
                aria-label="Add"
                className="button"
              >
                <Icon
                  onClick={() => {
                    handleOpenFeedback(0, "");
                  }}
                >
                  add
                </Icon>
              </Fab>
            </CardHeader>
            <Box overflow="auto">
              <ProductTable>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ px: 1 }} align="center" colSpan={1}>
                      S.NO
                    </TableCell>
                    <TableCell sx={{ px: 2 }} colSpan={4}>
                      Client
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Feedback
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Date
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Schedule Date
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Type
                    </TableCell>
                    <TableCell sx={{ px: 2 }} align="right" colSpan={2}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {feedbackdata.map((item, index) =>
                    <TableRow hover>
                      <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                        {index + 1}
                      </TableCell>

                      <TableCell
                        colSpan={4}
                        sx={{ px: 2, textTransform: "capitalize" }}
                      >
                        <div
                          className="flex-column"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.client_name}
                          <a className="text-secondary text-orange">
                            ({item.branch_name})
                          </a>
                        </div>
                      </TableCell>

                      <TableCell
                        colSpan={2}
                        sx={{ textTransform: "capitalize" }}
                      >
                        <div className="flex-column">
                          {item.feedback}
                        </div>
                      </TableCell>

                      <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                        {new Date(item.created_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                        {new Date(item.schedule_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                        {getAction(item.action)}
                      </TableCell>

                      <TableCell sx={{ px: 2 }} align="right" colSpan={2}>
                        <IconButton onClick={() => {}}>
                          <Icon
                            color="primary"
                            onClick={() => {
                              handleOpenFeedback(1, item);
                            }}
                          >
                            edit
                          </Icon>
                        </IconButton>
                        <IconButton>
                          <Icon
                            style={{ color: "red" }}
                            onClick={() => onDelete(item.feedback_id)}
                          >
                            delete
                          </Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </ProductTable>
            </Box>
          </Card>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "500px" }}>
            {type == 0 ? "Add" : "Update"} Feedback
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">Client Name</div>
            <div style={{ display: "flex" }}>
              <TextField
                id="client-name"
                name="client-name"
                select
                margin="dense"
                focused={false}
                SelectProps={{
                  native: true
                }}
                value={clientId}
                onChange={onChangeClient}
                style={{ width: "70%" }}
              >
                {clientdata.map(item =>
                  <option value={item.client_id}>
                    {" "}{item.client_name}{" "}
                  </option>
                )}
              </TextField>

              <StyledButton
                variant="outlined"
                color="secondary"
                onClick={() => onClientOpen()}
              >
                Add Client
              </StyledButton>
            </div>

            <div className="mt-20">Branch Name</div>

            <div style={{ display: "flex" }}>
              <TextField
                id="branch-name"
                name="branch-name"
                fullWidth
                select
                margin="dense"
                focused={false}
                SelectProps={{
                  native: true
                }}
                value={branchId}
                onChange={onChangeBranch}
                style={{ width: "70%" }}
              >
                {branchdata.map(item =>
                  <option value={item.branch_id}>
                    {" "}{item.branch_name}{" "}
                  </option>
                )}
              </TextField>
              <StyledButton
                variant="outlined"
                color="secondary"
                onClick={() => onBranchOpen()}
              >
                Add Branch
              </StyledButton>
            </div>

            <div className="mt-20">Lead Name</div>
            <TextField
              id="agreement-name"
              name="agreement-name"
              select
              fullWidth
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={contactId}
              onChange={e => {
                setContactID(e.target.value);
                var data = contactdata.filter(
                  item => item.contact_id === parseInt(e.target.value)
                );
                setContactName(data[0].contact_name);
              }}
            >
              {contactdata.map(item =>
                <option value={item.contact_id}>
                  {" "}{item.contact_name}{" "}
                </option>
              )}
            </TextField>

            <div className="mt-20">Feedback</div>
            <EmojiRating />

            <div className="mt-20">Comments</div>
            <TextField
              id="comments"
              name="comments"
              fullWidth
              margin="dense"
              focused={false}
              type="textarea"
              value={comments}
              onChange={e => {
                setComments(e.target.value);
              }}
            />

            <div className="mt-20">Action</div>
            <TextField
              id="agreement-name"
              name="agreement-name"
              select
              fullWidth
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={action}
              onChange={e => {
                setAction(e.target.value);
              }}
            >
              <option value={"Schedule Another Meeting"}>
                Schedule Another Meeting
              </option>
              <option value={"Schedule A Demo"}>Schedule A Demo</option>
              <option value={"Schedule A Person"}>Referred A Person</option>
              <option value={"Sign A Contract"}>Sign A Contract</option>
              <option value={"Follow Up"}>Follow Up</option>
            </TextField>

            <div className="mt-20">Schedule Date</div>
            <TextField
              id="comments"
              name="comments"
              fullWidth
              margin="dense"
              focused={false}
              type="date"
              value={date}
              onChange={e => {
                setDate(e.target.value);
              }}
            />

            <div className="mt-20">Schedule Time</div>
            <TextField
              id="comments"
              name="comments"
              fullWidth
              margin="dense"
              focused={false}
              type="time"
              value={time}
              onChange={e => {
                setTime(e.target.value);
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => onSubmit()}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
          <span
            style={{ textAlign: "center", marginBottom: "5px", color: "red" }}
          >
            {error}
          </span>
        </Dialog>

        <AddClient
          open={clientopen}
          handleClose={onClientClose}
          callBack={setCallBack}
          type={0}
          setType={setType}
        />
        <AddBranch
          open={branchopen}
          handleClose={onBranchClose}
          callBack={setCallBack}
          type={0}
          setType={setType}
          cid={clientId}
        />
      </Grid>
    </Container>
  );
};

export default Feedback;
