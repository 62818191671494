import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Select,
  MenuItem,
  Avatar,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Fragment } from "react";
import EventCalendar from "../Calendar/EventCalendar";
import axios from "axios";

import { FEEDBACK_LIST, FEEDBACK_LISTALL } from "app/utils/ApiRoutes.js";
const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center"
  // justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const ucdata = [
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "12 July 2023",
    type: 1
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "18 July 2023",
    type: 2
  },
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "21 July 2023",
    type: 3
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "29 July 2023",
    type: 1
  },
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "12 July 2023",
    type: 1
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "18 July 2023",
    type: 2
  },
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "21 July 2023",
    type: 3
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "29 July 2023",
    type: 1
  }
];

const getColorCode = id => {
  switch (id) {
    case 1:
      return "#d94a28";
    case 2:
      return "#3e995b";
    case 3:
      return "#5844aa";
    default:
      return "crimson";
  }
};

const getKeyCode = id => {
  switch (id) {
    case 1:
      return "SAR";
    case 2:
      return "DEMO";
    case 3:
      return "FU";
    default:
      return "";
  }
};

const CRMCalendar = () => {
  const [activeTab, setActiveTab] = useState("month");
  const [filterTab, setFilterTab] = useState(0);
  const [eventData, setEventData] = useState([]);
  const [alleventData, setEventAllData] = useState([]);
  const [commonData, setCommonData] = useState([]);
  const [feedbackdata, setFeedbackData] = useState([]);
  const [count, setCount] = useState({
    count: 0,
    meetingCount: 0,
    democount: 0,
    followcount: 0
  });

  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);

  const handleTabClick = tab => {
    setActiveTab(tab);
    if (tab == "day") {
      getToday();
    }
    if (tab == "week") {
      getNext7Days();
    }
    if (tab == "month") {
      getNext30Days();
    }
  };

  const filterTabClick = tab => {
    setFilterTab(tab);
    if (tab == 0) {
      setEventData(alleventData);
    } else {
      const filteredData = alleventData.filter(item => item.type === tab);
      setEventData(filteredData);
    }
  };

  const ListFeedback = () => {
    var userId = userdata ? userdata.id : "";
    var userRole = userdata ? userdata.role : "";
    var locId = locationdata ? locationdata.id : "";
    const data = {
      user_id: userId,
      role: userRole,
      location_id: locId
    };
    let config = {
      method: "POST",
      url: FEEDBACK_LISTALL,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.feedback_data);
        var resdata = response.data.feedback_data;
        if (resdata.length > 0) {
          setCommonData(resdata);
          var data = [];
          resdata.map(item => {
            if (item.action === 1 || item.action === 3 || item.action === 5) {
              var actiontype = 1;
              if (item.action == 3) {
                actiontype = 2;
              }
              if (item.action == 5) {
                actiontype = 3;
              }
              data.push({
                client: item.client_name,
                branch: item.branch_name,
                date: dateFormate(item.schedule_date),
                type: actiontype
              });
            }
          });
          setFeedbackData(data);
          setEventData(data);
          setEventAllData(data);
          var meetdata = data.filter(item => item.type === 1);
          var demodata = data.filter(item => item.type === 2);
          var followdata = data.filter(item => item.type === 3);
          console.log(data);
          console.log(meetdata);
          setCount({
            count: data.length,
            meetingCount: meetdata.length,
            democount: demodata.length,
            followcount: followdata.length
          });
          
        } else {
          setFeedbackData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setFeedbackData([]);
      });
  };

  const dateFormate = value => {
    const date = new Date(value);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const getToday = () => {
    var data = [];
    commonData.map(item => {
      if (
        (item.action === 1 || item.action === 3 || item.action === 5) &&
        new Date().toDateString() == new Date(item.schedule_date).toDateString()
      ) {
        var actiontype = 1;
        if (item.action == 3) {
          actiontype = 2;
        }
        if (item.action == 5) {
          actiontype = 3;
        }
        data.push({
          client: item.client_name,
          branch: item.branch_name,
          date: dateFormate(item.schedule_date),
          type: actiontype
        });
      }
    });

    setFeedbackData(data);
    setEventData(data);
    setEventAllData(data);
    var meetdata = data.filter(item => item.type === 1);
    var demodata = data.filter(item => item.type === 2);
    var followdata = data.filter(item => item.type === 3);
    setCount({
      count: data.length,
      meetingCount: meetdata.length,
      democount: demodata.length,
      followcount: followdata.length
    });
  };

  const getNext7Days = () => {
    const dates = [];
    const today = new Date();

    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      dates.push(nextDate.toDateString());
    }

    var data = [];
    commonData.map(item => {
      if (
        (item.action === 1 || item.action === 3 || item.action === 5) &&
        dates.includes(new Date(item.schedule_date).toDateString())
      ) {
        var actiontype = 1;
        if (item.action == 3) {
          actiontype = 2;
        }
        if (item.action == 5) {
          actiontype = 3;
        }
        data.push({
          client: item.client_name,
          branch: item.branch_name,
          date: dateFormate(item.schedule_date),
          type: actiontype
        });
      }
    });

    setFeedbackData(data);
    setEventData(data);
    setEventAllData(data);
    var meetdata = data.filter(item => item.type === 1);
    var demodata = data.filter(item => item.type === 2);
    var followdata = data.filter(item => item.type === 3);
    setCount({
      count: data.length,
      meetingCount: meetdata.length,
      democount: demodata.length,
      followcount: followdata.length
    });
  };

  const getNext30Days = (edata) => {
    const dates = [];
    const today = new Date();

    for (let i = 0; i < 30; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      dates.push(nextDate.toDateString());
    }

    var data = [];
    var adata = edata || commonData;
    commonData.map(item => {
      if (
        (item.action === 1 || item.action === 3 || item.action === 5) &&
        dates.includes(new Date(item.schedule_date).toDateString())
      ) {
        var actiontype = 1;
        if (item.action == 3) {
          actiontype = 2;
        }
        if (item.action == 5) {
          actiontype = 3;
        }
        data.push({
          client: item.client_name,
          branch: item.branch_name,
          date: dateFormate(item.schedule_date),
          type: actiontype
        });
      }
    });

    setFeedbackData(data);
    setEventData(data);
    setEventAllData(data);
    var meetdata = data.filter(item => item.type === 1);
    var demodata = data.filter(item => item.type === 2);
    var followdata = data.filter(item => item.type === 3);
    setCount({
      count: data.length,
      meetingCount: meetdata.length,
      democount: demodata.length,
      followcount: followdata.length
    });
  };
  useEffect(() => {
    ListFeedback();
  }, []);

  return (
    <div>
      <Fragment>
        <ContentBox className="analytics" style={{ overflow: "hidden" }}>
          <Card
            elevation={3}
            sx={{ pt: "20px", mb: 3 }}
            style={{ maxHeight: "87vh" }}
          >
            <Grid container spacing={3}>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <CardHeader>
                  <Title>Activity</Title>

                  <div style={{ marginLeft: "20px" }}>
                    <button
                      onClick={() => handleTabClick("day")}
                      className={activeTab === "day" ? "ast-btn" : "st-btn"}
                    >
                      Day
                    </button>
                    <button
                      onClick={() => handleTabClick("week")}
                      className={activeTab === "week" ? "ast-btn" : "st-btn"}
                    >
                      Week
                    </button>
                    <button
                      onClick={() => handleTabClick("month")}
                      className={activeTab === "month" ? "ast-btn" : "st-btn"}
                    >
                      Month
                    </button>
                  </div>
                </CardHeader>

                <CardHeader>
                  <Card style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: "100%"
                      }}
                    >
                      <div
                        className={
                          filterTab == 0
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(0)}
                      >
                        <a>All</a>
                        <span class="notification-count">
                          {count.count}
                        </span>
                      </div>
                      <div
                        className={
                          filterTab == 1
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(1)}
                      >
                        <a>Another Meeting</a>
                        <span class="notification-count">
                          {count.meetingCount}
                        </span>
                      </div>
                      <div
                        className={
                          filterTab == 2
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(2)}
                      >
                        <a>Demo</a>
                        <span class="notification-count">
                          {count.democount}
                        </span>
                      </div>
                      <div
                        className={
                          filterTab == 3
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(3)}
                      >
                        <a>Follow Up</a>
                        <span class="notification-count">
                          {count.followcount}
                        </span>
                      </div>
                    </div>
                  </Card>
                </CardHeader>

                <Box style={{ height: "70vh", overflowY: "auto" }}>
                  {eventData.map(item =>
                    <Card elevation={3} sx={{ p: "10px", m: 2 }}>
                      <div
                        className="flex-row"
                        style={{ alignItems: "center" }}
                      >
                        <Fab
                          size="small"
                          color="primary"
                          className="button"
                          style={{
                            background: getColorCode(item.type),
                            borderRadius: "5px",
                            fontSize: "12px"
                          }}
                        >
                          {getKeyCode(item.type)}
                        </Fab>

                        <div
                          className="flex-column"
                          style={{ paddingLeft: "15px", width: "85%" }}
                        >
                          {item.client}
                          <a className="text-secondory">
                            {item.branch}
                          </a>
                          <a className="text-secondory text-orange">
                            {item.date}
                          </a>
                        </div>

                        <Icon sx={{ color: "lightgray" }}>chevron_right</Icon>
                      </div>
                    </Card>
                  )}
                </Box>
              </Grid>

              <Grid
                item
                lg={5}
                md={5}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "85vh"
                }}
              >
                <EventCalendar event={feedbackdata} />

                <div>
                  <div className="dd-card" style={{ marginTop: "2px" }}>
                    <Card
                      className="demo-card"
                      style={{ background: "#e5f6ff" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button tab-btn"
                        style={{
                          background: "#b8e6ff",
                          fontSize: "12px"
                        }}
                      >
                        <Icon sx={{ color: "#2c71ff", fontSize: "14px" }}>
                          class
                        </Icon>
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#2c71ff" }}
                        >
                          {" "}{count.meetingCount}{" "}
                        </a>
                        <a
                          className="demo-con-text"
                          style={{ fontSize: "8px" }}
                        >
                          {" "}Another Meeting{" "}
                        </a>
                      </div>
                    </Card>

                    <Card
                      className="demo-card"
                      style={{ background: "#f6f3ff" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button tab-btn"
                        style={{
                          background: "#cdc6f0",
                          fontSize: "12px"
                        }}
                      >
                        <img
                          src="assets/images/icons/install-icon.png"
                          alt=""
                          width={24}
                          height={24}
                        />
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#5844aa" }}
                        >
                          {" "}{count.democount}{" "}
                        </a>
                        <a className="demo-con-text"> Demo </a>
                      </div>
                    </Card>

                    <Card
                      className="demo-card"
                      style={{ background: "#e2f6e9" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button tab-btn"
                        style={{
                          background: "#c3e8d1",
                          fontSize: "12px"
                        }}
                      >
                        <Icon sx={{ color: "#3e995b" }}>settings</Icon>
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#3e995b" }}
                        >
                          {" "}{count.followcount}{" "}
                        </a>
                        <a className="demo-con-text"> Follow Up </a>
                      </div>
                    </Card>

                    <Card
                      className="demo-card"
                      style={{ background: "#fef0e9" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button tab-btn"
                        style={{
                          background: "#f5d3c3",
                          fontSize: "12px"
                        }}
                      >
                        <img
                          src="assets/images/icons/all-icon.png"
                          alt=""
                          width={20}
                          height={20}
                        />
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#d94a28" }}
                        >
                          {" "}{count.count}{" "}
                        </a>
                        <a className="demo-con-text"> All </a>
                      </div>
                    </Card>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </ContentBox>
      </Fragment>
    </div>
  );
};

export default CRMCalendar;
