import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { LoadingButton } from "@mui/lab";
import {
    ADD_TANK,
    UPDATE_TANK,
    GET_TANK,
    ADD_TANKDETAILS,
    SUPPLIER_LIST,
    LIST_TANKDETAILS,
    TANKDETAIL_DELETE,
    TANK_DELETE,
    LIST_TANKTRACT,
    TANKDETAIL_UPDATE
  } from "app/utils/ApiRoutes.js";
  import "react-vertical-timeline-component/style.min.css";
  import axios from "axios";
  import Swal from "sweetalert2";


const AddTank = (Transdata) => {

    // const { palette } = useTheme();
    const [countryList, setCountryList] = useState([]);
    const {tankopen,tankdetailopen,handleTank,handleTankD,callBack,tanksdata} = Transdata
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => setOpen(true);
    const [category, setCategory] = useState("");
    const [in_width, setInWidth] = useState("");
    const [in_length, setInLength] = useState("");
    const [in_height, setInHeight] = useState("");
    const [ex_width, setExWidth] = useState("");
    const [ex_length, setExLength] = useState("");
    const [ex_height, setExHeight] = useState("");
    const [watercapacity, setWaterCapacity] = useState("");
    const handleClose = () => setOpen(false);
    const [chemical, setChemical] = useState("");
    const [OnEdit, setOnEdit] = useState(0);
    const [TankData, setTankData] = useState([]);
    const [TankDetailsList, setTankDetailsList] = useState([]);
    const [SupplierData, setSupplierData] = useState([]);
    const [TrackData, setTrackData] = useState([]);
    const [selecteddetails, setSelectedDetails] = useState();
    const [sizecode, setSizeCode] = useState();
  
    useEffect(() => {
      ApiFetch();
      ApiSupplier();
      setSizeCode(tanksdata.size_code)
    }, []);
  
    // GET_TANK
    const ApiFetch = (id = 0) => {
      var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
      let config = {
        method: "GET",
        maxBodyLength: Infinity,
        url: GET_TANK+"/"+loc_id
      };
  
      axios
        .request(config)
        .then(response => {
          const data = response.data.response.data;
          setTankData(data);
          if (data.length > 0) {
            getTankDetail(data[0].id);
          }
        })
        .catch(error => {
          // console.log(error);
          
          Swal.fire(error.response.data.message)
        });
    };
  
    const getTankDetail = (id = 0) => {
      // LIST_TANKDETAILS
      var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: LIST_TANKDETAILS + id + "/" + loc_id
      };
      axios
        .request(config)
        .then(response => {
          const data = response.data;
          console.log(data);
          setTankDetailsList(data.data);
          if (data.data.length > 0) {
            setSelectedDetails(data.data[0]);
            Tanktrack(data.data[0].id);
          }
        })
        .catch(error => {
          console.log(error);
          
          Swal.fire(error.response.data.message)
          setTrackData([]);
        });
    };
  
    const ApiSupplier = () => {
      let config = {
        method: "GET",
        maxBodyLength: Infinity,
        url: SUPPLIER_LIST
      };
  
      axios.request(config)
        .then(response => {
          const data = response.data.data;
          if (data.length > 0) {
            setSupplier(data[0].id);
            setAbbrivation(data[0].abbreviation);
            setSupplierData(data);
          }
        })
        .catch(error => {
          console.log(error);
        });
    };
  
    const onAddUpdate = async type => {
      var emp_id = JSON.parse(localStorage.getItem("user_data")).id;
      var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
      setLoading(true);
      if(type == 0) {
        var data = {
          name: category,
          employee_id: emp_id,
          inventory_id: 17,
          size_code: sizecode,
          internal_width: in_width,
          internal_length: in_length,
          internal_height: in_height,
          external_width: ex_width,
          external_length: ex_length,
          external_height: ex_height,
          capacity: watercapacity,
          chemicals: chemical,
          location_id: loc_id
        };
        const API_URL = OnEdit == 0 ? ADD_TANK : UPDATE_TANK + OnEdit;
        var config = {
          method: "POST",
          maxBodyLength: Infinity,
          url: API_URL,
          data: data
        };
        handleTank();
      } else {
        var Td_data = {
          emp_id: emp_id,
          size_code: tanksdata.size_code,
          capacity: tanksdata.capacity,
          chemicals: tanksdata.chemicals,
          tank_id: tanksdata.id,
          inventory_id: tanksdata.inventory_id,
          serial_no: serialno,
          supplier: supplier || 0,
          internal_width: tanksdata.internal_width,
          internal_height: tanksdata.internal_height,
          internal_length: tanksdata.internal_length,
          external_width: tanksdata.external_width,
          external_height: tanksdata.external_height,
          external_length: tanksdata.external_length,
          location_id: loc_id,
          life_cycle: tanksdata.life_cycle,
          manufacturer: manufacturer,
          manufacture_year: year,
          company_abbreviation: abbreviation,
          status: 0,
          tanktype,
          tank_condition: tankcondition,
          inLocation: loc_id,
          tank_cost: tankcost,
          life_cycle: tanklifecycle,
          installment: parseInt(tankcost) / parseInt(tanklifecycle),
          tank_number: tanksdata.size_code
        };
  
        handleTankD();
       if(OnEdit == 0){
        if(date == undefined){
          setLoading(false);
          Swal.fire("Please Select a Date before Submit")
          return
        }
  
          Td_data.date = date
        }
  
        const API_URL = OnEdit == 0 ? ADD_TANKDETAILS : TANKDETAIL_UPDATE + OnEdit;
        var config = {
          method: "POST",
          maxBodyLength: Infinity,
          url: API_URL,
          data: Td_data
        };
      }
  
      axios
        .request(config)
        .then(response => {
          setLoading(false);
          console.log(response.data);
         
          ApiFetch();
  
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Added or Updated sucessfully',
            showConfirmButton: false,
            timer: 1500
          })
  
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
          Swal.fire(error.response.data.message)
        });

        callBack(2)
    };
  
    // TankList
    const [serialno, setSerialNo] = useState("");
    const [supplier, setSupplier] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [year, setYear] = useState("");
    const [date, setDate] = useState("");
    const [tanktype, setTankType] = useState("New Tank");
    const [tankcondition, setTankCondition] = useState("Working");
    const [abbreviation, setAbbrivation] = useState("");
    const [tankcost, setTankCost] = useState(0);
    const [tanklifecycle, setTankLifeCycle] = useState(1);
    const [installment, setInstallment] = useState("");
    const lifeCount = Array.from({ length: 65 }, (_, index) => index + 1);
    

  
    const handleselectTank = event => {
      setSupplier(event.target.value);
      var data = SupplierData.filter(
        item => item.id === parseInt(event.target.value)
      );
      if (data.length > 0) {
        setAbbrivation(data[0].abbreviation);
      }
    };
  
    const handleSelectTank = event => {
      setTankType(event.target.value);
    };
  
    const handleSelectCondition = event => {
      setTankCondition(event.target.value);
    };
  
    const handleSelectCycle = event => {
      setTankLifeCycle(event.target.value);
    };

    const Tanktrack = id => {
      let config = {
        method: "GET",
        maxBodyLength: Infinity,
        url: LIST_TANKTRACT + id
      };
  
      setTrackData([]);
      axios
        .request(config)
        .then(response => {
          const data = response.data;
          console.log(data);
          setTrackData(data.data);
        })
        .catch(error => {
          setTrackData([]);
          console.log(error);
        });
  
    }

    return (
    <div>
    <Dialog
      open={tankopen}
      onClose={handleTank}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ width: "300px" }}>
        Tank
      </DialogTitle>
      <DialogContent>
        <div className="mt-20">Category</div>
        <TextField
          fullWidth
          id="name"
          type="text"
          margin="dense"
          focused={false}
          placeholder="Category"
          onChange={e => setCategory(e.target.value)}
          value={category}
        />
        <div className="mt-20">Size Code</div>
        <TextField
          fullWidth
          id="name"
          type="text"
          margin="dense"
          focused={false}
          placeholder="Size Code"
          onChange={e => setSizeCode(e.target.value)}
          value={sizecode}
        />

        <div style={{ marginTop: "20px" }}>
          <a>Internal Size</a>
        </div>
        <div style={{ display: "flex" }}>
          <TextField
            fullWidth
            id="name"
            type="text"
            margin="dense"
            focused={false}
            label="Width"
            onChange={e => setInWidth(e.target.value)}
            value={in_width}
            style={{ marginRight: "10px" }}
          />
          <TextField
            fullWidth
            id="name"
            type="text"
            margin="dense"
            focused={false}
            label="Length"
            onChange={e => setInLength(e.target.value)}
            value={in_length}
            style={{ marginRight: "10px" }}
          />
          <TextField
            fullWidth
            id="name"
            type="text"
            margin="dense"
            focused={false}
            label="Height"
            onChange={e => setInHeight(e.target.value)}
            value={in_height}
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <a>EXternal Size</a>
        </div>

        <div style={{ display: "flex" }}>
          <TextField
            fullWidth
            id="name"
            type="text"
            margin="dense"
            focused={false}
            label="Width"
            onChange={e => setExWidth(e.target.value)}
            value={ex_width}
            style={{ marginRight: "10px" }}
          />
          <TextField
            fullWidth
            id="name"
            type="text"
            margin="dense"
            focused={false}
            label="Length"
            onChange={e => setExLength(e.target.value)}
            value={ex_length}
            style={{ marginRight: "10px" }}
          />
          <TextField
            fullWidth
            id="name"
            type="text"
            margin="dense"
            focused={false}
            label="Height"
            onChange={e => setExHeight(e.target.value)}
            value={ex_height}
          />
        </div>

        <div className="mt-20">Water Capacity in Ltr</div>
        <TextField
          fullWidth
          id="name"
          type="text"
          margin="dense"
          focused={false}
          placeholder="Water Capacity in Ltr"
          onChange={e => setWaterCapacity(e.target.value)}
          value={watercapacity}
        />
        <div className="mt-20">Chemical in Kg</div>
        <TextField
          fullWidth
          id="name"
          type="text"
          margin="dense"
          focused={false}
          placeholder="Chemical in Kg"
          onChange={e => setChemical(e.target.value)}
          value={chemical}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleTank}>
          Cancel
        </Button>

        <LoadingButton
          variant="outlined"
          onClick={() => {
            onAddUpdate(0);
          }}
          color="primary"
          loading={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
    <Dialog
      open={tankdetailopen}
      onClose={handleTankD}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
        Tank Details
      </DialogTitle>

      <DialogContent>
        <div className="mt-20">Tank Size Code</div>
        <TextField
          fullWidth
          id="name"
          type="text"
          margin="dense"
          focused={false}
          placeholder="Tank Size Code"
          value={tanksdata.size_code}
          disabled
        />

        <div className="mt-20">Tank Serial No</div>
        <TextField
          fullWidth
          id="name"
          type="text"
          margin="dense"
          focused={false}
          placeholder="Tank Serial No"
          onChange={e => setSerialNo(e.target.value)}
          value={serialno}
        />

        <div className="mt-20">Supplier</div>
        <TextField
          id="supplier"
          name="supplier"
          fullWidth
          select
          margin="dense"
          focused={false}
          SelectProps={{
            native:true
          }}
          value={supplier}
          onChange={handleselectTank}
         >

          {SupplierData &&
            SupplierData.map(item =>
              <option value={item.id}>
                {" "}{item.company_name}{" "}
              </option>
            )}
        </TextField>

        <div className="mt-20">Manufacture</div>
        <TextField
          fullWidth
          id="name"
          type="text"
          margin="dense"
          focused={false}
          placeholder="Manufacture"
          onChange={e => setManufacturer(e.target.value)}
          value={manufacturer}
        />

        <div className="mt-20">Tank Manufacture Year</div>
        <TextField
          fullWidth
          id="name"
          type="number"
          margin="dense"
          focused={false}
          placeholder="Tank Manufacture Year"
          onChange={e => setYear(e.target.value)}
          value={year}
        />
        {OnEdit === 0 && 
          <div>
          <div className="mt-20">Date</div>
          <TextField
            fullWidth
            id="name"
            type="date"
            margin="dense"
            focused={false}
            placeholder="Date"
            onChange={e => setDate(e.target.value)}
            value={date}
          />
          </div>
        }
      
        <div className="mt-20">Tank Type</div>
        <TextField
          id="tank-type"
          name="tank-type"
          fullWidth
          select
          margin="dense"
          focused={false}
          SelectProps={{
            native: true
          }}
          value={tanktype}
          onChange={handleSelectTank}
        >
          <option value={"New Tank"}> New Tank </option>
          <option value={"Used Tank"}> Used Tank </option>
          <option value={"Refurbished Tank"}> Refurbished Tank </option>
        </TextField>

        <div className="mt-20">Tank Condition</div>
        <TextField
          id="tank-condition"
          name="tank-condition"
          fullWidth
          select
          margin="dense"
          focused={false}
          SelectProps={{
            native: true
          }}
          value={tankcondition}
          onChange={handleSelectCondition}
        >
          <option value={"Working"}> Working </option>
          <option value={"Not Working"}> Not Working </option>
          <option value={"Need Maintenance"}> Need Maintenance </option>
          <option value={"Need Refurbish"}> Need Refurbish </option>
        </TextField>

        <div className="mt-20">Tank Cost</div>
        <TextField
          fullWidth
          id="name"
          type="number"
          margin="dense"
          focused={false}
          placeholder="Tank Cost"
          onChange={e => setTankCost(e.target.value)}
          value={tankcost}
        />

        <div className="mt-20">Tank Lifecycle</div>
        <TextField
          id="tank-lifecycle"
          name="tank-lifecycle"
          fullWidth
          select
          margin="dense"
          focused={false}
          SelectProps={{
            native: true
          }}
          value={tanklifecycle}
          onChange={handleSelectCycle}
        >
          {lifeCount.map(number =>
            <option value={number}>
              {" "}{number}{" "}
            </option>
          )}
        </TextField>

        <div className="mt-20">Installment</div>
        <TextField
          fullWidth
          id="name"
          type="number"
          margin="dense"
          focused={false}
          placeholder="Installment"
          disabled
          onChange={e => setInstallment(e.target.value)}
          value={parseInt(tankcost) / parseInt(tanklifecycle)}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleTankD}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          onClick={() => {
            onAddUpdate(1);
          }}
          color="primary"
          loading={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
    </div>
  )
}

export default AddTank