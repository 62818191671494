import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Fragment } from "react";
import {
  CATEGORY_ADD,
  CATEGORY_LIST,
  CATEGORY_UPDATE,
  CATEGORY_DELETE
} from "app/utils/ApiRoutes.js";
import Swal from "sweetalert2";
import axios from "axios";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const Category = () => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(0);
  const handleClose = () => {
    setOpen(false);
    setError("");
  };
  const [category, setCategory] = useState("");
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [updatedata, setUpdateData] = useState();
  const handleClickOpen = (type, data) => {
    setIsAdd(type);
    if (type == 1) {
      setUpdateData(data);
      setCategory(data.category);
    } else {
      setCategory("");
    }
    setOpen(true);
  };
  const AddUpdateCategory = async type => {
    var userdata = await localStorage.getItem("user_data");
    userdata = JSON.parse(userdata);
    var data = {
      created_by: userdata.id,
      category: category
    };
    
    const API_URL =
      type == 0 ? CATEGORY_ADD : CATEGORY_UPDATE + updatedata.cat_id;
    let config = {
      method: "POST",
      url: API_URL,
      data: data
    };
    axios
      .request(config)
      .then(response => {
        console.log(response.data);
        ListCategory();
        handleClose();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      }).catch(error => {
        handleClose();
      });
  };
  const ListCategory = () => {
    let config = {
      method: "GET",
      url: CATEGORY_LIST
    };
    axios.request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          setData(resdata);
        } else {
          setData([]);
        }
        handleClose();
      }).catch(error => {
        handleClose();
        setData([]);
      });
  };

  const DeleteCategory = id => {
  
    let config = {
      method: "GET",
      url: CATEGORY_DELETE + id
    };

    axios.request(config)
      .then(response => {
        ListCategory();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDelete = id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        DeleteCategory(id);
      }
    });
  };

  const onSubmit = () => {
    if (!category) {
      setError("*required all fields");
      return;
    } else {
      setError("");
    }

    AddUpdateCategory(isAdd);
  };

  useEffect(() => {
    ListCategory();
  }, []);

  return (
    <div>
      <Fragment>
        <ContentBox className="analytics">
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <Title>Category</Title>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                    onClick={() => handleClickOpen(0, "")}
                  >
                    <Icon>add</Icon>
                  </Fab>
                </CardHeader>

                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={2}>
                          Category
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={2}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {data.length > 0 &&
                        data.map(item =>
                          <TableRow hover>
                            <TableCell
                              align="left"
                              colSpan={2}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <div className="flex-column">
                                {item.category}
                              </div>
                            </TableCell>

                            <TableCell sx={{ px: 0 }} colSpan={2}>
                              <IconButton
                                onClick={() => handleClickOpen(1, item)}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>
                              <IconButton onClick={() => onDelete(item.cat_id)}>
                                <Icon style={{ color: "red" }}>delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </ContentBox>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "300px" }}>
            {isAdd == 0 ? "Add" : "Update"} Category
          </DialogTitle>

          <DialogContent>
            <div>Category</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Category"
              onChange={e => setCategory(e.target.value)}
              value={category}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              variant="outlined"
              onClick={() => onSubmit()}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>

          <span className="text-red" style={{ textAlign: "center" }}>
            {error}
          </span>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default Category;
