import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Fab,
  TablePagination
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Fragment } from "react";
import {
  SPARE_ADD,
  SPARE_LIST,
  SPARE_DELETE,
  SPARE_UPDATE,
  SPARE_HISTORY,
  UNIT_LIST,
  GET_TANK,
  GET_TANK_DETAILS,
  SPARE_ADD_OR_TAKEN
} from "app/utils/ApiRoutes.js";
import Swal from "sweetalert2";

import axios from "axios";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const Spareparts = () => {
  const { palette } = useTheme();
  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);
  const [loading, setLoading] = useState(false);
  const [OnEdit, setOnEdit] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [spareID, setSpareID] = useState("");
  const [Name, setName] = useState("");
  const [NoPieces, setNoPieces] = useState("");
  const [CostOfEach, setCostOfEach] = useState("");
  const [StoreData, setStoreData] = useState([]);
  const [LowStack, setLowStack] = useState();
  const [UnitId, setUnitId] = useState();
  const [spareUnit, setspareUnit] = useState();

  useEffect(() => {
    ApiFetch();
    ApiFetchSpare();
    ListTank();
  }, []);

  const ApiFetch = () => {
    let config = {
      method: "GET",
      url: UNIT_LIST
    };

    axios
      .request(config)
      .then(response => {
        const data = response.data.data;
        setspareUnit(data);
        if (data.length > 0) {
          setUnitId(data[0].id);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const ApiFetchSpare = () => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    let config = {
      method: "GET",
      url: SPARE_LIST + locid
    };

    axios
      .request(config)
      .then(response => {
        const data = response.data.data;
        console.log(data);
        if (data && data.length > 0) {
          setStoreData(data);
        } else {
          setStoreData([]);
        }
      })
      .catch(error => {
        setStoreData([]);
        console.log(error);
      });
  };

  const onAddSpareOpen = data => {
    if (data) {
      setOnEdit(data.id)
      setName(data.parts_name);
      setCostOfEach(data.cost_of_each);
      setLowStack(data.low_stack);
      setUnitId(parseInt(data.unit_id));
    } else {
      setOnEdit(0);
      setName("");
      setCostOfEach("");
      setLowStack("");
      ApiFetch()
    }

    handleClickOpen();
  };

  const onAddUpdateSpare = () => {
    var emp_id = JSON.parse(localStorage.getItem("user_data")).id;
    var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
    var data = {
      parts_name: Name,
      no_of_pieces: NoPieces,
      cost_of_each: CostOfEach,
      stored_date: new Date().toLocaleDateString(),
      employee_id: emp_id,
      location_id: loc_id,
      low_stack: LowStack,
      unit_id: UnitId
    };

    const API_URL = OnEdit == 0 ? SPARE_ADD : SPARE_UPDATE + OnEdit;
    var config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: API_URL,
      data: data
    };

    setLoading(true);
    axios
      .request(config)
      .then(response => {
        setLoading(false);
        ApiFetchSpare();
        handleClose();
        console.log(response.data);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        setLoading(false);
        handleClose();
        Swal.fire(error.response.data.message)
      });
  };

  const handleSelectChange = event => {
    const selectedValue = event.target.value;
    setUnitId(selectedValue);
  };

  const PartsDelete = id => {
    let config = {
      method: "GET",
      url: SPARE_DELETE + id
    };

    axios
      .request(config)
      .then(response => {
        ApiFetchSpare();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Deleted sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDelete = id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        PartsDelete(id);
      }
    });
  };

  const [adderror, setAddError] = useState("");
  const [takenerror, setTakenError] = useState("");

  const [detailopen, setDetailOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState();
  const handleDetailClose = () => setDetailOpen(false);
  const handleDetailOpen = item => {
    setSelectedDetail(item);
    setDetailOpen(true);
  };

  const [addopen, setAddOpen] = useState(false);
  const handleAddClose = () => setAddOpen(false);
  const handleAddOpen = () => {
    handleDetailClose();
    setAddError("");
    setAddOpen(true);
  };

  const [takeopen, setTakeOpen] = useState(false);
  const handleTakeClose = () => setTakeOpen(false);
  const handleTakeOpen = () => {
    handleDetailClose();
    setTakeOpen(true);
  };

  // adden
  const [piecescount, setPiecesCount] = useState();
  const [price, setPrice] = useState();
  const [date, setDate] = useState();

  const [reqpieces, setReqPieces] = useState();
  const [customer, setCustomer] = useState();
  const [purpose, setPurpose] = useState();
  const [tanktype, setTankType] = useState("");
  const [tanksn, setTankSN] = useState("");

  const [tankdata, setTankData] = useState("");
  const [serialnodata, setSerialNodata] = useState("");

  const ListTank = () => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    var data = {
      location_id: locid
    };
    let config = {
      method: "POST",
      url: GET_TANK,
      data: data
    };
    axios
      .request(config)
      .then(response => {
        console.log(response.data.data);
        var resdata = response.data.data;
        if (resdata.length > 0) {
          ListSerialNo(resdata[0].id);
          setTankData(resdata);
        } else {
          setTankData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setTankData([]);
      });
  };

  const ListSerialNo = ID => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    var data = {
      tank_id: ID,
      location_id: locid
    };
    let config = {
      method: "POST",
      url: GET_TANK_DETAILS,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.data);
        var resdata = response.data.data;
        if (resdata.length > 0) {
          setTankSN(resdata[0].id);
          setSerialNodata(resdata);
        } else {
          setSerialNodata([]);
        }
      })
      .catch(error => {
        console.log(error);
        setSerialNodata([]);
      });
  };

  const AddTakeSpareparts = (type) => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    var userid = userdata && userdata.id ? userdata.id : "";
    var adddata = {
      spare_id: selectedDetail.id,
      unit: selectedDetail.unit_id,
      location_id: locid,
      taken_employee_id: userid,
      no_of_pieces: piecescount,
      added_date: date,
      price: price,
      required_no_of_pieces: "",
      tank_size: "",
      tank_serial_no: "",
      purpose: "",
      customer_id: "",
      add_or_taken: 0
    };
    var takendata = {
      spare_id: selectedDetail.id,
      unit: selectedDetail.unit_id,
      location_id: locid,
      taken_employee_id: userid,
      no_of_pieces: reqpieces,
      added_date: new Date().toISOString(),
      price: "",
      required_no_of_pieces: reqpieces,
      tank_size: tanktype,
      tank_serial_no: tanksn,
      purpose: purpose,
      customer_id: customer,
      add_or_taken: 1
    };

    let config = {
      method: "POST",
      url: SPARE_ADD_OR_TAKEN,
      data: type == 0 ? adddata : takendata
    };



    if(type == 1){
      if(reqpieces > selectedDetail.no_of_pieces){
        Swal.fire("Your stock Required higher than Selected Packet ")
        setLoading(false);
        handleAddClose();
        handleTakeClose();
        ApiFetchSpare();
        ListSpareHistory();
        return
      }
    }
    setLoading(true);
    axios
      .request(config)
      .then(response => {
        console.log(response.data);
        setLoading(false);
        handleAddClose();
        handleTakeClose();
        ApiFetchSpare();
        ListSpareHistory();
      })
      .catch(error => {
        setLoading(false);
        handleAddClose();
        handleTakeClose();
        console.log(error);
      });
  };

  useEffect(() => {
    ListSpareHistory();
  }, []);

  const [historyData, setHistoryData] = useState([]);

  const ListSpareHistory = () => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    let config = {
      method: "GET",
      url: SPARE_HISTORY + locid
    };

    axios
      .request(config)
      .then(response => {
        const data = response.data.data;
        console.log(data);
        if (data.length > 0) {
          setHistoryData(data);
        } else {
          setHistoryData([]);
        }
      })
      .catch(error => {
        setHistoryData([]);
        console.log(error);
      });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Fragment>
        <ContentBox className="analytics">
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <Title>Spareparts</Title>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                    onClick={() => onAddSpareOpen()}
                  >
                    <Icon>add</Icon>
                  </Fab>
                </CardHeader>
                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={2}>
                          Sparepart
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={2}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {StoreData &&
                        StoreData.map(item =>
                          <TableRow hover>
                            <TableCell
                              align="left"
                              colSpan={2}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <div className="flex-column">
                                {item.parts_name}
                                <div className="text-secondory">
                                  No of Pieces:<a className="text-secondory text-orange">
                                    {item.no_of_pieces}
                                  </a>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell sx={{ px: 0 }} colSpan={2}>
                              <div>
                                <div>
                                  <IconButton>
                                    <Icon
                                      style={{ color: "red" }}
                                      onClick={() => onDelete(item.id)}
                                    >
                                      delete
                                    </Icon>
                                  </IconButton>
                                  <IconButton>
                                    <Icon
                                      onClick={() => handleDetailOpen(item)}
                                      style={{ color: "#795548" }}
                                    >
                                      remove_red_eye
                                    </Icon>
                                  </IconButton>
                                  <IconButton>
                                    <Icon style={{ color: "#3F51B5" }}>
                                      loop
                                    </Icon>
                                  </IconButton>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
                </Box>
              </Card>
            </Grid>

            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
            
                <CardHeader>
                  <Title>History</Title>
                  <Fab
                    size="small"
                    color="white"
                    style={{ background: "white", boxShadow: "none" }}
                  />
                </CardHeader>

                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={1} />
                        <TableCell className="pl-0" sx={{ px: 3 }} colSpan={3}>
                          Spareparts
                        </TableCell>
                        <TableCell className="pl-0" sx={{ px: 3 }} colSpan={2}>
                          Date
                        </TableCell>
                        <TableCell className="pl-0" sx={{ px: 3 }} colSpan={2}>
                          Purpose
                        </TableCell>
                        <TableCell className="pl-0" sx={{ px: 0 }} colSpan={2}>
                          BY
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {historyData &&
                        historyData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, index) =>
                            <TableRow hover>
                              <TableCell
                                align="left"
                                colSpan={1}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                {parseInt(item.add_or_taken) == 0
                                  ? <IconButton>
                                      <Icon className="text-green">
                                        arrow_upward
                                      </Icon>
                                    </IconButton>
                                  : <IconButton>
                                      <Icon className="text-orange">
                                        arrow_downward
                                      </Icon>
                                    </IconButton>}
                              </TableCell>

                              <TableCell
                                align="left"
                                colSpan={3}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                <div className="flex-column">
                                  {item.parts_name}
                                  <div className="text-secondory">
                                    QTY :{" "}
                                    <a className="text-secondory text-orange">
                                      {item.no_of_pieces}
                                    </a>
                                  </div>
                                  {/* <div className="text-secondory">
                                  Price :{" "}
                                  <a className="text-secondory text-green">
                                    500
                                  </a>
                                </div> */}
                                </div>
                              </TableCell>

                              <TableCell
                                align="left"
                                colSpan={2}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                {new Date(item.date).toLocaleDateString()}
                              </TableCell>

                              <TableCell
                                align="left"
                                colSpan={2}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                {item.purpose}
                              </TableCell>

                              <TableCell
                                align="left"
                                colSpan={2}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                User
                              </TableCell>
                            </TableRow>
                          )}
                    </TableBody>
                  </ProductTable>
                  <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={historyData.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                  />
                  {/* {!branchData && <h1 className="no-data">No Data Found</h1>} */}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </ContentBox>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "300px" }}>
            Spareparts
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">Parts Name</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Parts Name"
              onChange={e => setName(e.target.value)}
              value={Name}
            />         
            <div className="mt-20">Cost of Each</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="number"
              margin="dense"
              focused={false}
              placeholder="Cost of Each"
              onChange={e => setCostOfEach(e.target.value)}
              value={CostOfEach}
            />

            <div className="mt-20">Low Stack</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="number"
              margin="dense"
              focused={false}
              placeholder="Low Stack"
              onChange={e => setLowStack(e.target.value)}
              value={LowStack}
            />

            <div className="mt-20">Unit</div>
            <select
              className="select-drop"
              value={UnitId}
              onChange={handleSelectChange}
            >
              {spareUnit &&
                spareUnit.map((item, index) =>
                  <option value={item.id}>
                    {item.unit_name}
                  </option>
                )}
            </select>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => onAddUpdateSpare()}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Detail */}
        <Dialog
          open={detailopen}
          onClose={handleDetailClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{
              width: "300px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            Spareparts Details
            <IconButton>
              <Icon style={{ color: "red" }} onClick={handleDetailClose}>
                close
              </Icon>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <div style={{ display: "flex" }}>
              <div style={{ width: "150px" }}>Parts Name </div>
              <a>:</a>
              <div style={{ marginLeft: "5px" }}>
                {selectedDetail && selectedDetail.parts_name}
              </div>
            </div>

            <div style={{ display: "flex" }} className="mt-20">
              <div style={{ width: "150px" }}>Total Pieces </div>
              <a>:</a>
              <div style={{ marginLeft: "5px" }}>
                {" "}{selectedDetail && selectedDetail.no_of_pieces}{" "}
              </div>
            </div>
          </DialogContent>

          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleAddOpen()}
            >
              Add
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => handleTakeOpen()}
              color="primary"
              loading={loading}
            >
              Take
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Adden Parts */}
        <Dialog
          open={addopen}
          onClose={handleAddClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
            {selectedDetail && selectedDetail.parts_name}
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">No Of Pieces</div>
            <TextField
              fullWidth
              autoFocus
              id="numberofpieces"
              type="number"
              margin="dense"
              focused={false}
              onChange={e => setPiecesCount(e.target.value)}
              value={piecescount}
            />

            <div className="mt-20">Price</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="number"
              margin="dense"
              focused={false}
              onChange={e => setPrice(e.target.value)}
              value={price}
            />

            <div className="mt-20">Date</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="date"
              margin="dense"
              focused={false}
              onChange={e => setDate(e.target.value)}
              value={date}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleAddClose}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => AddTakeSpareparts(0)}
              color="primary"
              loading={loading}
            >
              Add
            </LoadingButton>
          </DialogActions>

          <span
            style={{ textAlign: "center", marginBottom: "5px", color: "red" }}
          >
            {adderror}
          </span>
        </Dialog>

        {/* Take Chemicals */}
        <Dialog
          open={takeopen}
          onClose={handleTakeClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
            {selectedDetail && selectedDetail.parts_name}
          </DialogTitle>

          <DialogContent>
            <div style={{ display: "flex" }}>
              <div style={{ width: "150px" }}>Total Pieces </div>
              <a>:</a>
              <div style={{ marginLeft: "5px" }}>
                {selectedDetail && selectedDetail.no_of_pieces}
              </div>
            </div>

            <div className="mt-20">How many pieces do you need?</div>
            <TextField
              fullWidth
              autoFocus
              id="numberofpackets"
              type="number"
              margin="dense"
              focused={false}
              onChange={e => setReqPieces(e.target.value)}
              value={reqpieces}
            />

            <div className="mt-20">Tank Type</div>
            <TextField
              id="tank-type"
              name="tank-type"
              fullWidth
              select
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={tanktype}
              onChange={e => {
                setTankType(e.target.value);
                ListSerialNo(e.target.value);
              }}
            >
              {tankdata &&
                tankdata.map(item =>
                  <option value={item.id}>
                    {" "}{item.name}{" "}
                  </option>
                )}
            </TextField>

            <div className="mt-20">Tank Serial No</div>
            <TextField
              id="tank-sn"
              name="tank-sn"
              fullWidth
              select
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={tanksn}
              onChange={e => setTankSN(e.target.value)}
            >
              {serialnodata &&
                serialnodata.map(item =>
                  <option value={item.id}>
                    {" "}{item.serial_no}{" "}
                  </option>
                )}
            </TextField>

            <div className="mt-20">Customer Name</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              focused={false}
              onChange={e => setCustomer(e.target.value)}
              value={customer}
            />

            <div className="mt-20">Purpose</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              focused={false}
              onChange={e => setPurpose(e.target.value)}
              value={purpose}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleTakeClose}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => AddTakeSpareparts(1)}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
          <span
            style={{ textAlign: "center", marginBottom: "5px", color: "red" }}
          >
            {takenerror}
          </span>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default Spareparts;
