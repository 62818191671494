import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Fragment } from "react";
import {
  ADD_TANK,
  UPDATE_TANK,
  GET_TANK,
  ADD_TANKDETAILS,
  SUPPLIER_LIST,
  LIST_TANKDETAILS,
  TANKDETAIL_DELETE,
  TANK_DELETE,
  LIST_TANKTRACT,
  TANKDETAIL_UPDATE
} from "app/utils/ApiRoutes.js";

import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";
import axios from "axios";
import Swal from "sweetalert2";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const Tanks = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [category, setCategory] = useState("");
  const [sizecode, setSizeCode] = useState("");
  const [in_width, setInWidth] = useState("");
  const [in_length, setInLength] = useState("");
  const [in_height, setInHeight] = useState("");
  const [ex_width, setExWidth] = useState("");
  const [ex_length, setExLength] = useState("");
  const [ex_height, setExHeight] = useState("");
  const [watercapacity, setWaterCapacity] = useState("");
  const [chemical, setChemical] = useState("");
  const [OnEdit, setOnEdit] = useState(0);
  const [TankData, setTankData] = useState([]);
  const [SelectTankDetails, setSelectTankDetails] = useState([]);
  const [TankDetailsList, setTankDetailsList] = useState([]);
  const [SupplierData, setSupplierData] = useState([]);
  const [TrackData, setTrackData] = useState([]);
  const [selecteddetails, setSelectedDetails] = useState();

  const onTankModalOpen = data => {
    if (data) {
      setCategory(data.name);
      setSizeCode(data.size_code);
      setInWidth(data.internal_width);
      setInHeight(data.internal_height);
      setInLength(data.internal_length);
      setExWidth(data.external_width);
      setExHeight(data.external_height);
      setExLength(data.external_length);
      setWaterCapacity(data.capacity);
      setChemical(data.chemicals);
    } else {
      setCategory("");
      setSizeCode("");
      setInWidth("");
      setInHeight("");
      setInLength("");
      setExWidth("");
      setExHeight("");
      setExLength("");
      setWaterCapacity("");
      setChemical("");
    }
    handleClickOpen();
  };

  useEffect(() => {
    ApiFetch();
    ApiSupplier();
  }, []);

  // GET_TANK
  const ApiFetch = (id = 0) => {
    var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: GET_TANK+"/"+loc_id
    };

    axios
      .request(config)
      .then(response => {
        const data = response.data.response.data;
        setTankData(data);
        if (data.length > 0) {
          setSelectTankDetails(data[0]);
          getTankDetail(data[0].id);
        }
      })
      .catch(error => {
        // console.log(error);
        
        Swal.fire(error.response.data.message)
      });
  };

  const getTankDetail = (id = 0) => {
    // LIST_TANKDETAILS
    var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: LIST_TANKDETAILS + id + "/" + loc_id
    };
    axios
      .request(config)
      .then(response => {
        const data = response.data;
        console.log(data);
        setTankDetailsList(data.data);
        if (data.data.length > 0) {
          setSelectedDetails(data.data[0]);
          Tanktrack(data.data[0].id);
        }
      })
      .catch(error => {
        console.log(error);
        
        Swal.fire(error.response.data.message)
        setTrackData([]);
      });

    // setSelectTankDetails
  };

  const ApiSupplier = () => {
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: SUPPLIER_LIST
    };

    axios.request(config)
      .then(response => {
        const data = response.data.data;
        if (data.length > 0) {
          setSupplier(data[0].id);
          setAbbrivation(data[0].abbreviation);
          setSupplierData(data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onAddUpdate = async type => {
    var emp_id = JSON.parse(localStorage.getItem("user_data")).id;
    var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
   
    setLoading(true);

    if(type == 0) {
      var data = {
        name: category,
        employee_id: emp_id,
        inventory_id: 17,
        size_code: sizecode,
        internal_width: in_width,
        internal_length: in_length,
        internal_height: in_height,
        external_width: ex_width,
        external_length: ex_length,
        external_height: ex_height,
        capacity: watercapacity,
        chemicals: chemical,
        location_id: loc_id
      };
      const API_URL = OnEdit == 0 ? ADD_TANK : UPDATE_TANK + OnEdit;
      var config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: API_URL,
        data: data
      };
    } else {
      var Td_data = {
        emp_id: emp_id,
        size_code: SelectTankDetails.size_code,
        capacity: SelectTankDetails.capacity,
        chemicals: SelectTankDetails.chemicals,
        tank_id: SelectTankDetails.id,
        inventory_id: SelectTankDetails.inventory_id,
        serial_no: serialno,
        supplier: supplier || 0,
        internal_width: SelectTankDetails.internal_width,
        internal_height: SelectTankDetails.internal_height,
        internal_length: SelectTankDetails.internal_length,
        external_width: SelectTankDetails.external_width,
        external_height: SelectTankDetails.external_height,
        external_length: SelectTankDetails.external_length,
        location_id: loc_id,
        life_cycle: SelectTankDetails.life_cycle,
        manufacturer: manufacturer,
        manufacture_year: year,
        company_abbreviation: abbreviation,
        status: 0,
        tanktype,
        tank_condition: tankcondition,
        inLocation: loc_id,
        tank_cost: tankcost,
        life_cycle: tanklifecycle,
        installment: parseInt(tankcost) / parseInt(tanklifecycle),
        tank_number: SelectTankDetails.size_code
      };


      if(OnEdit == 0){

      if(date == undefined || tankcost <= 0){
        setLoading(false);
        handleBranchClose();
        handleClose();
        Swal.fire("Please Enter Valid data in Fields before Submit")
        return
      }
      
      Td_data.date = date
      
    }

      const API_URL = OnEdit == 0 ? ADD_TANKDETAILS : TANKDETAIL_UPDATE + OnEdit;
      var config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: API_URL,
        data: Td_data
      };
    }

    axios
      .request(config)
      .then(response => {
        setLoading(false);
        console.log(response.data);

        handleBranchClose();
        handleClose();
        ApiFetch();

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })

      })
      .catch(error => {
        setLoading(false);
        handleBranchClose();
        handleClose();
        console.log(error);
        Swal.fire(error.response.data.message)
      });
  };

  // TankList
  const [branchOpen, setBranchOpen] = useState(false);
  const handleClickBranchOpen = () => setBranchOpen(true);
  const handleBranchClose = () => setBranchOpen(false);

  const [serialno, setSerialNo] = useState("");
  const [supplier, setSupplier] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [year, setYear] = useState("");
  const [date, setDate] = useState("");
  const [tanktype, setTankType] = useState("New Tank");
  const [tankcondition, setTankCondition] = useState("Working");
  const [inLocation, setInLocation] = useState("");
  const [abbreviation, setAbbrivation] = useState("");
  const [tankcost, setTankCost] = useState(0);
  const [tanklifecycle, setTankLifeCycle] = useState(1);
  const [installment, setInstallment] = useState("");
  const lifeCount = Array.from({ length: 65 }, (_, index) => index + 1);
  const [contactOpen, setContactOpen] = useState(false);
  const handleContactClose = () => setContactOpen(false);

  const handleBranchOpen = data =>{
    if (data) {
      setSerialNo(data.serial_no);
      setSupplier(data.supplier);
      setAbbrivation(data.company_abbreviation);
      setManufacturer(data.manufacturer);
      setYear(data.manufacture_year);
      setDate(data.date);
      setTankType(data.tank_type);
      setTankCondition(data.tank_condition);
      setTankCost(data.tank_cost);
      setTankLifeCycle(data.life_cycle);
    } else {
      setSerialNo();
      ApiSupplier();
      setManufacturer();
      setYear();
      setDate();
      setTankType("New Tank");
      setTankCondition("Working");
      setTankCost(0);
      setTankLifeCycle(1);
    }
    handleClickBranchOpen();
  };

  const handleselectTank = event => {
    setSupplier(event.target.value);
    var data = SupplierData.filter(
      item => item.id === parseInt(event.target.value)
    );
    if (data.length > 0) {
      setAbbrivation(data[0].abbreviation);
    }
  };

  const handleSelectTank = event => {
    setTankType(event.target.value);
  };

  const handleSelectCondition = event => {
    setTankCondition(event.target.value);
  };

  const handleSelectCycle = event => {
    setTankLifeCycle(event.target.value);
  };

  const handleSelectTankDetail = data => {
    setSelectedDetails(data);
    Tanktrack(data.id);
  };

  const onDeleteTank = async (type, id) => {
    if (type == "0") {
      var config = {
        method: "GET",
        maxBodyLength: Infinity,
        url: TANK_DELETE + id
      };
    } else {
      var config = {
        method: "GET",
        maxBodyLength: Infinity,
        url: TANKDETAIL_DELETE + id
      };
    }

    axios
      .request(config)
      .then(response => {
        ApiFetch();
        console.log(response.data);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Deleted sucessfully',
          showConfirmButton: false,
          timer: 1500
        })

      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDelete = (type, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        onDeleteTank(type, id);
      }
    });
  };

  const Tanktrack = id => {
    var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: LIST_TANKTRACT + id
    };

    setTrackData([]);
    axios
      .request(config)
      .then(response => {
        const data = response.data;
        console.log(data);
        setTrackData(data.data);
      })
      .catch(error => {
        setTrackData([]);
        console.log(error);
      });
  };

  return (
    <div>
      <Fragment>
        <ContentBox className="analytics">
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <Title>Tank</Title>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                    onClick={() => {
                      setOnEdit(0);
                      onTankModalOpen();
                    }}
                  >
                    <Icon>add</Icon>
                  </Fab>
                </CardHeader>

                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={2}>
                          Tank
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={2}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {Array.isArray(TankData) &&
                        TankData.map(item =>
                          <TableRow
                            hover
                            onClick={() => {
                              setSelectTankDetails(item);
                              getTankDetail(item.id);
                            }}
                          >
                            <TableCell
                              align="left"
                              colSpan={2}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <div className="flex-column">
                                {item.name}
                                <div className="text-secondory">
                                  Total Tanks:<a className="text-secondory text-orange">
                                    {item.totaltank}
                                  </a>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell sx={{ px: 0 }} colSpan={2}>
                              <IconButton
                                onClick={() => {
                                  setOnEdit(item.id);
                                  onTankModalOpen(item);
                                }}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  onDelete("0", item.id);
                                }}
                              >
                                <Icon style={{ color: "red" }}>delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
                </Box>
              </Card>
            </Grid>

            <Grid item lg={4} md={5} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <Title>
                    {SelectTankDetails && SelectTankDetails.name}
                  </Title>

                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                    onClick={() => {
                      setOnEdit(0);
                      handleBranchOpen();
                    }}
                  >
                    <Icon>add</Icon>
                  </Fab>
                </CardHeader>

                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={2}>
                          Tank
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={2}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {Array.isArray(TankDetailsList) &&
                        TankDetailsList.map(item =>
                          <TableRow
                            hover
                            onClick={() => handleSelectTankDetail(item)}
                          >
                            <TableCell
                              align="left"
                              colSpan={2}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <div className="flex-column">
                                {item.serial_no}
                                <div className="text-secondory">
                                  Condition :{" "}
                                  <a className="text-secondory text-orange">
                                    {item.tank_condition}
                                  </a>
                                </div>
                                <div className="text-secondory">
                                  Status :{" "}
                                  <a className="text-secondory text-green">
                                    {item.status == 0
                                      ? "Available"
                                      : "Not Avilable"}
                                  </a>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell sx={{ px: 0 }} colSpan={2}>
                              <IconButton
                                onClick={() => {
                                  setOnEdit(item.id);
                                  handleBranchOpen(item);
                                }}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>

                              <IconButton
                                onClick={() => {
                                  onDelete("1", item.id);
                                }}
                              >
                                <Icon style={{ color: "red" }}>delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
                  {!TankDetailsList &&
                    <h1 className="no-data">No Data Found</h1>}
                </Box>
              </Card>
            </Grid>

            <Grid item lg={4} md={7} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <div className="row-flex-center">
                    <Title style={{ marginLeft: "10px" }}>
                      {selecteddetails && selecteddetails.serial_no}
                    </Title>
                  </div>
                  <Fab
                    size="small"
                    className="button"
                    style={{ boxShadow: "none", background: "none" }}
                  />
                </CardHeader>
                <Box overflow="auto">
                  <VerticalTimeline
                    layout={"1-column-left"}
                    lineColor={"#fcb038"}
                  >
                    {TrackData.map((item, index) =>
                      <VerticalTimelineElement
                        contentStyle={{
                          background: "rgb(33, 150, 243)",
                          color: "#fff",
                          padding: "2px",
                          paddingLeft: "1rem"
                        }}
                        contentArrowStyle={{
                          borderRight: "7px solid  rgb(33, 150, 243)"
                        }}
                        date={new Date(item.date).toDateString()}
                        iconStyle={{
                          background: "crimson",
                          color: "#fff",
                          width: "30px",
                          height: "30px"
                        }}
                        icon={index + 1}
                        iconClassName={"track-icon"}
                      >
                        <p style={{ margin: "0px" }}>
                          {index == 0
                            ? "Newly Stored in Inventory"
                            : parseInt(item.add_or_taken) == 1
                              ? "Assigned To " + item.client_name
                              : parseInt(item.add_or_taken) == 0
                                ? "Removed"
                                : ""}
                        </p>
                      </VerticalTimelineElement>
                    )}
                  </VerticalTimeline>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </ContentBox>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "300px" }}>
            Tank
          </DialogTitle>
          <DialogContent>
            <div className="mt-20">Category</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Category"
              onChange={e => setCategory(e.target.value)}
              value={category}
            />
            <div className="mt-20">Size Code</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Size Code"
              onChange={e => setSizeCode(e.target.value)}
              value={sizecode}
            />

            <div style={{ marginTop: "20px" }}>
              <a>Internal Size</a>
            </div>
            <div style={{ display: "flex" }}>
              <TextField
                fullWidth
                id="name"
                type="text"
                margin="dense"
                focused={false}
                label="Width"
                onChange={e => setInWidth(e.target.value)}
                value={in_width}
                style={{ marginRight: "10px" }}
              />
              <TextField
                fullWidth
                id="name"
                type="text"
                margin="dense"
                focused={false}
                label="Length"
                onChange={e => setInLength(e.target.value)}
                value={in_length}
                style={{ marginRight: "10px" }}
              />
              <TextField
                fullWidth
                id="name"
                type="text"
                margin="dense"
                focused={false}
                label="Height"
                onChange={e => setInHeight(e.target.value)}
                value={in_height}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <a>EXternal Size</a>
            </div>
            <div style={{ display: "flex" }}>
              <TextField
                fullWidth
                id="name"
                type="text"
                margin="dense"
                focused={false}
                label="Width"
                onChange={e => setExWidth(e.target.value)}
                value={ex_width}
                style={{ marginRight: "10px" }}
              />
              <TextField
                fullWidth
                id="name"
                type="text"
                margin="dense"
                focused={false}
                label="Length"
                onChange={e => setExLength(e.target.value)}
                value={ex_length}
                style={{ marginRight: "10px" }}
              />
              <TextField
                fullWidth
                id="name"
                type="text"
                margin="dense"
                focused={false}
                label="Height"
                onChange={e => setExHeight(e.target.value)}
                value={ex_height}
              />
            </div>

            <div className="mt-20">Water Capacity in Ltr</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Water Capacity in Ltr"
              onChange={e => setWaterCapacity(e.target.value)}
              value={watercapacity}
            />
            <div className="mt-20">Chemical in Kg</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Chemical in Kg"
              onChange={e => setChemical(e.target.value)}
              value={chemical}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => {
                onAddUpdate(0);
              }}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={branchOpen}
          onClose={setBranchOpen}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
            Tank Details
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">Tank Size Code</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Tank Size Code"
              onChange={e => setSizeCode(e.target.value)}
              value={SelectTankDetails && SelectTankDetails.size_code}
              disabled
            />

            <div className="mt-20">Tank Serial No</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Tank Serial No"
              onChange={e => setSerialNo(e.target.value)}
              value={serialno}
            />

            <div className="mt-20">Supplier</div>
            <TextField
              id="supplier"
              name="supplier"
              fullWidth
              select
              margin="dense"
              focused={false}
              SelectProps={{
                native:true
              }}
              value={supplier}
              onChange={handleselectTank}
             >

              {SupplierData &&
                SupplierData.map(item =>
                  <option value={item.id}>
                    {" "}{item.company_name}{" "}
                  </option>
                )}
            </TextField>

            <div className="mt-20">Manufacture</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Manufacture"
              onChange={e => setManufacturer(e.target.value)}
              value={manufacturer}
            />

            <div className="mt-20">Tank Manufacture Year</div>
            <TextField
              fullWidth
              id="name"
              type="number"
              margin="dense"
              focused={false}
              placeholder="Tank Manufacture Year"
              onChange={e => setYear(e.target.value)}
              value={year}
            />
            {OnEdit === 0 && 
              <div>
              <div className="mt-20">Date</div>
              <TextField
                fullWidth
                id="name"
                type="date"
                margin="dense"
                focused={false}
                placeholder="Date"
                onChange={e => setDate(e.target.value)}
                value={date}
              />
              </div>
            }
          
           

            <div className="mt-20">Tank Type</div>
            <TextField
              id="tank-type"
              name="tank-type"
              fullWidth
              select
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={tanktype}
              onChange={handleSelectTank}
            >
              <option value={"New Tank"}> New Tank </option>
              <option value={"Used Tank"}> Used Tank </option>
              <option value={"Refurbished Tank"}> Refurbished Tank </option>
            </TextField>

            <div className="mt-20">Tank Condition</div>
            <TextField
              id="tank-condition"
              name="tank-condition"
              fullWidth
              select
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={tankcondition}
              onChange={handleSelectCondition}
            >
              <option value={"Working"}> Working </option>
              <option value={"Not Working"}> Not Working </option>
              <option value={"Need Maintenance"}> Need Maintenance </option>
              <option value={"Need Refurbish"}> Need Refurbish </option>
            </TextField>

            <div className="mt-20">Tank Cost</div>
            <TextField
              fullWidth
              id="name"
              type="number"
              margin="dense"
              focused={false}
              placeholder="Tank Cost"
              onChange={e => setTankCost(e.target.value)}
              value={tankcost}
            />

            <div className="mt-20">Tank Lifecycle</div>
            <TextField
              id="tank-lifecycle"
              name="tank-lifecycle"
              fullWidth
              select
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={tanklifecycle}
              onChange={handleSelectCycle}
            >
              {lifeCount.map(number =>
                <option value={number}>
                  {" "}{number}{" "}
                </option>
              )}
            </TextField>

            <div className="mt-20">Installment</div>
            <TextField
              fullWidth
              id="name"
              type="number"
              margin="dense"
              focused={false}
              placeholder="Installment"
              disabled
              onChange={e => setInstallment(e.target.value)}
              value={parseInt(tankcost) / parseInt(tanklifecycle)}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleBranchClose}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => {
                onAddUpdate(1);
              }}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Fragment>
    </div>
  );

};

export default Tanks;
