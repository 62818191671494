import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Select,
  MenuItem,
  Avatar,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Fragment } from "react";
import EventCalendar from "./EventCalendar";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const ucdata = [
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "12 July 2023",
    type: 1
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "18 July 2023",
    type: 2
  },
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "21 July 2023",
    type: 3
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "29 July 2023",
    type: 1
  }
];

const getColorCode = id => {
  switch (id) {
    case 1:
      return "#d94a28";
    case 2:
      return "#3e995b";
    case 3:
      return "#5844aa";
    default:
      return "crimson";
  }
};

const getKeyCode = id => {
  switch (id) {
    case 1:
      return "DEMO";
    case 2:
      return "SER";
    case 3:
      return "INS";
    default:
      return "";
  }
};

const DemoCalendar = () => {

  const [activeTab, setActiveTab] = useState("day");
  const [filterTab, setFilterTab] = useState(0);
  const [eventData, setEventData] = useState(ucdata);

  const handleTabClick = tab => {
    setActiveTab(tab);
  };

  const filterTabClick = tab => {
   
    setFilterTab(tab);
    
    if(tab==0){
    
      setEventData(ucdata);
    
    } else {

      const filteredData = ucdata.filter(item => item.type === tab);
      setEventData(filteredData);
    
    }
  };

  return (
    <div>
      <Fragment>
        <ContentBox className="analytics">
          <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <CardHeader>
                  <Title>Activity</Title>

                  <div>
                    <button
                      onClick={() => handleTabClick("day")}
                      className={activeTab === "day" ? "ast-btn" : "st-btn"}
                    >
                      Day
                    </button>
                    <button
                      onClick={() => handleTabClick("week")}
                      className={activeTab === "week" ? "ast-btn" : "st-btn"}
                    >
                      Week
                    </button>
                    <button
                      onClick={() => handleTabClick("month")}
                      className={activeTab === "month" ? "ast-btn" : "st-btn"}
                    >
                      Month
                    </button>
                  </div>
                </CardHeader>
                <CardHeader>
                  <Card style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: "100%"
                      }}
                    >
                    <div
                        className={
                          filterTab == 0
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(0)}
                      >All</div>
                      <div className={
                          filterTab == 1
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(1)}
                      >
                        Demo
                      </div>
                      <div
                        className={
                          filterTab == 2
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(2)}
                      >
                        Service
                      </div>
                      <div
                        className={
                          filterTab == 3
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(3)}
                      >
                        Installation
                      </div>
                    </div>
                  </Card>
                </CardHeader>

                <Box style={{ height: "400px", overflowY: "auto" }}>
                  {eventData.map(item =>
                    <Card elevation={3} sx={{ p: "10px", m: 2 }}>
                      <div
                        className="flex-row"
                        style={{ alignItems: "center" }}
                      >
                        <Fab
                          size="small"
                          color="primary"
                          className="button"
                          style={{
                            background: getColorCode(item.type),
                            borderRadius: "5px",
                            fontSize: "12px"
                          }}
                        >
                          {getKeyCode(item.type)}
                        </Fab>

                        <div
                          className="flex-column"
                          style={{ paddingLeft: "15px", width: "85%" }}
                        >
                          {item.client}
                          <a className="text-secondory">
                            {item.branch}
                          </a>
                          <a className="text-secondory text-orange">
                            {item.date}
                          </a>
                        </div>

                        <Icon sx={{ color: "lightgray" }}>chevron_right</Icon>
                      </div>
                    </Card>
                  )}
                </Box>

                <div>
                  <div className="dd-card" style={{ marginTop: "20px" }}>
                    <Card
                      className="demo-card"
                      style={{ background: "#e5f6ff" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button"
                        style={{
                          background: "#b8e6ff",
                          fontSize: "12px"
                        }}
                      >
                        <Icon sx={{ color: "#2c71ff" }}>class</Icon>
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#2c71ff" }}
                        >
                          {" "}20{" "}
                        </a>
                        <a className="demo-con-text"> Demo </a>
                      </div>
                    </Card>

                    <Card
                      className="demo-card"
                      style={{ background: "#f6f3ff" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button"
                        style={{
                          background: "#cdc6f0",
                          fontSize: "12px"
                        }}
                      >
                        <Icon sx={{ color: "#5844aa" }}>insert_invitation</Icon>
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#5844aa" }}
                        >
                          {" "}30{" "}
                        </a>
                        <a className="demo-con-text"> Installation </a>
                      </div>
                    </Card>

                    <Card
                      className="demo-card"
                      style={{ background: "#e2f6e9" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button"
                        style={{
                          background: "#c3e8d1",
                          fontSize: "12px"
                        }}
                      >
                        <Icon sx={{ color: "#3e995b" }}>settings</Icon>
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#3e995b" }}
                        >
                          {" "}20{" "}
                        </a>
                        <a className="demo-con-text"> Service </a>
                      </div>
                    </Card>

                    <Card
                      className="demo-card"
                      style={{ background: "#fef0e9" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button"
                        style={{
                          background: "#f5d3c3",
                          fontSize: "12px"
                        }}
                      >
                        <Icon sx={{ color: "#d94a28" }}>wallpaper</Icon>
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#d94a28" }}
                        >
                          {" "}30{" "}
                        </a>
                        <a className="demo-con-text"> All </a>
                      </div>
                    </Card>
                  </div>
                </div>
              </Grid>

              <Grid item lg={5} md={5} sm={12} xs={12}>
                <EventCalendar event={ucdata} />
                <Box elevation={3} sx={{ pt: "20px", mb: 3 }}>
                  <CardHeader>
                    <Title>All Upcoming Event</Title>
                  </CardHeader>

                  <Box style={{ height: "250px", overflowY: "auto" }}>
                    {ucdata.map(item =>
                      <Card elevation={3} sx={{ p: "10px", m: 2 }}>
                        <div
                          className="flex-row"
                          style={{ alignItems: "center" }}
                        >
                          <Fab
                            size="small"
                            color="primary"
                            className="button"
                            style={{
                              background: getColorCode(item.type),
                              borderRadius: "5px",
                              fontSize: "12px"
                            }}
                          >
                            {getKeyCode(item.type)}
                          </Fab>

                          <div
                            className="flex-column"
                            style={{ paddingLeft: "15px", width: "75%" }}
                          >
                            {item.client}
                            <a className="text-secondory">
                              {item.branch}
                            </a>
                            <a className="text-secondory text-orange">
                              {item.date}
                            </a>
                          </div>

                          <Icon sx={{ color: "text.primary" }}>
                            chevron_right
                          </Icon>
                        </div>
                      </Card>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </ContentBox>
      </Fragment>
    </div>
  );
};

export default DemoCalendar;
