import React, { useState, useEffect } from "react";
import { styled, darken, alpha, lighten } from "@mui/material/styles";
import {
  IconButton,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  Fab
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LIST_JOB_RECEIPT } from "app/utils/ApiRoutes.js";
import Swal from "sweetalert2";
import axios from "axios";
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));


const JobReceipt = ({}) => {
  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);
  const navigate = useNavigate();

  const [receiptdata, setUpReceiptData] = useState([]);
  const moveAddJobRecepiet = () => {
    navigate("/add-job-receipt", { state: { from: 0, data: {} } });
  };

  const ListJobReceipt = () => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    let config = {
      method: "GET",
      url: LIST_JOB_RECEIPT + locid
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.jobrecipt_data);
        var resdata = response.data.jobrecipt_data;
        if (resdata.length > 0) {
          var jobdata = [];
          for (let i = 0; i < resdata.length; i++) {
            if (resdata[i].status == "1") {
              var data = resdata[i];
              jobdata.push(data);
            }
          }
          setUpReceiptData(jobdata);
        } else {
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    ListJobReceipt();
  }, []);

  const MovePaymentScreen = item => {
    navigate("/payment", { state: { from: 1, data: item } });
  };

  return (
    <Container>
      <Grid container spacing={3} mt={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
            <CardHeader>
              <Title>Job Receipt</Title>
              <Fab
                size="small"
                color="secondary"
                aria-label="Add"
                className="button"
              >
                <Icon
                  onClick={() => {
                    moveAddJobRecepiet();
                  }}
                >
                  add
                </Icon>
              </Fab>
            </CardHeader>
            <Box overflow="auto">
              <ProductTable>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                      NO
                    </TableCell>
                    <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                      Receipt
                    </TableCell>
                    <TableCell sx={{ px: 2 }} colSpan={4}>
                      Client
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Tank
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Date
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Type
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {receiptdata.map((item, index) =>
                    <TableRow hover>
                      <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                        {index + 1}
                      </TableCell>

                      <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                        {item.receipt_no}
                      </TableCell>

                      <TableCell
                        colSpan={4}
                        sx={{ px: 2, textTransform: "capitalize" }}
                      >
                        <div
                          className="flex-column"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.client_name}
                          <a className="text-secondory">
                            ({item.branch_name})
                          </a>
                        </div>
                      </TableCell>

                      <TableCell
                        colSpan={2}
                        sx={{ textTransform: "capitalize" }}
                      >
                        <div className="flex-column">
                          {item.name}
                          <a className="text-secondory">
                            ({item.serial_no})
                          </a>
                        </div>
                      </TableCell>

                      <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                        {item.date}
                      </TableCell>

                      <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                        {item.type}
                      </TableCell>

                      <TableCell sx={{ px: 0 }} colSpan={2}>
                        {/* {false
                          ? <IconButton>
                              <Icon color="primary">edit</Icon>
                            </IconButton>
                          : <IconButton>
                              <Icon
                                onClick={() => MovePaymentScreen(item)}
                                style={{ color: "#34314c" }}
                              >
                                remove_red_eye
                              </Icon>
                            </IconButton>} */}

                        <IconButton>
                          <div
                            onClick={() => MovePaymentScreen(item)}
                            style={{
                              color: "white",
                              background: "#34314c",
                              padding: "5px",
                              borderRadius: "5px",
                              fontSize: "8px"
                            }}
                          >
                            PAY
                          </div>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </ProductTable>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default JobReceipt;
