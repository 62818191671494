import React, { useState, useEffect, useRef } from "react";
import { styled, darken, alpha, lighten } from "@mui/material/styles";
import {
  IconButton,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { Fragment } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  BRANCH_LIST,
  CLIENT_LIST,
  UPDATE_AGREEMENT,
  ADD_AGREEMENT,
  GET_TANK,
  ADD_JOB_RECEIPT,
  LIST_AGREEMENT,
  DELETE_AGREEMENT
} from "app/utils/ApiRoutes.js";
import AddClient from "../Common/AddClient";
import AddBranch from "../Common/AddBranch";
import SignatureCanvas from "react-signature-canvas";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const CardFooder = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "center"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1)
}));

const agrdata = [
  {
    sno: 1,
    client: "LULU MUSCAT HYPERMARKETING",
    branch: "Muscat",
    tank: "Large",
    tanktype: "XL 234",
    date: "20-07-2023"
  },
  {
    sno: 2,
    client: "Al Kawther Bakery",
    branch: "Al Kawther - Liwa",
    tank: "Small",
    tanktype: "EN0936",
    date: "29-07-2023"
  }
];

const Agreements = ({}) => {
  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const handleClose = () => setOpen(false);

  const handleClickOpen = item => {
    if (item) {
      setOnEdit(item.id);
      setDate(item.date)
      setClientID(parseInt(item.client_id));
      ListBranch(parseInt(item.client_id), parseInt(item.branch_id));
      setAgreementType(item.agreement_type);
      setTankType(item.tank_type);
      setTankSize(parseInt(item.tank_size))
      if (item.chemical_size) {
        setChemicalSize(item.chemical_size)
      }else{
        setChemicalSize("100")
      }
      setServiceEvery(parseInt(item.service_every))
      setDeliveryEvery(parseInt(item.delivery_every))
      setFees(item.fees)
      setEveryService(item.fees_type)
      setCreditLimit(parseInt(item.credit_limit))
      setOwnerName(item.owner_name)
      setCliName(item.client_user_name)
   
    }else{

      setOnEdit(0);
      setDate(new Date().toISOString().split("T")[0]);
      setTankType("Our Tank")
      ListClient();
      ListTank();
      setServiceEvery(1);
      setDeliveryEvery(1);
      setEveryService("Monthly");
      setCreditLimit(0);
      setOwnerName("");
      setCliName("");
      setFees("10");
   
    }

    setOpen(true);
    setError("");
  
  };

  const [clientId, setClientID] = useState();
  const [clientName, setClientName] = useState("");
  const [branchId, setBranchID] = useState("");
  const [branchName, setBranchName] = useState("");

  const [callback, setCallBack] = useState("");
  const [clientopen, setClientOpen] = useState();
  const onClientOpen = () => setClientOpen(true);
  const onClientClose = () => setClientOpen(false);

  const [branchopen, setBranchOpen] = useState();
  const onBranchOpen = () => setBranchOpen(true);
  const onBranchClose = () => setBranchOpen(false);

  const [clientdata, setClientData] = useState([]);
  const [branchdata, setBranchData] = useState([]);
  const [tankdata, setTankData] = useState([]);
  const [OnEdit, setOnEdit] = useState(0);

  useEffect(() => {
    AgreementList();
    ListClient();
    ListTank();
  }, []);

  const ListClient = () => {
    let config = {
      method: "GET",
      url: CLIENT_LIST
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          ListBranch(resdata[0].client_id);
          setClientID(resdata[0].client_id);
          setClientName(resdata[0].client_name);
          setClientData(resdata);
        } else {
          setClientData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setClientData([]);
      });
  };

  const ListBranch = (id, BranchId) => {
    let config = {
      method: "GET",
      url: BRANCH_LIST + id
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          if (BranchId) {
            var data = resdata.filter(item => item.branch_id === BranchId);
            if (data.length > 0) {
              setBranchID(data[0].branch_id);
              setBranchName(data[0].branch_name);
            } else {
              setBranchID(resdata[0].branch_id);
              setBranchName(resdata[0].branch_name);
            }
          } else {
            setBranchID(resdata[0].branch_id);
            setBranchName(resdata[0].branch_name);
          }
          setBranchData(resdata);
        } else {
          setBranchData([]);
        }
      })
      .catch(error => {
        setBranchData([]);
      });
  };

  const ListTank = () => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    var data = {
      location_id: locid
    };
    let config = {
      method: "POST",
      url: GET_TANK,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        var resdata = response.data.data;
        if (resdata.length > 0) {
          setTankSize(resdata[0].id);
          setChemicalSize(resdata[0].chemicals);
          setTankData(resdata);
        } else {
          setTankData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setTankData([]);
      });
  };

  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [agreementtype, setAgreementType] = useState("Service Agreement");
  const [tanktype, setTankType] = useState("Our Tank");
  const [tanksize, setTankSize] = useState();
  const [chemicalsize, setChemicalSize] = useState("");
  const [serviceevery, setServiceEvery] = useState(1);
  const [deliveryevery, setDeliveryEvery] = useState(1);
  const [fees, setFees] = useState(10);
  const [everyservice, setEveryService] = useState("Monthly");
  const [creditlimit, setCreditLimit] = useState(0);
  const [ownername, setOwnerName] = useState("");
  const [ownersign, setOwnerSign] = useState("");
  const [cliname, setCliName] = useState("");
  const [clientsign, setClientSign] = useState("");
  const [error, setError] = useState("");
  const ownersignatureRef = useRef(null);
  const clientsignatureRef = useRef(null);

  var clearSignature = ref => {
    if (ref.current) {
      ref.current.clear();
    }
  };

  const [servicecount, setServiceCount] = useState([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30
  ]);

  function dataURLtoBlob(dataURL) {
    const base64Data = dataURL.split(",")[1];
    const byteString = atob(base64Data);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  const onAddUpdate = type => {
    var clientsignature;
    var ownersignature;
    if (ownersignatureRef.current) {
      ownersignature = ownersignatureRef.current.toDataURL();
      console.log(dataURLtoBlob(ownersignature));
    }

    if (clientsignatureRef.current) {
      clientsignature = clientsignatureRef.current.toDataURL();
      console.log(dataURLtoBlob(clientsignature));
    }
    if (
      !clientId ||
      !branchId ||
      !agreementtype ||
      !tanktype ||
      !tanksize ||
      !chemicalsize ||
      !serviceevery ||
      !deliveryevery ||
      !fees ||
      !everyservice ||
      !creditlimit ||
      !cliname ||
      !ownername
    ) {
      setError("Required all fields");
      return;
    } else {
      setError("");
    }

    const data = {
      client_id: clientId,
      branch_id: branchId,
      agreement_type: agreementtype,
      tank_type: tanktype,
      tank_size: tanksize,
      chimical_size: chemicalsize,
      service_every: serviceevery,
      delivery_every: deliveryevery,
      fees: fees,
      fees_type: everyservice,
      credit_limit: creditlimit,
      client_name: cliname,
      owner_name: ownername,
      yearly: "yearly",
      location_id: locationdata.id,
      created_by: userdata.id
    };
     
    if(OnEdit == 0){
      data.date=date;
    }


    setLoading(true);
    var formData = new FormData();
   
    formData.append(
      "user_sign_img",
      dataURLtoBlob(ownersignature),
      "image.png"
    );

    formData.append(
      "client_sign_img",
      dataURLtoBlob(clientsignature),
      "image.png"
    );

    formData.append("data", JSON.stringify(data));

    const API_URL = OnEdit == 0 ? ADD_AGREEMENT : UPDATE_AGREEMENT + OnEdit;
    var config = {
      method: "POST",
      url: API_URL,
      data: formData
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data);
        setLoading(false);
        if (response.data.status == 1) {
          if (OnEdit == 0) {
            AddJobReceipt();
          }
          AgreementList();
          handleClose();
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Added or Updated sucessfully',
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const AddJobReceipt = () => {
    const formData = new FormData();

    var data = {
      employee_id: userdata.id,
      location_id: locationdata.id,
      receipt_no: "",

      type: "Installation",
      date: date,
      removal_date: "",

      client_id: clientId,
      branch_id: branchId,

      tank_type: tanksize,
      tank_serial_no: "",
      temperature: "",
      chemicals: chemicalsize,

      comp_by_name: "",
      comp_by_position: "",
      comp_by_sign: "",

      accepted_by_name: "",
      accepted_by_position: "",
      accepted_by_sign: "",

      remark: "Installation",
      b_integer: "",
      payment: 0,
      status: 0,
      price: fees
    };

    formData.append("data", JSON.stringify(data));

    let config = {
      method: "POST",
      url: ADD_JOB_RECEIPT,
      data: formData
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const [agreementdata, setAgreementData] = useState([]);
  const AgreementList = () => {
    var locID = locationdata && locationdata.id ? locationdata.id : "";
    let config = {
      method: "GET",
      url: LIST_AGREEMENT + locID
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          setAgreementData(resdata);
        } else {
          setAgreementData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setAgreementData([]);
      });
  };

  const AgrementDelete = id => {
    let config = {
      method: "GET",
      url: DELETE_AGREEMENT + id
    };

    axios
      .request(config)
      .then(response => {
        AgreementList();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Deleted sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDelete = id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        AgrementDelete(id);
      }
    });
  };

  return (
    <Container>
      <Grid container spacing={3} mt={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
            <CardHeader>
              <Title>Agreements</Title>
              <Fab
                size="small"
                color="secondary"
                aria-label="Add"
                className="button"
              >
                <Icon
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  add
                </Icon>
              </Fab>
            </CardHeader>
            <Box overflow="auto">
              <ProductTable>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ px: 1 }} align="center" colSpan={1}>
                      S.NO
                    </TableCell>
                    <TableCell sx={{ px: 2 }} colSpan={4}>
                      Client
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Tank
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Date
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {agreementdata.map((item, index) =>
                    <TableRow hover>
                      <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                        {index + 1}
                      </TableCell>

                      <TableCell
                        colSpan={4}
                        sx={{ px: 2, textTransform: "capitalize" }}
                      >
                        <div
                          className="flex-column"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.client_name}
                          <a className="text-secondory">
                            ({item.branch_name})
                          </a>
                        </div>
                      </TableCell>

                      <TableCell
                        colSpan={2}
                        sx={{ textTransform: "capitalize" }}
                      >
                        <div className="flex-column">
                          {item.tank_type}
                          <a className="text-secondory">
                            ({item.tank_size})
                          </a>
                        </div>
                      </TableCell>

                      <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                        {new Date(item.date).toLocaleDateString()}
                      </TableCell>

                      <TableCell sx={{ px: 0 }} colSpan={2}>
                        <IconButton onClick={() => {}}>
                          <Icon
                            color="primary"
                            onClick={() => {
                              handleClickOpen(item);
                            }}
                          >
                            edit
                          </Icon>
                        </IconButton>

                        <IconButton>
                          <Icon
                            style={{ color: "red" }}
                            onClick={() => onDelete(item.id)}
                          >
                            delete
                          </Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </ProductTable>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ width: "500px" }}>
          {type == 0 ? "Add" : "Update"} Agreement
        </DialogTitle>

        <DialogContent>


        {OnEdit == 0 &&
         <div>
          <div className="mt-20">Date</div>
          <TextField
            id="date"
            name="date"
            type="date"
            margin="dense"
            focused={false}
            value={date}
            onChange={e => {
              setDate(e.target.value);
            }}
            style={{ width: "70%" }}
          />
          </div>}
  
          <div className="mt-20">Client Name</div>
          <div style={{ display: "flex" }}>
            <TextField
              id="client-name"
              name="client-name"
              select
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={clientId}
              onChange={e => {
                setClientID(e.target.value);
                var data = clientdata.filter(
                  item => item.client_id === parseInt(e.target.value)
                );
                setClientName(data[0].client_name);
                ListBranch(e.target.value);
              }}
              style={{ width: "70%" }}
            >
              {clientdata.map(item =>
                <option value={item.client_id}>
                  {" "}{item.client_name}{" "}
                </option>
              )}
            </TextField>

            <StyledButton
              variant="outlined"
              color="secondary"
              onClick={() => onClientOpen()}
            >
              Add Client
            </StyledButton>
          </div>

          <div className="mt-20">Branch Name</div>

          <div style={{ display: "flex" }}>
            <TextField
              id="branch-name"
              name="branch-name"
              fullWidth
              select
              margin="dense"
              focused={false}
              SelectProps={{
                native: true
              }}
              value={branchId}
              onChange={e => {
                setBranchID(e.target.value);
                var data = branchdata.filter(
                  item => item.branch_id === parseInt(e.target.value)
                );
                setBranchName(data[0].branch_name);
              }}
              style={{ width: "70%" }}
            >
              {branchdata.map(item =>
                <option value={item.branch_id}>
                  {" "}{item.branch_name}{" "}
                </option>
              )}
            </TextField>
            <StyledButton
              variant="outlined"
              color="secondary"
              onClick={() => onBranchOpen()}
            >
              Add Branch
            </StyledButton>
          </div>

          <div className="mt-20">Agreement Type</div>
          <TextField
            id="agreement-name"
            name="agreement-name"
            select
            fullWidth
            margin="dense"
            focused={false}
            SelectProps={{
              native: true
            }}
            value={agreementtype}
            onChange={e => {
              setAgreementType(e.target.value);
            }}
          >
            <option value={"Service Agreement"}>Service Agreement</option>
            <option value={"Service wit No Maintenance Agreement"}>
              Service wit No Maintenance Agreement
            </option>
            <option value={"Providing Chemical"}>Providing Chemical</option>
            <option value={"Selling Chemical"}>Selling Chemical</option>
          </TextField>

          <div className="mt-20">Tank Type</div>
          <TextField
            id="tank-type"
            name="tank-type"
            select
            fullWidth
            margin="dense"
            focused={false}
            SelectProps={{
              native: true
            }}
            value={tanktype}
            onChange={e => {
              setTankType(e.target.value);
            }}
          >
            <option value={"Our Tank"}>Our Tank</option>
            <option value={"Client Tank"}>Client Tank</option>
          </TextField>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <div className="mt-20">Tank Size</div>
              <TextField
                id="tank-type"
                name="tank-type"
                fullWidth
                select
                margin="dense"
                focused={false}
                SelectProps={{
                  native: true
                }}
                value={tanksize}
                onChange={e => {
                  setTankSize(e.target.value);
                  var data = tankdata.filter(
                    item => item.id === parseInt(e.target.value)
                  );
                  setChemicalSize(data[0].chemicals);
                }}
              >
                {tankdata.map(item =>
                  <option value={item.id}>
                    {item.name}
                  </option>
                )}
              </TextField>
            </div>

            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <div className="mt-20">Chemical Size</div>
              <TextField
                id="tank-type"
                name="tank-type"
                fullWidth
                margin="dense"
                focused={false}
                value={chemicalsize}
                disabled
                onChange={e => setChemicalSize(e.target.value)}
              />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <div className="mt-20">Service Every</div>
              <TextField
                id="tank-type"
                name="tank-type"
                fullWidth
                select
                margin="dense"
                focused={false}
                SelectProps={{
                  native: true
                }}
                value={serviceevery}
                onChange={e => setServiceEvery(e.target.value)}
              >
                {servicecount.map(item =>
                  <option value={item}>
                    {" "}{item} Days{" "}
                  </option>
                )}
              </TextField>
            </div>

            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <div className="mt-20">Delivery Every</div>
              <TextField
                id="tank-type"
                name="tank-type"
                fullWidth
                select
                margin="dense"
                focused={false}
                SelectProps={{
                  native: true
                }}
                value={deliveryevery}
                onChange={e => setDeliveryEvery(e.target.value)}
              >
                {servicecount.map(item =>
                  <option value={item}>
                    {" "}{item} Days{" "}
                  </option>
                )}
              </TextField>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <div className="mt-20">Fees</div>
              <TextField
                id="fees"
                name="fees"
                fullWidth
                margin="dense"
                focused={false}
                type="number"
                value={fees}
                onChange={e => setFees(e.target.value)}
              />
            </div>

            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <div className="mt-20">Every Service</div>
              <TextField
                id="every-service"
                name="every-service"
                fullWidth
                select
                margin="dense"
                focused={false}
                SelectProps={{
                  native: true
                }}
                value={everyservice}
                onChange={e => setEveryService(e.target.value)}
              >
                <option value={"Monthly"}> Monthly </option>
                <option value={"Yearly"}> Yearly </option>
              </TextField>
            </div>
          </div>

          <div className="mt-20">Credit Limit</div>
          <TextField
            id="tank-type"
            name="tank-type"
            fullWidth
            select
            margin="dense"
            focused={false}
            SelectProps={{
              native: true
            }}
            value={creditlimit}
            onChange={e => setCreditLimit(e.target.value)}
          >
            <option value={0}> 0 Day </option>
            <option value={28}> 28 Days </option>
            <option value={30}> 30 Days </option>
            <option value={60}> 60 Days </option>
            <option value={90}> 90 Days </option>
            <option value={180}> 180 Days </option>
          </TextField>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <div className="mt-20">Owner Name</div>
              <TextField
                id="owner-name"
                name="owner-name"
                fullWidth
                margin="dense"
                focused={false}
                value={ownername}
                onChange={e => setOwnerName(e.target.value)}
              />

              <div className="mt-20">Owner Sign Here</div>
              <SignatureCanvas
                ref={ownersignatureRef}
                penColor="blue"
                canvasProps={{
                  height: 150,
                  className: "sign-canvas"
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "5px"
                }}
              >
                <button
                  onClick={() => clearSignature(ownersignatureRef)}
                  className="clr-btn"
                >
                  Clear
                </button>
              </div>
            </div>

            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <div className="mt-20">Client Name</div>
              <TextField
                id="client-name"
                name="client-name"
                fullWidth
                margin="dense"
                focused={false}
                value={cliname}
                onChange={e => setCliName(e.target.value)}
              />
              <div className="mt-20">Client Sign Here</div>
              <SignatureCanvas
                ref={clientsignatureRef}
                penColor="blue"
                canvasProps={{
                  height: 150,
                  className: "sign-canvas"
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "5px"
                }}
              >
                <button
                  onClick={() => clearSignature(clientsignatureRef)}
                  className="clr-btn"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <LoadingButton
            variant="outlined"
            onClick={() => onAddUpdate()}
            color="primary"
            loading={loading}
          >
            Submit
          </LoadingButton>
        </DialogActions>

        <span
          style={{ textAlign: "center", marginBottom: "5px", color: "red" }}
        >
          {error}
        </span>
      </Dialog>

      <AddClient
        open={clientopen}
        handleClose={onClientClose}
        callBack={setCallBack}
        type={0}
        setType={setType}
      />

      <AddBranch
        open={branchopen}
        handleClose={onBranchClose}
        callBack={setCallBack}
        type={0}
        setType={setType}
        cid={clientId}
      />
    </Container>
  );
};

export default Agreements;
