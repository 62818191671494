import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios"
import Swal from "sweetalert2";

import {
  IconButton,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  Fab
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { QUOTATION_LIST,QUOTATION_DELETE } from "app/utils/ApiRoutes.js";
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));



const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));



const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const Quatations = ({ }) => {
  const navigate = useNavigate();
  const [Quatationdata, setUpQuatationdata] = useState([]);
  const handleMoveAddQuatation = () => {
    navigate("/add-quatations")
  };

  useEffect(() => {
    ListQuotation()
  }, [])

const onDeleteQuotes = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be delete!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "green",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!"
  }).then(result => {
    if (result.isConfirmed) {
      let config = {
        method: "Get",
        url: QUOTATION_DELETE+id
      };
    
      axios
        .request(config)
        .then(response => {
          console.log(response)
          ListQuotation()
        }).catch(error => {
          console.log(error);
        });
    }
  });


 
}

const ListQuotation = () => {

    var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
    var user_id = JSON.parse(localStorage.getItem("user_data")).id
    var data = {
      location_id: loc_id,
      user_id: user_id
    }
    let config = {
      method: "POST",
      url: QUOTATION_LIST,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        var resdata = response.data.quotation_data
        setUpQuatationdata(resdata)
      }).catch(error => {
        console.log(error);
      });

  };

  return (
    <Container>
      <Grid container spacing={3} mt={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
            <CardHeader>
              <Title>Quatations</Title>
              <Fab
                size="small"
                color="secondary"
                aria-label="Add"
                className="button"
              >
                <Icon
                  onClick={() => {
                    handleMoveAddQuatation();
                  }}
                >
                  add
                </Icon>
              </Fab>
            </CardHeader>
            <Box overflow="auto" />
            <Box overflow="auto">
              <ProductTable>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                      NO
                    </TableCell>
                    <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                      Sr NO
                    </TableCell>
                    <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                      Tank Name
                    </TableCell>
                    <TableCell sx={{ px: 2 }} colSpan={2}>
                      Client
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Every service Fees
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Yearly
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Discount
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      validity
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Date
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Quatationdata.map((item, index) =>
                    <TableRow hover>
                      <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                        {item.sr_no}
                      </TableCell>
                      <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                        {item.tank_name}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        sx={{ px: 2, textTransform: "capitalize" }}
                      >
                        <div
                          className="flex-column"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.client_name}
                          <a className="text-secondory">
                            ({item.branch_name})
                          </a>
                        </div>
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        sx={{ textTransform: "capitalize" }}
                      >
                        <div className="flex-column">
                          {item.every_service_fees}
                        </div>
                      </TableCell>
                      <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                        {item.yearly_fee}
                      </TableCell>
                      <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                        {item.discount}
                      </TableCell>
                      <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                        {item.validity}
                      </TableCell>
                      <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                        {item.date}
                      </TableCell>

                      <TableCell sx={{ px: 0 }} colSpan={2}>
                        <IconButton
                          onClick={() => {
                            onDeleteQuotes(item.id);
                          }}
                        >
                          <Icon style={{ color: "red" }}>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </ProductTable>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Quatations;
