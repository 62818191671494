import React from "react";
import { styled, darken, alpha, lighten } from "@mui/material/styles";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import classNames from "clsx";
import {
  Scheduler,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentForm,
  EditRecurrenceMenu
} from "@devexpress/dx-react-scheduler-material-ui";
import ColorLens from "@mui/icons-material/ColorLens";
import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const PREFIX = "Demo";
const classes = {
  cell: `${PREFIX}-cell`,
  content: `${PREFIX}-content`,
  text: `${PREFIX}-text`,
  sun: `${PREFIX}-sun`,
  cloud: `${PREFIX}-cloud`,
  rain: `${PREFIX}-rain`,
  sunBack: `${PREFIX}-sunBack`,
  cloudBack: `${PREFIX}-cloudBack`,
  rainBack: `${PREFIX}-rainBack`,
  opacity: `${PREFIX}-opacity`,
  appointment: `${PREFIX}-appointment`,
  apptContent: `${PREFIX}-apptContent`,
  flexibleSpace: `${PREFIX}-flexibleSpace`,
  flexContainer: `${PREFIX}-flexContainer`,
  tooltipContent: `${PREFIX}-tooltipContent`,
  tooltipText: `${PREFIX}-tooltipText`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  circle: `${PREFIX}-circle`,
  textCenter: `${PREFIX}-textCenter`,
  dateAndTitle: `${PREFIX}-dateAndTitle`,
  titleContainer: `${PREFIX}-titleContainer`,
  container: `${PREFIX}-container`
};

const getBorder = theme =>
  `1px solid ${theme.palette.mode === "light"
    ? lighten(alpha(theme.palette.divider, 1), 0.88)
    : darken(alpha(theme.palette.divider, 1), 0.68)}`;

const DayScaleCell = props =>
  <MonthView.DayScaleCell
    {...props}
    style={{ textAlign: "center", fontWeight: "bold" }}
  />;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.cell}`]: {
    color: "#78909C!important",
    position: "relative",
    userSelect: "none",
    verticalAlign: "top",
    padding: 0,
    height: 80,
    borderLeft: getBorder(theme),
    "&:first-of-type": {
      borderLeft: "none"
    },
    "&:last-child": {
      paddingRight: 0
    },
    "tr:last-child &": {
      borderBottom: "none"
    },
    "&:hover": {
      backgroundColor: "white"
    },
    "&:focus": {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      outline: 0
    }
  },
  [`&.${classes.sunBack}`]: {
    backgroundColor: "#FFFDE7"
  },
  [`&.${classes.cloudBack}`]: {
    backgroundColor: "#ECEFF1"
  },
  [`&.${classes.rainBack}`]: {
    backgroundColor: "#E1F5FE"
  },
  [`&.${classes.opacity}`]: {
    opacity: "0.5"
  }
}));

const StyledDivText = styled("div")(() => ({
  [`&.${classes.text}`]: {
    padding: "0.5em",
    textAlign: "center"
  }
}));

const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
  [`&.${classes.flexibleSpace}`]: {
    flex: "none"
  },
  [`& .${classes.flexContainer}`]: {
    display: "flex",
    alignItems: "center"
  }
}));

const StyledAppointmentsAppointment = styled(Appointments.Appointment)(() => ({
  [`&.${classes.appointment}`]: {
    backgroundColor: "transparent",
    "&:hover": {
      opacity: 0.6
    }
  }
}));

const StyledAppointmentsAppointmentContent = styled(
  Appointments.AppointmentContent
)(() => ({
  [`&.${classes.apptContent}`]: {
    "&>div>div": {
      whiteSpace: "normal !important",
      lineHeight: 1.2,
      textAlign: "center"
    }
  }
}));

const appointments = [
  {
    id: 0,
    title: "Test 1",
    startDate: new Date(2018, 6, 23, 9, 30),
    endDate: new Date(2018, 6, 23, 11, 30),
    type: 0
  },
  {
    id: 1,
    title: "Test 2",
    startDate: new Date(2018, 5, 28, 9, 30),
    endDate: new Date(2018, 5, 28, 11, 30),
    type: 1
  },
  {
    id: 2,
    title: "Test 3",
    startDate: new Date(2018, 6, 9, 12, 0),
    endDate: new Date(2018, 6, 9, 13, 0),
    type: 2
  },
  {
    id: 3,
    title: "Test 4",
    startDate: new Date(2018, 6, 18, 14, 30),
    endDate: new Date(2018, 6, 19, 15, 30),
    type: 1
  }
];

const CellBase = React.memo(({ startDate, formatDate, otherMonth }) => {
  const iconId = Math.abs(Math.floor(Math.sin(startDate.getDate()) * 10) % 3);
  const isFirstMonthDay = startDate.getDate() === 1;
  const formatOptions = isFirstMonthDay
    ? { day: "numeric", month: "long" }
    : { day: "numeric" };
  return (
    <StyledTableCell
      tabIndex={0}
      className={classNames({
        [classes.cell]: true,
        [classes.rainBack]: iconId === 0,
        [classes.sunBack]: iconId === 1,
        [classes.cloudBack]: iconId === 2,
        [classes.opacity]: otherMonth
      })}
    >
      <StyledDivText className={classes.text}>
        {formatDate(startDate, formatOptions)}
      </StyledDivText>
    </StyledTableCell>
  );
});

const TimeTableCell = CellBase;

const Appointment = ({ ...restProps }) =>
  <StyledAppointmentsAppointment
    {...restProps}
    className={classes.appointment}
  />;

const getAppointmentColor = id => {
  switch (id) {
    case 0:
      return "orange";
    case 1:
      return "#c60d0d";
    case 2:
      return "#0a0a86";
    default:
      return "crimson";
  }
};

const AppointmentContent = ({ ...restProps }) =>
  <StyledAppointmentsAppointmentContent
    {...restProps}
    className={classes.apptContent}
    style={{
      backgroundColor: getAppointmentColor(restProps.data.type),
      borderRadius: "5px"
    }}
  />;

const FlexibleSpace = ({ ...restProps }) =>
  <StyledToolbarFlexibleSpace {...restProps} className={classes.flexibleSpace}>
    <div className={classes.flexContainer}>
      <ColorLens fontSize="large" htmlColor="#FF7043" />
      <Typography variant="h5" style={{ marginLeft: "10px" }}>
        SERVICE
      </Typography>
    </div>
  </StyledToolbarFlexibleSpace>;

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "center",
  borderBottom: "1px solid gray"
}));

const CardFooder = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "center"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const ECalendar = ({}) => {
  const commitChanges = ({ added, changed, deleted }) => {};

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <Card>
            <Scheduler data={appointments}>
              <EditingState onCommitChanges={commitChanges} />
              <ViewState defaultCurrentDate="2018-07-17" />

              <MonthView
                timeTableCellComponent={TimeTableCell}
                dayScaleCellComponent={DayScaleCell}
              />
              <Appointments
                appointmentComponent={Appointment}
                appointmentContentComponent={AppointmentContent}
              />

              <Toolbar flexibleSpaceComponent={FlexibleSpace} />
              <DateNavigator />
              <EditRecurrenceMenu />
              <AppointmentForm />
            </Scheduler>
          </Card>
        </Grid>

        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card overflow="auto">
            <CardHeader style={{ padding: "0px", flexDirection: "column" }}>
              <div
                className="flex-between"
                style={{ margin: "10px 0px", width: "95%" }}
              >
                <Fab
                  size="small"
                  color="primary"
                  className="button"
                  style={{ background: "#a52a2a" }}
                >
                  All
                </Fab>

                <Fab
                  size="small"
                  color="primary"
                  className="button"
                  style={{ background: "orange" }}
                >
                  SER
                </Fab>

                <Fab
                  size="small"
                  color="primary"
                  className="button"
                  style={{ background: "#c60d0d" }}
                >
                  DEM
                </Fab>

                <Fab
                  size="small"
                  color="primary"
                  className="button"
                  style={{ background: "#0a0a86" }}
                >
                  INS
                </Fab>
              </div>

              <Title style={{ padding: "10px 15px", width: "100%" }}>ALL</Title>
            </CardHeader>

            <ProductTable>
              <TableBody>
                <TableRow
                  hover
                  style={{
                    borderBottom: "1px solid lightgray",
                    borderLeft: "2px solid #c60d0d"
                  }}
                >
                  <TableCell
                    align="left"
                    colSpan={4}
                    sx={{ px: 0, textTransform: "capitalize" }}
                  >
                    <div className="flex-column">
                      Test 1
                      <a className="text-secondory text-orange">Demo</a>
                      <a className="text-secondory">18 Juny 2023</a>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow
                  hover
                  style={{
                    borderBottom: "1px solid lightgray",
                    borderLeft: "2px solid orange"
                  }}
                >
                  <TableCell
                    align="left"
                    colSpan={4}
                    sx={{ px: 0, textTransform: "capitalize" }}
                  >
                    <div className="flex-column">
                      Test 2
                      <a className="text-secondory text-orange">Service</a>
                      <a className="text-secondory">23 Juny 2023</a>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </ProductTable>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ECalendar;
