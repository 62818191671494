import React, { useRef } from "react";

import {
  Box,
  styled,
  Card,
  Grid,
  Table
} from "@mui/material";
import axios from "axios";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SignatureCanvas from "react-signature-canvas";
import { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import { LoadingButton } from "@mui/lab";
import {
  CLIENT_LIST,
  BRANCH_LIST,
  QUOTATION_ADD,
  GET_TANK
} from "app/utils/ApiRoutes.js";
import { useLocation, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "center"
}));

const CardFooder = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "center"
}));

const AddQuatations = () => {
  
  const navigations = useNavigate();
  const signatureRef = useRef(null);
  // const [serilNo, setserilNo] = useState("");
  const [date, setDate] = useState("");
  const [validity, setValidity] = useState("");
  const [client, setClient] = useState("");
  const [branch, setBranch] = useState("");
  const [tanksize, setTanksize] = useState("");
  const [servicefee, setServicefee] = useState("");
  const [yearlyfee, setYearlyfee] = useState("");
  const [discount, setDiscount] = useState("");
  const [overallYear, setOverallYear] = useState("");
  const [acceptedby, setAcceptedby] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [sign, setsign] = useState("");
  const [clientdata, setClientData] = useState([]);
  const [branchdata, setBranchData] = useState([]);
  const [tankdata, setTankData] = useState([]);
  const [tankdtl, setTankDtl] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(() => {
    ListClient()
    TankList()
  }, [])
  
  const captureRef = useRef(null);
  const TankList = (id = 0) => {
    var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: GET_TANK + "/" + loc_id
    };

    axios
      .request(config)
      .then(response => {
        const data = response.data.response.data;
        setTankData(data);
      }).catch(error => {
        console.log(error);
      });
    };

  const ListClient = () => {
    
    let config = {
      method: "GET",
      url: CLIENT_LIST
    };

      axios.request(config).then(response => {
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          ListBranch(resdata[0].client_id);
          setClient(resdata[0].client_id);
          // setClientName(resdata[0].client_name);
          setClientData(resdata);
        } else {
          setClientData([]);
        }
      }).catch(error => {
        console.log(error);
        setClientData([]);
      });
  };

  const ListBranch = id => {
    let config = {
      method: "GET",
      url: BRANCH_LIST + id
    };
    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          setBranchData(resdata);
          setBranch(resdata[0].branch_id);
        } else {
          setBranchData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setBranchData([]);
      });
  };

  const clearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear(); // Clear the signature canvas
    }

  };

  function getSteps() {
    return [
      "Quotation Details",
      "Client Details",
      "Tank Details",
      "Fees Details",
      "Office Use"
    ];
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();



  const handleCapture = () => {
    if (captureRef.current) {
      html2canvas(captureRef.current)
        .then(canvas => {
          const imgData = canvas.toDataURL();
          const link = document.createElement("a");
          link.href = imgData;
          link.download = "screenshot.png";
          link.click();
        })
        .catch(error => {
          console.error("Error capturing image:", error);
        });
    }
  };

  const handleNext = () => {

    setError("")

    if ((!date || !validity) && activeStep == 0) {
      setError("please Enter Required Feilds")
      return
    }

    if ((!client || !branch) && activeStep == 1) {
      setError("please Enter Required Feilds")
      return
    }

    if ((!tanksize) && activeStep == 2) {
      setError("please Enter Required Feilds")
      return
    }

    if ((!servicefee || !yearlyfee || !discount || !overallYear) && activeStep == 3) {
      setError("please Enter Required Feilds")
      return
    }

    if (activeStep == 4) {
      saveSignature()
    }

    if ((!acceptedby || !position || !email) && activeStep == 4) {
      setError("please Enter Required Feilds")
      return
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
  };

  function dataURLtoBlob(dataURL) {
    const base64Data = dataURL.split(",")[1];
    const byteString = atob(base64Data);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  const SaveQuotation = () => {
   var loc_id = JSON.parse(localStorage.getItem("location-data")).id;
   var user_id = JSON.parse(localStorage.getItem("user_data")).id
    const formData = new FormData();
    formData.append("sign_img", dataURLtoBlob(sign), "image.png");
    var data = {
      date: date, 
      validity:validity,
      client_id: client, 
      branch_id:branch, 
      tank_id:tanksize,
      every_service_fees: servicefee,
      yearly_fee:yearlyfee, 
      discount:discount, 
      overallYear,
      name:acceptedby, 
      position:position,
      email:email,
      product:"Fattank",
      location_id:loc_id,
      created_by:user_id,
      electricity: "-",
      water: "-",
      drainage: "-",
    };
    console.log(JSON.stringify(data));
    //inventory_subcat_id: "required",
    formData.append("data", JSON.stringify(data));
    let config = {
      method: "POST",
      url: QUOTATION_ADD,
      data: formData
    };

    setLoading(true);
    axios
      .request(config)
      .then(response => {
        setLoading(false);
        console.log(response.data);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })

        setTimeout(() => {
          navigations("/quatations");
        }, 1000);
      }).catch(error => {
        setLoading(false);
        Swal.fire(error.response.data.message)
        console.log(error);
      });

  }
  const saveSignature = () => {
    if (signatureRef.current) {
      const signatureDataURL = signatureRef.current.toDataURL();
      setsign(signatureDataURL);
    }
  };

  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1);
  const handleReset = () => setActiveStep(0);
  const valdata = [
    "1 day",
    "2 days",
    "3 days",
    "4 days",
    "5 days",
    "6 days",
    "7 days",
    "8 days",
    "9 days",
    "10 days",
    "11 days",
    "12 days",
    "12 days",
    "14 days",
    "15 days",
    "16 days",
    "17 days",
    "18 days",
    "19 days",
    "20 days",
    "21 days",
    "22 days",
    "23 days",
    "24 days",
    "25 days",
    "26 days",
    "27 days",
    "28 days",
    "29 days",
    "30 days"
  ];


  return (
    <Container>
      <Card style={{ background: "#fbfdff" }}>
        <ContentBox className="analytics">
          <CardHeader>
            <Title>Add Quatations</Title>
          </CardHeader>
          <Grid container spacing={3}>
            <Grid item lg={3} md={3} sm={12} xs={12} style={{ display: activeStep === steps.length ? 'none' : 'block' }}>
              <div elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <Box ml={5} mt={4} mb={4}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map(label =>
                      <Step key={label}>
                        <StepLabel>
                          {label}
                        </StepLabel>
                      </Step>
                    )}
                  </Stepper>
                </Box>
              </div>
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <div elevation={3} sx={{ mb: 3 }}>
                {activeStep === 0
                  ? <Container style={{ width: "80%" }}>
                    <Title mb={4} className="text-orange">
                      Quatations Details
                    </Title>
                    {/* <div className="mt-20">Serial Number</div>
                    <TextField
                      fullWidth
                      autoFocus
                      type="number"
                      onChange={(e) => {
                        setserilNo(e.target.value)
                      }}
                      value={serilNo}
                      margin="dense"
                      focused={false}
                    /> */}
                    <div className="mt-20">Date</div>
                    <TextField
                      fullWidth
                      autoFocus
                      type="date"
                      onChange={(e) => {
                        setDate(e.target.value)
                      }}
                      value={date}
                      margin="dense"
                      focused={false}
                    />

                    <div className="mt-20">Validity</div>
                    <select className="select-drop"
                      onChange={(e) => {
                        setValidity(e.target.value)
                      }} value={validity} >
                      <option value=""></option>
                      {valdata.map(item =>
                        <option value={item}>{" "}{item}{" "}</option>
                      )}
                    </select>
                  </Container>
                  : activeStep === 1
                    ? <Container style={{ width: "80%" }}>
                      <Title mb={4} className="text-orange">Client Details</Title>
                      <div className="mt-20">Client Name</div>
                      <select className="select-drop"
                        onChange={(e) => {
                          setClient(e.target.value);
                          ListBranch(e.target.value);
                        }} value={client} >
                        <option value="">Select Client</option>
                        {clientdata.map(item =>
                          <option value={item.client_id}>
                            {" "}{item.client_name}{" "}
                          </option>
                        )}
                      </select>
                      <div className="mt-20">Branch Name</div>

                      <select className="select-drop"
                        onChange={(e) => {
                          setBranch(e.target.value)
                        }}
                        value={branch}
                      >

                        <option value="">Select Client</option>
                        {branchdata.map(item =>
                          <option value={item.branch_id}>
                            {" "}{item.branch_name}{" "}
                          </option>
                        )}
                      </select>
                    </Container>
                    : activeStep === 2
                      ? <Container style={{ width: "80%" }}>
                        <Title mb={4} className="text-orange">
                          Tank Details
                        </Title>
                        <div className="mt-20">Tank Size</div>
                        <select className="select-drop"
                          onChange={(e) => {
                            var tank = e.target.value
                            var filter = tankdata.find(item => item.id == tank)
                            setTanksize(tank)
                            // console.log(filter)
                            setTankDtl(filter)
                          }}
                          value={tanksize}
                        >

                          <option value="">Select Tank</option>
                          {tankdata.map(item =>
                            <option value={item.id}>
                              {" "}{item.name}{" "}
                            </option>
                          )}

                        </select>

                        <div className="mt-20">Dimension</div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          {tankdtl &&
                            <div className="d-flex">

                              <div className="mt-20">
                                {" "} Width: {" "}
                                <a style={{ fontWeight: "bold" }}>{tankdtl.internal_width} mm</a>
                              </div>

                              <div className="mt-20">
                                {" "} Length: {" "}
                                <a style={{ fontWeight: "bold" }}>{tankdtl.internal_length} mm</a>
                              </div>

                              <div className="mt-20">
                                {" "} Height: {" "}
                                <a style={{ fontWeight: "bold" }}>{tankdtl.internal_height} mm</a>
                              </div>
                            </div>
                          }
                        </div>
                      </Container>
                      : activeStep === 3
                        ? <Container style={{ width: "80%" }}>
                          <Title mb={4} className="text-orange">
                            Fees Details
                          </Title>
                          <div className="flex-row">
                            <div style={{ width: "50%" }}>
                              <div className="mt-20">Service Fees</div>

                              <TextField
                                fullWidth
                                autoFocus
                                onChange={(e) => {
                                  var ser = e.target.value
                                  setServicefee(ser)
                                  setYearlyfee(ser * 13)
                                }}
                                value={servicefee}
                                type="number"
                                margin="dense"
                                focused={false}
                              />
                            </div>
                            <div style={{ marginLeft: "10px", width: "50%" }}>
                              <div className="mt-20">Yearly Fees</div>
                              <TextField
                                fullWidth
                                autoFocus
                                onChange={(e) => {
                                  setYearlyfee(e.target.value)
                                }}
                                value={yearlyfee}
                                type="text"
                                margin="dense"
                                focused={false}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="flex-row">
                            <div style={{ width: "50%" }}>
                              <div className="mt-20">Discount (%)</div>
                              <TextField
                                fullWidth
                                autoFocus
                                type="text"
                                onChange={(e) => {
                                  var dis = e.target.value
                                  setDiscount(dis)
                                  var cal = ((yearlyfee * 1 / 100) * 13) * dis;
                                  setOverallYear(cal.toFixed(2))
                                }}
                                value={discount}
                                margin="dense"
                                focused={false}
                              />
                            </div>
                            <div style={{ marginLeft: "10px", width: "50%" }}>
                              <div className="mt-20">Overall Yearly</div>
                              <TextField
                                fullWidth
                                autoFocus
                                value={overallYear}
                                type="text"
                                margin="dense"
                                disabled
                                focused={false}
                              />
                            </div>
                          </div>
                        </Container>
                        : activeStep === 4 ?
                          <Container style={{ width: "80%" }}>
                            <Title mb={4} className="text-orange">
                              Office Use
                            </Title>

                            <div className="mt-20">Accepted By</div>
                            <TextField
                              fullWidth
                              autoFocus
                              type="text"
                              margin="dense"
                              onChange={(e) => {
                                setAcceptedby(e.target.value)
                              }}
                              value={acceptedby}
                              focused={false}
                            />

                            <div className="mt-20">Position</div>
                            <TextField
                              fullWidth
                              autoFocus
                              type="text"
                              onChange={(e) => {
                                setPosition(e.target.value)
                              }}
                              value={position}
                              margin="dense"
                              focused={false}
                            />
                            <div className="mt-20">Email</div>
                            <TextField
                              fullWidth
                              autoFocus
                              onChange={(e) => {
                                setEmail(e.target.value)
                              }}
                              value={email}
                              type="text"
                              margin="dense"
                              focused={false}
                            />

                            <div className="mt-20">Sign Here</div>
                            <SignatureCanvas
                              ref={signatureRef}
                              penColor="blue"
                              canvasProps={{
                                height: 150,
                                className: "sign-canvas"
                              }}
                            />
                            <button
                              onClick={clearSignature}
                              className="clr-btn"
                            >
                              Clear Signature
                            </button>
                          </Container>
                          : null}
              </div>
            </Grid>
          </Grid>
          <span
            style={{
              textAlign: "center",
              marginBottom: "5px",
              color: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {error}
          </span>
          <CardFooder>
            {activeStep === steps.length
              ? <Box>
                <Card
                  className="job-form"
                  ref={captureRef}
                  style={{ background: "#fbfdff", padding: "10px" }}
                >
                  <table className="job">
                    <tr>
                      <th>
                        Quotation Form
                      </th>
                    </tr>
                  </table>
                  <div className="image">
                    <img src={"assets/images/s_logo.png"} width="100px" height="100px" alt="logo" />
                    <div className="con">
                      <h5>Date :{date}</h5>
                      <h5>Validity :{validity}</h5>
                    </div>
                  </div>
                  <div className="to">
                    <h4> TO : {client}</h4>
                    <h4>{branch}</h4>
                  </div>
                  <h4>SUBJECT:Proposal for FAT TANK invoice</h4>
                  <p>Dear Sir,</p>
                  <p>
                    I Would like thank for you for your interest in our outstanding solution
                    FAF TANK SERVICE TECHNOLOGY using the South African world recgonised
                    product CARBSOLVE,Please kind our options for a yearly-service
                    agreement(every 28 days service,13 visits a year)
                  </p>
                  <table className="num">
                    <tr>
                      <th rowSpan="2">NO</th>
                      <th rowSpan="2">Product</th>
                      <th rowSpan="2">Location</th>
                      <th rowSpan="2">Tank Size</th>
                      <th colSpan="3">Dimsntion(mm)</th>
                      <th colSpan="4">Fees in SAR</th>
                    </tr>
                    <tr>
                      <th>W</th>
                      <th>L</th>
                      <th>D</th>
                      <th>Every</th>
                      <th>Yearly</th>
                      <th>Discount</th>
                      <th>Yearly</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>FAT TANK</td>
                      <td>{JSON.parse(localStorage.getItem("location-data")).city}</td>
                      <td>{tankdtl.name}</td>
                      <td>{tankdtl.internal_width}</td>
                      <td>{tankdtl.internal_length}</td>
                      <td>{tankdtl.internal_height}</td>
                      <td>{servicefee}</td>
                      <td>{yearlyfee}</td>
                      <td>{discount}</td>
                      <td>{overallYear}</td>
                    </tr>
                    <tr>
                      <td rowSpan="2" colSpan="2">
                        Setup Requirement
                      </td>
                      <td colSpan="3">Electricity</td>
                      <td colSpan="4">Water</td>
                      <td colSpan="2">Drainage</td>
                    </tr>
                    <tr>
                      <td colSpan="3"> Single Phare - inhalf Socket for each tank</td>
                      <td colSpan="4"> Single Water Supply with hear neer the tank</td>
                      <td colSpan="2">Drainage next to the tank</td>
                    </tr>
                  </table>

                  <div className="clarification">
                    <h2>Clarification & Benifits</h2>
                  </div>
                  <p>
                    1.Attached sample Service Agreement should by signed both - after
                    proposal approved.
                  </p>
                  <p>
                    2.Payment can be every service (every 28 days)or monthly to match the
                    client financial system.
                  </p>
                  <p>
                    3.VAT is waived so far.To be invoice once it will be compu lsary like
                    any other applicable GOV fees.
                  </p>
                  <p>
                    4.NO CAPEX.the chosen tank size will be provided for FREE for the
                    service agreement period and it will remain our property all the time.
                  </p>
                  <p>
                    5.NO HIDDEN COST.fees is inclusive of chemical,spare parts and
                    maintenance if required(unless missuse the tank).
                  </p>
                  <p>
                    6.FULL SERVICE. it is our responsibility to conduct the service every 28
                    days, include remove and draiange or chemical clean and maintain the
                    tank is suitable condition add the new detergent to the water.
                  </p>
                  <p>7.SETUP.the rediness of the tank place client responsibility.</p>
                  <p>
                    8.MAINTENANCE to be seducled during 72 hours when needed .On special
                    occasions,spare parts kits to be rovided to the client management to
                    perform it when required.
                  </p>
                  <p>
                    9.TRAINING.an inducation to the responsible during installation of the
                    tank and can be provided during the service.
                  </p>
                  <p>10.CREDIT LIMIT 28 days.</p>
                  <p>11.Sustainable cleaning and begine Solutions.</p>
                  <p>12.Direct saving Chemical, labour,water and electricity.</p>
                  <p>13.Yearly fixed budget.</p>
                  <p>14.Indirect saving of replacement of equipment,etc.</p>
                  <p>15.Non-hozardous,safe chemical.</p>
                  <p>16.No Purchasing of stock calling process every time.just sign with us.</p>
                  <div style={{ textAlign: "left", fontSize: "25px" }}>
                    <h6>Please do not hesitate to contact me if you require further information</h6>
                    <h6>Regards:</h6>
                    <><img src={sign} width={200} /></>
                    <p>{acceptedby}</p>
                    <p>{position}</p>
                    <p>{email}</p>
                  </div>

                  <div style={{
                    justifyContent: "space-around",
                    display: "flex",
                    alignItems: "flex-end"
                  }}>
                    <Button
                      sx={{ mt: 2 }}
                      variant="outlined"
                      color="secondary"
                      onClick={handleReset}
                    >back</Button>
                  
                <LoadingButton
                   variant="outlined"
                   color="primary"
                   onClick={() => SaveQuotation()}
                    style={{ height: "40px", marginLeft: "10px" }}
                    loading={loading}
                  >
                      Save Quatation
                      </LoadingButton>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      onClick={() => handleCapture()}
                      color="warning">
                      print
                    </Button>

                  </div>
                </Card>
              </Box> : <Box>
                <Box pt={2}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >Back</Button>
                  <Button
                    sx={{ ml: 2 }}
                    variant="outlined"
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </Box>}
          </CardFooder>
        </ContentBox>
      </Card>
    </Container>
  );
};

export default AddQuatations;
