
import Breadcrumb from 'app/components/Breadcrumb';
import SimpleCard from 'app/components/SimpleCard';
import AreaChart from 'app/views/charts/echarts/AreaChart';
import ComparisonChart from 'app/views/charts/echarts/ComparisonChart';
import DoughnutChart from 'app/views/charts/echarts/Doughnut';
import LineChart from 'app/views/charts/echarts/LineChart';
import EventCalendar from "../Calendar/EventCalendar";
import react,{useState,useEffect} from 'react'
import axios from 'axios'
import { LIST_FINANCE_BY_LOC } from "app/utils/ApiRoutes";
import { useNavigate } from "react-router-dom";
import {
  Box,
  useTheme,
  Icon,
  styled,
  Card,
  Grid,
  Fab
} from "@mui/material";

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: {
    margin: '16px'
  },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px'
    }
  }
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center"
  // justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ucdata = [
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "12 July 2023",
    type: 1
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "18 July 2023",
    type: 2
  },
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "21 July 2023",
    type: 3
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "29 July 2023",
    type: 1
  },
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "12 July 2023",
    type: 1
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "18 July 2023",
    type: 2
  },
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "21 July 2023",
    type: 3
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "29 July 2023",
    type: 1
  }
];


const getColorCode = id => {
  switch (id) {
    case 1:
      return "#d94a28";
    case 2:
      return "#3e995b";
    case 3:
      return "#5844aa";
    default:
      return "crimson";
  }
};

const Finance = () => {
  const theme = useTheme();
  const [alleventdata, setAllEventData] = useState([]);
  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Week");
  const [alldata, setAllData] = useState([]);
  const [eventData, setEventData] = useState([]);
     
  const handleTabClick = tab => {
    setActiveTab(tab);
    if (tab == "Week") {
      ListJobReceipt(0);
    }
    if (tab == "Month") {
      ListJobReceipt(1);
    }
    if (tab == "Year") {
      ListJobReceipt(2);
    }
  };

  const dateFormate = value => {
    const date = new Date(value);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate);
    return formattedDate;
  };

  const setUpCalendarData = async JRDATA => {
  
    var eventdata = [];
  
    for (let i = 0; i < JRDATA.length; i++) {
      var data = JRDATA[i];
      data.date = dateFormate(data.date);
      eventdata.push(data);
    }
  
    setAllData(eventdata);
    setEventData(eventdata);
    setAllEventData(eventdata);  
  };

 

  const ListJobReceipt = (id = 0) => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
   
    let config = {
      method: "GET",
      url: LIST_FINANCE_BY_LOC + locid +"/"+id
    };

    axios.request(config)
      .then(response => {
        console.log(response.data.Payment_data);
        var resdata = response.data.Payment_data;
        if(resdata.length > 0) {
          console.log(resdata);
          setUpCalendarData(resdata);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

useEffect(() => {
    ListJobReceipt(0);
},[]);

  return (
    <Container>
      <Box sx={{ py: '12px' }} />      
      <ContentBox className="analytics" style={{ overflow: "hidden" }}>
           <Card
            elevation={3}
            sx={{ pt: "20px", mb: 3 }}
            style={{ maxHeight: "87vh" }}
           >
            <Grid container spacing={3}>  
            
              <Grid item lg={7} md={7} sm={12} xs={12}>             
                <CardHeader>
                  <Title>Finance Module</Title>
                  <div style={{ marginLeft: "20px" }}>
                    <button
                      onClick={() => handleTabClick("Week")}
                      className={activeTab === "Week" ? "ast-btn" : "st-btn"}
                    >
                      Week
                    </button>
                    <button
                      onClick={() => handleTabClick("Month")}
                      className={activeTab === "Month" ? "ast-btn" : "st-btn"}
                    >
                      Month
                    </button>
                    <button
                      onClick={() => handleTabClick("Year")}
                      className={activeTab === "Year" ? "ast-btn" : "st-btn"}
                    >
                      Year
                    </button>
                  </div>
                </CardHeader>
                <Box style={{ height: "50vh", overflowY: "auto" }}>
                  {eventData.map(item =>
                    <Card elevation={3} sx={{ p: "10px", m: 2 }}>
                      <div
                        className="flex-row"
                        style={{ alignItems: "center" }} >
                       
                        <Fab
                          size="small"
                          color="primary"
                          className="button"
                          style={{
                            background: "crimson",
                            borderRadius: "5px",
                            fontSize: "12px"
                          }}
                        >
                          Pay
                        </Fab>
                       
                        <div
                          className="flex-column"
                          style={{ paddingLeft: "15px", width: "74%" }}
                        >
                          {item.payment_collected_by_name}
                          <a className="text-secondory">
                            {item.payment_collected_by_position}
                          </a>
                          <a className="text-secondory text-orange">
                            {item.date}
                          </a>
                        </div>

                        <div>

                        <a style={{color:"#00e5ff"}}>
                        Paid-{item.paid}
                        </a>     
                        <br/>
                        <a style={{color:"#f44336"}}>
                        Pending-{item.pending}
                        </a>
                        </div>

                      </div>
                    </Card>
                )}
                </Box>
              </Grid>

              <Grid
                item
                lg={5}
                md={5}
                sm={12}
                xs={12}
              >
              <div className="container">
                <EventCalendar event={alleventdata} />
              </div>
              </Grid>
          
            </Grid>
          </Card>
        </ContentBox>
      <SimpleCard title="Finance - Current Month vs Previous Month">
      
      <LineChart
          height="350px"
          color={[theme.palette.primary.main, theme.palette.primary.light]}
          id='1'
          data ={[]}
      />
      
    </SimpleCard>
    <Box sx={{ py: '12px' }} />      
      <SimpleCard title="Finance - Current Month">
        <ComparisonChart
          height="350px"
          color={[theme.palette.primary.dark, theme.palette.primary.light]}
        />
      </SimpleCard>
    </Container>
  );
};

export default Finance;
