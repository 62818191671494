import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Select,
  MenuItem,
  TablePagination,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Fragment } from "react";
import {
  ADD_PAYMENT,
  LIST_PAYMENT_BY_JR,
  GET_TANK
} from "app/utils/ApiRoutes.js";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ListDiv = styled("div")(() => ({
  display: "flex",
  alignItems: "start",
  marginTop: "20px"
}));

const ListHead = styled("a")(() => ({
  width: "100px",
  minWidth: "100px",
  fontSize: "12px",
  color: "gray"
}));

const ListTitle = styled("span")(() => ({
  fontSize: "14px",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const Payment = () => {
  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [JRDATA, setJrData] = useState();
  const location = useLocation();

  useEffect(() => {
    if (location && location.state) {
      if (location.state.from == 1) {
        var data = location.state.data;
        console.log(data);
        setJrData(data);
        setAmount(data.price);
      }
    }
  }, []);

  const [amount, setAmount] = useState("0");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [sign, setSign] = useState("");
  const signatureRef = useRef(null);

  var clearSignature = ref => {
    if (ref.current) {
      ref.current.clear();
    }
  };

  const IsNotValidate = () => {
    if (!amount || !name || !email || !position || !signatureRef) {
      return true;
    } else {
      return false;
    }
  };

  const AddPayment = () => {
    if (IsNotValidate()) {
      alert("Required all fields");
      return;
    }

    var locid = locationdata && locationdata.id ? locationdata.id : "";
    var userid = userdata && userdata.id ? userdata.id : "";
    var jr_id;
    var paid = 0;
    var pending = 0;
    var signimg;

    if (signatureRef.current) {
      signimg = signatureRef.current.toDataURL();
      console.log(dataURLtoBlob(signimg));
    }

    if (JRDATA && JRDATA.price) {
      jr_id = JRDATA.id;
      var overallamount = parseInt(JRDATA.price);

      if (paymentData && paymentData.length > 0) {
        var lastdata = paymentData[0];
        overallamount = parseInt(lastdata.pending);
      }
      var addamount = parseInt(amount);
      if (addamount > overallamount) {
        alert("Required amount is greater than your amount..");
        return;
      }

      pending = overallamount - addamount;
      paid = addamount;
    }

    var data = {
      jr_id: jr_id,
      location_id: locid,
      user_id: userid,
      paid: paid,
      pending: pending,
      status: pending == 0 ? 1 : 0,
      date: new Date().toISOString().split("T")[0],
      payment_collected_by_name: name,
      payment_collected_by_email: email,
      payment_collected_by_position: position
    };

    console.log(data);
    setLoading(true);
    var formData = new FormData();
    formData.append(
      "payment_collected_by_image",
      dataURLtoBlob(signimg),
      "image.png"
    );
    formData.append("data", JSON.stringify(data));
    let config = {
      method: "POST",
      url: ADD_PAYMENT,
      data: formData
    };

    axios
      .request(config)
      .then(response => {
        setLoading(false);
        handleClose();
        ListPayment();
        console.log(response.data);
      })
      .catch(error => {
        setLoading(false);
        handleClose();
        console.log(error);
      });
  };

  function dataURLtoBlob(dataURL) {
    const base64Data = dataURL.split(",")[1];
    const byteString = atob(base64Data);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  const [paymentData, setPaymentData] = useState([]);

  const ListPayment = () => {
    var JrId = 0;
    if (location && location.state.data.id) {
      JrId = location.state.data.id;
    }
    let config = {
      method: "GET",
      url: LIST_PAYMENT_BY_JR + JrId
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.Payment_data);
        var resdata = response.data.Payment_data;
        if (resdata && resdata.length > 0) {
          setPaymentData(resdata);
          setAmount(resdata[0].pending);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    ListPayment();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div style={{ minHeight: "90vh", display: "flex", alignItems: "center" }}>
      <Fragment>
        <ContentBox
          className="analytics"
          style={{ minHeight: "80vh", display: "flex", alignItems: "center" }}
        >
          <Card elevation={3} sx={{ pt: "20px", height: "100%" }}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={12} xs={12} style={{height:"80vh"}}>
                <Box elevation={3} sx={{ mb: 3 }}>
                <Title sx={{ ml: 3 }}>Payment Activity</Title>
                  <CardHeader
                    sx={{ pt: "10px" }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start"
                    }}
                  >
                    <ListDiv>
                      <ListHead>JR NO </ListHead>
                      <a style={{ marginRight: "10px" }}>:</a>
                      <ListTitle>
                        {JRDATA && JRDATA.receipt_no}
                      </ListTitle>
                    </ListDiv>

                    <ListDiv>
                      <ListHead>Service Date </ListHead>
                      <a style={{ marginRight: "10px" }}>:</a>
                      <ListTitle>
                        {JRDATA && JRDATA.date}
                      </ListTitle>
                    </ListDiv>

                    <ListDiv>
                      <ListHead>Service Type </ListHead>
                      <a style={{ marginRight: "10px" }}>:</a>
                      <ListTitle>
                        {JRDATA && JRDATA.type}
                      </ListTitle>
                    </ListDiv>

                    <ListDiv>
                      <ListHead>Client Name </ListHead>
                      <a style={{ marginRight: "10px" }}>:</a>
                      <ListTitle>
                        {JRDATA && JRDATA.client_name}
                      </ListTitle>
                    </ListDiv>

                    <ListDiv>
                      <ListHead>Branch Name </ListHead>
                      <a style={{ marginRight: "10px" }}>:</a>
                      <ListTitle>
                        {JRDATA && JRDATA.branch_name}
                      </ListTitle>
                    </ListDiv>

                    <ListDiv>
                      <ListHead>Tank Category </ListHead>
                      <a style={{ marginRight: "10px" }}>:</a>
                      <ListTitle>
                        {JRDATA && JRDATA.name}
                      </ListTitle>
                    </ListDiv>

                    <ListDiv>
                      <ListHead>Tank Serial No </ListHead>
                      <a style={{ marginRight: "10px" }}>:</a>
                      <ListTitle>
                        {JRDATA && JRDATA.serial_no}
                      </ListTitle>
                    </ListDiv>

                    <ListDiv>
                      <ListHead>Chemicals </ListHead>
                      <a style={{ marginRight: "10px" }}>:</a>
                      <ListTitle>
                        {JRDATA && JRDATA.chemicals} Kg
                      </ListTitle>
                    </ListDiv>
                    <ListDiv>
                      <ListHead>Overall Price </ListHead>
                      <a style={{ marginRight: "10px" }}>:</a>
                      <ListTitle>
                        {JRDATA && JRDATA.price}
                      </ListTitle>
                    </ListDiv>
                  </CardHeader>
                </Box>
              </Grid>

              <Grid item lg={8} md={5} sm={12} xs={12}>
                <Card elevation={3} sx={{ mr:"20px", p: "0px", mb: 3, }}>
                  <CardHeader sx={{mt:1}}>
                    <Title>Payment Details</Title>

                     <Fab
                      size="small"
                      color="secondary"
                      aria-label="Add"
                      className="button"
                      onClick={() => {
                        handleClickOpen();
                      }}
                    >
                      <Icon>add</Icon>
                    </Fab>
                  </CardHeader>

                  <Box overflow="auto">
                    <ProductTable>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ px: 3 }} colSpan={3}>
                            Name
                          </TableCell>
                          <TableCell sx={{ px: 3 }} colSpan={2}>
                            Paid
                          </TableCell>
                          <TableCell sx={{ px: 3 }} colSpan={3}>
                            Pending
                          </TableCell>
                          <TableCell sx={{ px: 3 }} colSpan={3}>
                            Date
                          </TableCell>
                          <TableCell sx={{ px: 3 }} colSpan={3}>
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {paymentData &&
                          paymentData
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map(item =>
                              <TableRow hover >
                                <TableCell sx={{ px: 3 }} colSpan={3} style={{padding:"20px 0px"}}>
                                  <a style={{ paddingLeft: "5px" }}>
                                    {item.payment_collected_by_name}
                                  </a>
                                </TableCell>
                                <TableCell sx={{ px: 3 }} colSpan={2}>
                                  {item.paid}
                                </TableCell>
                                <TableCell sx={{ px: 3 }} colSpan={3}>
                                  {item.pending}
                                </TableCell>
                                <TableCell sx={{ px: 3 }} colSpan={3}>
                                  {item.date}
                                </TableCell>
                                <TableCell sx={{ px: 3 }} colSpan={3}>
                                  <a
                                    style={{
                                      color:
                                        parseInt(item.status) == 0
                                          ? "red"
                                          : "green"
                                    }}
                                  >
                                    {parseInt(item.status) == 0
                                      ? "Pending"
                                      : "Completed"}
                                  </a>
                                </TableCell>
                              </TableRow>
                            )}
                      </TableBody>
                    </ProductTable>

                    <TablePagination
                      sx={{ px: 2 }}
                      page={page}
                      component="div"
                      rowsPerPage={rowsPerPage}
                      count={paymentData.length}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={[5]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      nextIconButtonProps={{ "aria-label": "Next Page" }}
                      backIconButtonProps={{ "aria-label": "Previous Page" }}
                    />
                    {!paymentData && <h1 className="no-data">No Data Found</h1>}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </ContentBox>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "300px" }}>
            Payment
          </DialogTitle>
          <DialogContent>
            <div className="mt-20">Amount</div>
            <TextField
              fullWidth
              id="name"
              type="number"
              margin="dense"
              placeholder="Amount"
              onChange={e => setAmount(e.target.value)}
              value={amount}
            />

            <div className="mt-20">Name</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              placeholder="Name"
              onChange={e => setName(e.target.value)}
              value={name}
            />

            <div className="mt-20">Email</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />

            <div className="mt-20">Position</div>
            <TextField
              fullWidth
              id="name"
              type="text"
              margin="dense"
              placeholder="Position"
              onChange={e => setPosition(e.target.value)}
              value={position}
            />

            <div className="mt-20">Signature</div>
            <SignatureCanvas
              ref={signatureRef}
              penColor="blue"
              canvasProps={{
                height: 150,
                className: "sign-canvas"
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "5px"
              }}
            >
              <button
                onClick={() => clearSignature(signatureRef)}
                className="clr-btn"
              >
                Clear
              </button>
            </div>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              color="primary"
              loading={loading}
              onClick={() => AddPayment()}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default Payment;
