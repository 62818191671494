import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Select,
  MenuItem,
  Avatar,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Fragment } from "react";
import countrydata from "../../../assets/country.js";
import { SUPPLIER_ADD } from "app/utils/ApiRoutes.js";
import Swal from "sweetalert2";
import axios from "axios";
import AddClient from "../Common/AddClient.js";
import AddBranch from "../Common/AddBranch.js";
import {
  CATEGORY_LIST,
  CLIENT_LIST,
  CLIENT_DELETE,
  BASE_URL,
  BRANCH_LIST,
  BRANCH_DELETE,
  CONTACT_ADD,
  CONTACT_DELETE,
  CONTACT_UPDATE,
  CONTACT_LIST
} from "app/utils/ApiRoutes.js";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const CRM = () => {
  const { palette } = useTheme();

  const [clientform, setClientForm] = useState();
  const [branchform, setBranchForm] = useState();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [branchOpen, setBranchOpen] = useState(false);
  const handleClickBranchOpen = () => setBranchOpen(true);
  const handleBranchClose = () => setBranchOpen(false);

  const [contactOpen, setContactOpen] = useState(false);
  const handleClickContactOpen = () => setContactOpen(true);
  const handleContactClose = () => setContactOpen(false);

  useEffect(
    () => {
      if (clientform) {
        setClientForm("");
      }
      if (clientform == 1) {
        ListClient();
      }
    },
    [clientform]
  );

  const [clientdata, setClientData] = useState([]);
  const [cudata, setCUData] = useState();
  const [ctype, setCType] = useState("");
  const [selectedclient, setSelectedClient] = useState();

  const ListClient = () => {
    let config = {
      method: "GET",
      url: CLIENT_LIST
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          if (!selectedclient) {
            selectClient(resdata[0]);
          }
          setClientData(resdata);
        } else {
          setClientData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setClientData([]);
      });
  };

  const onClientOpen = (type, data) => {
    if (type == 1) {
      setCUData(data);
    } else {
      setCUData();
    }

    setCType(type);
    handleClickOpen();
  };

  const clientDelete = (id, type) => {
    const API_URL =
      type == 0 ? CLIENT_DELETE : type == 1 ? BRANCH_DELETE : CONTACT_DELETE;
    let config = {
      method: "GET",
      url: API_URL + id
    };

    axios
      .request(config)
      .then(response => {
        if (type == 0) {
          ListClient();
        } else if (type == 1) {
          if (selectedclient && selectedclient.client_id) {
            ListBranch(selectedclient.client_id);
          }
        } else {
          if (selectedBranch && selectedBranch.branch_id) {
            ListContact(selectedBranch.branch_id);
          }
        }

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Deleted sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDelete = (id, type) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        clientDelete(id, type);
      }
    });
  };

  const selectClient = item => {
    setSelectedClient(item);
    ListBranch(item.client_id);
  };

  const [branchdata, setBranchData] = useState([]);
  const [budata, setBUData] = useState();
  const [btype, setBType] = useState("");
  const [selectedBranch, setSelectedBranch] = useState();

  const selectBranch = item => {
    setSelectedBranch(item);
    ListContact(item.branch_id);
  };

  const onBranchOpen = (type, data) => {
    if (type == 1) {
      setBUData(data);
    } else {
      setBUData();
    }

    setBType(type);
    handleClickBranchOpen();
  };

  const ListBranch = id => {
    let config = {
      method: "GET",
      url: BRANCH_LIST + id
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        setContactData([]);
        setSelectContact();
        if (resdata.length > 0) {
          if (!selectedBranch || selectedBranch != resdata[0]) {
            selectBranch(resdata[0]);
          }
          setBranchData(resdata);
        } else {
          setBranchData([]);
          selectBranch();
        }
      })
      .catch(error => {
        console.log(error);
        setBranchData([]);
      });
  };

  useEffect(
    () => {
      if (branchform) {
        setBranchForm("");
      }
      if (branchform == 1 && selectedclient && selectedclient.client_id) {
        ListBranch(selectedclient.client_id);
      }
    },
    [branchform]
  );

  useEffect(() => {
    ListClient();
  }, []);

  // Contact
  const [contact, setContact] = useState("");
  const [position, setPosition] = useState("");
  const [cphone, setCPhone] = useState("");
  const [cemail, setCEmail] = useState("");
  const [contactdata, setContactData] = useState([]);
  const [selectcontact, setSelectContact] = useState();
  const [error, setError] = useState();

  const isNotValidate = () => {
    if (!contact || !position || !cphone || !cemail) {
      return true;
    } else {
      return false;
    }
  };
  const onSubmitContact = async () => {
    if (isNotValidate()) {
      setError("Required all fields");
      return;
    } else {
      setError("");
    }
    var userdata = await localStorage.getItem("user_data");
    userdata = JSON.parse(userdata);
    let data = {
      contact_name: contact,
      designation: position,
      number: cphone,
      email: cemail,
      lead_form: "",
      branch_id: selectedBranch && selectedBranch.branch_id,
      client_id: selectedclient && selectedclient.client_id,
      created_by: userdata.id
    };

    const API_URL = selectcontact
      ? CONTACT_UPDATE + selectcontact.contact_id
      : CONTACT_ADD;
    let config = {
      method: "POST",
      url: API_URL,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data);
        handleContactClose();
        if (selectedBranch && selectedBranch.branch_id) {
          ListContact(selectedBranch.branch_id);
        }
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onContactOpen = data => {
    if (data) {
      setContact(data.contact_name);
      setPosition(data.designation);
      setCPhone(data.number);
      setCEmail(data.email);
      setSelectContact(data);
    } else {
      setContact("");
      setPosition("");
      setCPhone("");
      setCEmail("");
      setSelectContact();
    }

    handleClickContactOpen();
  };

  const ListContact = id => {
    let config = {
      method: "GET",
      url: CONTACT_LIST + id
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          setContactData(resdata);
        } else {
          setContactData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setBranchData([]);
      });
  };

  return (
    <div>
      <Fragment>
        <ContentBox className="analytics">
          <div>
            <Grid
              container
              spacing={3}
              style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto" }}
            >
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                  <CardHeader>
                    <Title>Clients</Title>
                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="Add"
                      className="button"
                    >
                      <Icon onClick={() => onClientOpen(0, "")}>add</Icon>
                    </Fab>
                  </CardHeader>

                  <Box overflow="auto">
                    <ProductTable>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ px: 3 }} colSpan={2}>
                            Image
                          </TableCell>
                          <TableCell sx={{ px: 0 }} colSpan={4}>
                            Clients
                          </TableCell>
                          <TableCell sx={{ px: 0 }} colSpan={2}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {clientdata.length > 0 &&
                          clientdata.map(item =>
                            <TableRow
                              hover
                              style={{
                                background:
                                  item.client_id === selectedclient.client_id
                                    ? "beige"
                                    : ""
                              }}
                              onClick={() => selectClient(item)}
                            >
                              <TableCell
                                align="left"
                                colSpan={2}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                <Box display="flex" alignItems="center">
                                  <img
                                    src={BASE_URL + item.logo}
                                    style={{ width: "50px" }}
                                  />
                                </Box>
                              </TableCell>

                              <TableCell
                                align="left"
                                colSpan={4}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                <div
                                  className="flex-column"
                                  style={{ paddingRight: "20px" }}
                                >
                                  {item.client_name}
                                  <a className="text-secondory text-orange">
                                    {item.client_address}
                                  </a>
                                  <a className="text-secondory">
                                    {item.branches} Branches
                                  </a>
                                </div>
                              </TableCell>

                              <TableCell sx={{ px: 0 }} colSpan={2}>
                                <IconButton
                                  onClick={() => onClientOpen(1, item)}
                                >
                                  <Icon color="primary">edit</Icon>
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    onDelete(item.client_id, 0);
                                  }}
                                >
                                  <Icon style={{ color: "red" }}>delete</Icon>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </ProductTable>
                  </Box>
                </Card>
              </Grid>

              <Grid item lg={5} md={7} sm={12} xs={12}>
                <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                  <CardHeader>
                    <div className="row-flex-center">
                      <Title>
                        {selectedclient && selectedclient.client_name}
                      </Title>
                    </div>

                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="Add"
                      className="button"
                    >
                      <Icon onClick={() => onBranchOpen(0, "")}>add</Icon>
                    </Fab>
                  </CardHeader>

                  <Box overflow="auto">
                    <ProductTable>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ px: 3 }} colSpan={3}>
                            Branch
                          </TableCell>
                          <TableCell sx={{ px: 0 }} colSpan={2}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {branchdata.length > 0 &&
                          branchdata.map(item =>
                            <TableRow hover onClick={() => {
                              selectBranch(item);
                            }}>
                              <TableCell
                                align="left"
                                colSpan={3}
                                sx={{ px: 0, textTransform: "capitalize" }}
                                
                              >
                                <div
                                  className="flex-column"
                                  style={{
                                    paddingRight: "20px",
                                    paddingLeft: "10px"
                                  }}
                                >
                                  {item.branch_name}
                                  <a className="text-secondory text-orange">
                                    {item.branch_address}
                                  </a>
                                  <a className="text-secondory">
                                    {item.branch_number}
                                  </a>
                                </div>
                              </TableCell>

                              <TableCell sx={{ px: 0 }} colSpan={2}>
                                <IconButton>
                                  <Icon
                                    color="primary"
                                    onClick={() => onBranchOpen(1, item)}
                                  >
                                    edit
                                  </Icon>
                                </IconButton>
                                <IconButton>
                                  <Icon
                                    style={{ color: "red" }}
                                    onClick={() => {
                                      onDelete(item.branch_id, 1);
                                    }}
                                  >
                                    delete
                                  </Icon>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </ProductTable>
                    {!branchdata && <h1 className="no-data">No Data Found</h1>}
                  </Box>
                </Card>
              </Grid>

              <Grid item lg={5} md={7} sm={12} xs={12}>
                <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                  <CardHeader>
                    <div className="row-flex-center">
                      <Title style={{ marginLeft: "10px" }}>
                        {selectedBranch && selectedBranch.branch_name}
                      </Title>
                    </div>

                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="Add"
                      className="button"
                    >
                      <Icon onClick={() => onContactOpen()}>add</Icon>
                    </Fab>
                  </CardHeader>

                  <Box overflow="auto">
                    <ProductTable>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ px: 3 }} colSpan={3}>
                            Contact
                          </TableCell>
                          <TableCell sx={{ px: 0 }} colSpan={2}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {contactdata.length > 0 &&
                          contactdata.map(item =>
                            <TableRow hover>
                              <TableCell
                                align="left"
                                colSpan={3}
                                sx={{ px: 0, textTransform: "capitalize" }}
                              >
                                <div
                                  className="flex-column"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  {item.contact_name}
                                  <a className="text-secondory">
                                    ({item.designation})
                                  </a>
                                  <a className="text-secondory text-orange">
                                    {item.number}
                                  </a>
                                </div>
                              </TableCell>

                              <TableCell sx={{ px: 0 }} colSpan={2}>
                                <IconButton>
                                  <Icon
                                    color="primary"
                                    onClick={() => onContactOpen(item)}
                                  >
                                    edit
                                  </Icon>
                                </IconButton>
                                <IconButton>
                                  <Icon
                                    style={{ color: "red" }}
                                    onClick={() => {
                                      onDelete(item.contact_id, 3);
                                    }}
                                  >
                                    delete
                                  </Icon>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </ProductTable>
                    {/* {!branchData && <h1 className="no-data">No Data Found</h1>} */}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </div>
        </ContentBox>

        <AddClient
          open={open}
          handleClose={handleClose}
          callBack={setClientForm}
          type={ctype}
          udata={cudata}
          setType={setCType}
        />
        <AddBranch
          open={branchOpen}
          handleClose={handleBranchClose}
          callBack={setBranchForm}
          type={btype}
          udata={budata}
          setType={setBType}
          cid={selectedclient && selectedclient.client_id}
        />

        <Dialog
          open={contactOpen}
          onClose={handleContactClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
            Contact
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">Name</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              placeholder="Name"
              onChange={e => setContact(e.target.value)}
              value={contact}
              focused={false}
            />
            <div className="mt-20">Designation</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              placeholder="Designation"
              onChange={e => setPosition(e.target.value)}
              value={position}
              focused={false}
            />

            <div className="mt-20">Phone Number</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="number"
              margin="dense"
              placeholder="Phone Number"
              onChange={e => setCPhone(e.target.value)}
              value={cphone}
              focused={false}
            />
            <div className="mt-20">Email</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              placeholder="Email"
              onChange={e => setCEmail(e.target.value)}
              value={cemail}
              focused={false}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleContactClose}
            >
              Cancel
            </Button>

            <Button
              variant="outlined"
              onClick={() => onSubmitContact(0, "")}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>

          <span
            style={{ textAlign: "center", marginBottom: "5px", color: "red" }}
          >
            {error}
          </span>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default CRM;
