import { lazy } from 'react';
import Loadable from 'app/components/Loadable';
import CountryBranch from 'app/screen/CountryBranch/CountryBranch';
import Tanks from 'app/screen/Inventory/Tanks';
import Suppliers from 'app/screen/Suppliers/Suppliers';
import Chemicals from 'app/screen/Chemical/Chemical';
import Spareparts from 'app/screen/Spareparts/Spareparts';
import Units from 'app/screen/Units/Units';
import CRM from 'app/screen/CRM/CRM';
import AddJobReceipt from 'app/screen/JobReceipt/AddJobReceipt';
import ECalendar from 'app/screen/Calendar/ECalendar';
import JobReceipt from 'app/screen/JobReceipt/JobReceipt';
import DemoCalendar from 'app/screen/Calendar/DemoCalendar';
import JRCalendar from 'app/screen/JobReceipt/JRCalendar';
import CRMCalendar from 'app/screen/Marketing/CRMCalendar';
import Agreements from 'app/screen/Marketing/Agreements';
import Feedback from 'app/screen/Marketing/Feedback';
import Quatations from 'app/screen/Marketing/Quatations';
import AddQuatations from 'app/screen/Marketing/AddQuatations';
import Category from 'app/screen/CRM/Category';
import Finance from 'app/screen/Finance/Finance';
import Payment from 'app/screen/JobReceipt/Payment';
import User from 'app/screen/Users/Users';
import Role from 'app/screen/Users/Role';

const AppTable = Loadable(lazy(() => import('./tables/AppTable')));
const AppForm = Loadable(lazy(() => import('./forms/AppForm')));
const AppButton = Loadable(lazy(() => import('./buttons/AppButton')));
const AppIcon = Loadable(lazy(() => import('./icons/AppIcon')));
const AppProgress = Loadable(lazy(() => import('./AppProgress')));
const AppMenu = Loadable(lazy(() => import('./menu/AppMenu')));
const AppCheckbox = Loadable(lazy(() => import('./checkbox/AppCheckbox')));
const AppSwitch = Loadable(lazy(() => import('./switch/AppSwitch')));
const AppRadio = Loadable(lazy(() => import('./radio/AppRadio')));
const AppSlider = Loadable(lazy(() => import('./slider/AppSlider')));
const AppDialog = Loadable(lazy(() => import('./dialog/AppDialog')));
const AppSnackbar = Loadable(lazy(() => import('./snackbar/AppSnackbar')));
const AppAutoComplete = Loadable(lazy(() => import('./auto-complete/AppAutoComplete')));
const AppExpansionPanel = Loadable(lazy(() => import('./expansion-panel/AppExpansionPanel')));

const materialRoutes = [
  { path: '/country-branch', element: <CountryBranch /> },
  { path: '/tank', element: <Tanks /> },
  { path: '/chemical', element: <Chemicals /> },
  { path: '/suppliers', element: <Suppliers /> },
  { path: '/spareparts', element: <Spareparts /> },
  { path: '/units', element: <Units /> },
  { path: '/crm', element: <CRM /> },
  { path: '/role', element: <Role /> },
  { path: '/user', element: <User /> },
  { path: '/category', element: <Category /> },
  { path: '/calendar', element: <ECalendar /> },
  { path: '/demo-calendar', element: <DemoCalendar /> },
  { path: '/jr-calendar', element: <JRCalendar /> },
  { path: '/job-receipt', element: <JobReceipt /> },
  { path: '/payment', element: <Payment /> },
  { path: '/add-job-receipt', element: <AddJobReceipt /> },
  { path: '/crm-calendar', element: <CRMCalendar /> },
  { path: '/agreements', element: <Agreements /> },
  { path: '/feedbacks', element: <Feedback /> },
  { path: '/quatations', element: <Quatations /> },
  { path: '/add-quatations', element: <AddQuatations /> },
  { path: '/finance', element: <Finance /> },
  { path: '/material/table', element: <AppTable /> },
  { path: '/material/form', element: <AppForm /> },
  { path: '/material/buttons', element: <AppButton /> },
  { path: '/material/icons', element: <AppIcon /> },
  { path: '/material/progress', element: <AppProgress /> },
  { path: '/material/menu', element: <AppMenu /> },
  { path: '/material/checkbox', element: <AppCheckbox /> },
  { path: '/material/switch', element: <AppSwitch /> },
  { path: '/material/radio', element: <AppRadio /> },
  { path: '/material/slider', element: <AppSlider /> },
  { path: '/material/autocomplete', element: <AppAutoComplete /> },
  { path: '/material/expansion-panel', element: <AppExpansionPanel /> },
  { path: '/material/dialog', element: <AppDialog /> },
  { path: '/material/snackbar', element: <AppSnackbar /> }
];

export default materialRoutes;
