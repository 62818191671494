import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  styled,
  Card,
  Grid,
  Fab
} from "@mui/material";
import { Fragment } from "react";
import EventCalendar from "../Calendar/EventCalendar";
import { LIST_JOB_RECEIPT } from "app/utils/ApiRoutes";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center"
  // justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ucdata = [
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "12 July 2023",
    type: 1
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "18 July 2023",
    type: 2
  },
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "21 July 2023",
    type: 3
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "29 July 2023",
    type: 1
  },
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "12 July 2023",
    type: 1
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "18 July 2023",
    type: 2
  },
  {
    client: "Lulu Muscat Hypermarkets",
    branch: "Muscat",
    date: "21 July 2023",
    type: 3
  },
  {
    client: "United Catering LLC",
    branch: "Muscat",
    date: "29 July 2023",
    type: 1
  }
];

const getColorCode = id => {
  switch (id) {
    case 1:
      return "#d94a28";
    case 2:
      return "#3e995b";
    case 3:
      return "#5844aa";
    default:
      return "crimson";
  }
};

const getKeyCode = id => {
  switch (id) {
    case 1:
      return "DEMO";
    case 2:
      return "SER";
    case 3:
      return "INS";
    default:
      return "";
  }
};

const getTypeCode = type => {
  var d = type.toLowerCase();
  switch (d) {
    case "demo":
      return 1;
    case "service":
      return 2;
    case "installation":
      return 3;
    default:
      return 0;
  }
};

const JRCalendar = () => {

  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("month");
  const [filterTab, setFilterTab] = useState(0);
  const [alldata, setAllData] = useState([]);
  const [alleventdata, setAllEventData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [count, setCount] = useState({
    count: 0,
    demo: 0,
    service: 0,
    installation: 0
  });

  const handleTabClick = tab => {
    setActiveTab(tab);
    if (tab == "day") {
      getToday();
    }
    if (tab == "week") {
      getNext7Days();
    }
    if (tab == "month") {
      getNext30Days();
    }
  };

  const filterTabClick = tab => {
    setFilterTab(tab);
    if (tab == 0) {
      setEventData(alleventdata);
    } else {
      const filteredData = alleventdata.filter(
        item => getTypeCode(item.type) === tab
      );
      setEventData(filteredData);
    }
  };

  const dateFormate = value => {
    const date = new Date(value);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate);
    return formattedDate;
  };

  const setUpCalendarData = async JRDATA => {
    var eventdata = [];

    for (let i = 0; i < JRDATA.length; i++) {
      // if (parseInt(JRDATA[i].status) == 0) {
        var data = JRDATA[i];
        data.date = dateFormate(data.date);
        eventdata.push(data);
      // }
    }

    setAllData(eventdata);
    setEventData(eventdata);
    setAllEventData(eventdata);
    getCount(eventdata);
    getNext30Days(eventdata);
  };

  const getCount = data => {
    var demo = data.filter(item => getTypeCode(item.type) === 1);
    var service = data.filter(item => getTypeCode(item.type) === 2);
    var installation = data.filter(item => getTypeCode(item.type) === 3);
    setCount({
      count: data.length,
      demo: demo.length,
      service: service.length,
      installation: installation.length
    });
  };

  const getToday = () => {
    var data = [];
    alldata.map(item => {
      if (new Date().toDateString() == new Date(item.date).toDateString()) {
        data.push(item);
      }
    });
    setEventData(data);
    setAllEventData(data);
    getCount(data);
  };

  const getNext7Days = () => {
  
    const dates = [];
    const today = new Date();

    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      dates.push(nextDate.toDateString());
    }

    var data = [];

    alldata.map(item => {
      if (dates.includes(new Date(item.date).toDateString())) {
        data.push(item);
      }
    });

    setEventData(data);
    setAllEventData(data);
    getCount(data);
 
  };

  const getNext30Days = (edata) => {
  
    const dates = [];
    const today = new Date();

    for (let i = 0; i < 30; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      dates.push(nextDate.toDateString());
    }

    var data = [];

    var adata = edata || alldata;

    adata.map(item => {
      if (dates.includes(new Date(item.date).toDateString())) {
        data.push(item);
      }
    });

    setEventData(data);
    setAllEventData(data);
    getCount(data);
  };

  const ListJobReceipt = () => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    let config = {
      method: "GET",
      url: LIST_JOB_RECEIPT + locid
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.jobrecipt_data);
        var resdata = response.data.jobrecipt_data;
        if (resdata.length > 0) {
          console.log(resdata);
          setUpCalendarData(resdata);
        } else {
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    ListJobReceipt();
  }, []);

  const handleUpdateJR = item => {
    if(item.status == 0){
      navigate("/add-job-receipt", { state: { from: 1, data: item } });
    }
  };

  return (
    <div>
      <Fragment>
        <ContentBox className="analytics" style={{ overflow: "hidden" }}>
          <Card
            elevation={3}
            sx={{ pt: "20px", mb: 3 }}
            style={{ maxHeight: "87vh" }}
          >

            <Grid container spacing={3}>
             
              <Grid item lg={7} md={7} sm={12} xs={12}>
              
                <CardHeader>
                  <Title>Activity</Title>

                  <div style={{ marginLeft: "20px" }}>
                    <button
                      onClick={() => handleTabClick("day")}
                      className={activeTab === "day" ? "ast-btn" : "st-btn"}
                    >
                      Day
                    </button>
                    <button
                      onClick={() => handleTabClick("week")}
                      className={activeTab === "week" ? "ast-btn" : "st-btn"}
                    >
                      Week
                    </button>
                    <button
                      onClick={() => handleTabClick("month")}
                      className={activeTab === "month" ? "ast-btn" : "st-btn"}
                    >
                      Month
                    </button>
                  </div>
                </CardHeader>

                <CardHeader>
                  <Card style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: "100%"
                      }}
                    >
                      <div
                        className={
                          filterTab == 0
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(0)}
                      >
                        <a>All</a>
                        <span class="notification-count">
                          {count.count}
                        </span>
                      </div>
                      <div
                        className={
                          filterTab == 1
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(1)}
                      >
                        <a>Demo</a>
                        <span class="notification-count">
                          {count.demo}
                        </span>
                      </div>
                      <div
                        className={
                          filterTab == 2
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(2)}
                      >
                        <a>Service</a>
                        <span class="notification-count">
                          {count.service}
                        </span>
                      </div>
                      <div
                        className={
                          filterTab == 3
                            ? "active-tab-con-child"
                            : "tab-con-child"
                        }
                        onClick={() => filterTabClick(3)}
                      >
                        <a>Installation</a>
                        <span class="notification-count">
                          {count.installation}
                        </span>
                      </div>
                    </div>
                  </Card>
                </CardHeader>

                <Box style={{ height: "70vh", overflowY: "auto" }}>
                  {eventData.map(item =>
                    <Card elevation={3} onClick={() => handleUpdateJR(item)} sx={{ p: "10px", m: 2 }}>
                      <div
                        className="flex-row"
                        style={{ alignItems: "center" }}
                      >
                        <Fab
                          size="small"
                          color="primary"
                          className="button"
                          style={{
                            background:item.status!=0?"red":getColorCode(getTypeCode(item.type)),
                            borderRadius: "5px",
                            fontSize: "12px" }} >
                          {item.status !=0?'COM':getKeyCode(getTypeCode(item.type))}
                        </Fab>
                        <div className="flex-column"
                          style={{ paddingLeft: "15px", width: "85%" }}>
                          {item.client_name}
                          <a className="text-secondory">
                            {item.branch_name}
                          </a>
                          <a className="text-secondory text-orange">
                            {item.date}
                          </a>
                        </div>
                        <Icon sx={{ color: "lightgray" }} >chevron_right</Icon>
                    </div>
                    </Card>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "85vh"
                }}
              >
                <EventCalendar event={alleventdata} />

                <div>
                  <div className="dd-card" style={{ marginTop: "2px" }}>
                    <Card
                      className="demo-card"
                      style={{ background: "#e5f6ff" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button tab-btn"
                        style={{
                          background: "#b8e6ff",
                          fontSize: "12px"
                        }}
                      >
                        <Icon sx={{ color: "#2c71ff", fontSize: "14px" }}>
                          class
                        </Icon>
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#2c71ff" }}
                        >
                          {count.demo}
                        </a>
                        <a className="demo-con-text"> Demo </a>
                      </div>
                    </Card>

                    <Card
                      className="demo-card"
                      style={{ background: "#f6f3ff" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button tab-btn"
                        style={{
                          background: "#cdc6f0",
                          fontSize: "12px"
                        }}
                      >
                        <img
                          src="assets/images/icons/install-icon.png"
                          alt=""
                          width={24}
                          height={24}
                        />
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#5844aa" }}
                        >
                          {count.installation}
                        </a>
                        <a className="demo-con-text"> Installation </a>
                      </div>
                    </Card>

                    <Card
                      className="demo-card"
                      style={{ background: "#e2f6e9" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button tab-btn"
                        style={{
                          background: "#c3e8d1",
                          fontSize: "12px"
                        }}
                      >
                        <Icon sx={{ color: "#3e995b" }}>settings</Icon>
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#3e995b" }}
                        >
                          {count.service}
                        </a>
                        <a className="demo-con-text"> Service </a>
                      </div>
                    </Card>

                    <Card
                      className="demo-card"
                      style={{ background: "#fef0e9" }}
                    >
                      <Fab
                        size="large"
                        color="primary"
                        className="button tab-btn"
                        style={{
                          background: "#f5d3c3",
                          fontSize: "12px"
                        }}
                      >
                        <img
                          src="assets/images/icons/all-icon.png"
                          alt=""
                          width={20}
                          height={20}
                        />
                      </Fab>
                      <div className="demo-con">
                        <a
                          className="demo-count-text"
                          style={{ color: "#d94a28" }}
                        >
                          {count.count}
                        </a>
                        <a className="demo-con-text"> All </a>
                      </div>
                    </Card>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </ContentBox>
        {/* <MatxLoading /> */}
      </Fragment>
    </div>
  );
};

export default JRCalendar;
