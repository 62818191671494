import { Card, Grid, styled, useTheme } from '@mui/material';
import DoughnutChart from 'app/views/charts/echarts/Doughnut';
import Campaigns from 'app/views/dashboard/shared/Campaigns';
import RowCards from 'app/views/dashboard/shared/RowCards';
import StatCards from 'app/views/dashboard/shared/StatCards';
import StatCards2 from 'app/views/dashboard/shared/StatCards2';
import TopSellingTable from 'app/views/dashboard/shared/TopSellingTable';
import UpgradeCard from 'app/views/dashboard/shared/UpgradeCard';
import { Fragment } from 'react';
// import DoughnutChart from 'app/views/dashboard/shared/shared/Doughnut';
// import RowCards from 'app/views/dashboard/shared/shared/RowCards';
// import StatCards from 'app/views/dashboard/shared/shared/StatCards';
// import StatCards2 from 'app/views/dashboard/shared/shared/StatCards2';
// import TopSellingTable from 'app/views/dashboard/shared/shared/TopSellingTable';
// import UpgradeCard from 'app/views/dashboard/shared/shared/UpgradeCard';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
}));

const H4 = styled('h4')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginBottom: '16px',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));

const Dashboard = () => {
  const { palette } = useTheme();

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <StatCards />
            <TopSellingTable />
            <StatCards2 />

            <H4>Feedbacks</H4>
            <RowCards />
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card sx={{ px: 3, py: 2, mb: 3 }}>
              <Title>Finance</Title>
              <SubTitle>Last 10 days</SubTitle>

              <DoughnutChart
                height="300px"
                color={[palette.primary.dark, palette.primary.main, palette.primary.light]}
              />
            </Card>

            {/* <UpgradeCard /> */}
            <Campaigns />
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default Dashboard;
