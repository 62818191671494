import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  useTheme,
  Select,
  MenuItem,
  Avatar,
  Fab
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Paragraph } from "app/components/Typography";
import { Fragment } from "react";
import countrydata from "../../../assets/country.js";
import {
  ADD_COUNTRY,
  LIST_COUNTRY,
  UPDATE_COUNTRY,
  DELETE_COUNTRY,
  ADD_BRANCH,
  LIST_BRANCH,
  UPDATE_BRANCH,
  DELETE_BRANCH,
  BASE_URL
} from "app/utils/ApiRoutes.js";
import MapPicker from "react-google-map-picker";
import Swal from "sweetalert2";
import axios from "axios";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const productList = [
  {
    imgUrl: "/assets/images/products/headphone-2.jpg",
    name: "earphone",
    price: 100,
    available: 15
  },
  {
    imgUrl: "/assets/images/products/headphone-3.jpg",
    name: "earphone",
    price: 1500,
    available: 30
  },
  {
    imgUrl: "/assets/images/products/iphone-2.jpg",
    name: "iPhone x",
    price: 1900,
    available: 35
  },
  {
    imgUrl: "/assets/images/products/iphone-1.jpg",
    name: "iPhone x",
    price: 100,
    available: 0
  },
  {
    imgUrl: "/assets/images/products/headphone-3.jpg",
    name: "Head phone",
    price: 1190,
    available: 5
  }
];

const CountryBranch = () => {
  const { palette } = useTheme();
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [countryID, setCountryID] = useState("");
  const [country, setCountry] = useState("Afghanistan");
  const [c_img, setCImg] = useState("assets/images/cimg/af.png");
  const [currency, setCurrency] = useState("");
  const [omrCurrency, setOmrCurrency] = useState("");
  const [countryerror, setCountryError] = useState("");
  const [countryBranch, setCountryBranch] = useState();

  const handleSelectChange = event => {
    const selectedValue = event.target.value;
    const selectedItem = countrydata.find(item => item.name === selectedValue);
    console.log(selectedItem);
    setCountry(selectedItem.name);
    setCImg(selectedItem.icon);
  };

  const onAddCountry = async () => {
   
    var data = {
      country: country,
      country_flag_image: c_img,
      currency: currency,
      equivalent_value: omrCurrency
    };

    console.log(JSON.stringify(data));

    setLoading(true);
    fetch(ADD_COUNTRY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        if (data.status == 0) {
          alert(country+ " country is alerdy exists");
          return;
        }
        onListCountry();
        
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        setLoading(false);
        console.log(error.message);
      });
  };

  const onUpdateCountry = async () => {
    var data = {
      country: country,
      country_flag_image: c_img,
      currency: currency,
      equivalent_value: omrCurrency
    };

    console.log(JSON.stringify(data));

    setLoading(true);
    fetch(UPDATE_COUNTRY + "/" + countryID, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        console.log(data);
        onListCountry();
        
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        setLoading(false);
        console.log(error.message);
      });
  };

  const onListCountry = async () => {
    fetch(LIST_COUNTRY, {
      method: "POST"
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        handleClose();
        var resdata = data.response.data;
        if (resdata.length > 0) {
          setCountryBranch(resdata[0]);
          onListBranch(resdata[0].id);
          setCountryList(resdata);
       
        } else {
          setCountryBranch();
          setCountryList();
        }
      })
      .catch(error => console.log("error", error));
  };

  const onAddCountryOpen = data => {
    if (data) {
      setCountryID(data.id);
      setCountry(data.country);
      setCImg(data.country_flag_image);
      setCurrency(data.currency);
      setOmrCurrency(data.equivalent_value);
    } else {
      setCountryID("");
      setCountry("Afghanistan");
      setCImg("assets/images/cimg/af.png");
      setCurrency("");
      setOmrCurrency("");
    }
    setCountryError("");
    handleClickOpen();
  };

  const onAddUpdate = () => {

    if (!country || !c_img || !currency || !omrCurrency ) {
      setCountryError("Required all fields");
      return;
    }else{
      setCountryError("");
    }

    if (countryID) {
      onUpdateCountry();
    } else {
      onAddCountry();
    }
  };

  const selectCountry = item => {
    setCountryBranch(item);
    onListBranch(item.id);
  };

  // Branch

  const [branchOpen, setBranchOpen] = useState(false);
  const handleClickBranchOpen = () => setBranchOpen(true);
  const handleBranchClose = () => setBranchOpen(false);

  const [branchUpdateID, setBranchUpdateID] = useState();
  const [area, setArea] = useState();
  const [city, setCity] = useState();
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [latlong, setLatLong] = useState();
  const [location_img, setLocationImg] = useState();
  const [remark, setRemark] = useState();
  const [brancherror, setBranchError] = useState("");
  const [defaultLocation, setDefaultLocation] = useState({ lat: 0, lng: 0 });
  const [branchData, setBranchData] = useState();

  
  const handleLocationChange = (lat, lng) => {
    setLat(lat);
    setLong(lng);
    setLatLong("Lat: " + lat + " Long: " + lng);
  };

  const onAddBranchOpen = data => {
    if (data) {
      setBranchUpdateID(data.id);
      setArea(data.area);
      setCity(data.city);
      setLat(data.lat);
      setLong(data.lang);
      setRemark(data.remarks);
      setLocationImg(data.location_image);
      setLatLong(data.lat + ", " + data.lang);
      setDefaultLocation({ lat: data.lat, lng: data.long });
    } else {
      setBranchUpdateID();
      setArea();
      setCity();
      setLat();
      setLong();
      setLocationImg();
      setRemark();
      setLatLong();
      setDefaultLocation({ lat: 0, lng: 0 });
    }
    setBranchError("");
    handleClickBranchOpen();
  };

  const onAddBranch = () => {

    console.log("area :", area)
    console.log("city :", city)
    console.log("lat :", lat)
    console.log("long :", long)
    console.log("location_img :", location_img)
    console.log("remark :", remark)
    if (!area || !city ||  !location_img || !remark) {
      setBranchError("Required all fields");
      return;
    }else{
      setBranchError("");
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("area", area);
    formData.append("city", city);
    formData.append("country", countryBranch.id);
    formData.append("currency", countryBranch.currency);
    formData.append(
      "currency_equivalent_to_ro",
      countryBranch.equivalent_value
    );
    formData.append("lat", lat);
    formData.append("location_image", location_img);
    formData.append("long", long);
    formData.append("remark", remark);

    const BRANCH_URL = branchUpdateID
      ? UPDATE_BRANCH + branchUpdateID
      : ADD_BRANCH;

    fetch(BRANCH_URL, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        onListBranch(countryBranch.id);
        handleBranchClose();
        setLoading(false);
           
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added sucessfully',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        setLoading(false);
        setBranchError("Request Entity Too Large")
        console.error(error);
      });
  };

  const onListBranch = async id => {
    setBranchData();
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: LIST_BRANCH + id
    };
    axios
      .request(config)
      .then(response => {
        try {
          var data = response.data.response.data;
          console.log(data);
          if (data && data.length > 0) {
            setBranchData(data);
          }
        } catch (error) {
          console.log(error);
        }

        console.log(JSON.stringify(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDelete = (type, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        const DELETE_URL = type == 0 ? DELETE_COUNTRY : DELETE_BRANCH;
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: DELETE_URL + id
        };

        axios
          .request(config)
          .then(response => {
            if (type == 0) {
              onListCountry();
            } else {
              onListBranch(countryBranch.id);
            }

            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Deleted sucessfully',
              showConfirmButton: false,
              timer: 1500
            })
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  };

  useEffect(() => {
    onListCountry();
    LocData();
  }, []);

  const [CLID, setCLID] = useState("");

  const LocData = async () => {
    var locdata = await localStorage.getItem("location-data");
    locdata = JSON.parse(locdata);
    if (locdata) {
      setCLID(locdata.id);
    }
  };

  const ActiveLocation = async data => {
    localStorage.setItem("location-data", JSON.stringify(data));
    LocData();
  };

  return (
    <div>
      <Fragment>
        <ContentBox className="analytics">
          <Grid container spacing={3}>
            <Grid item lg={6} md={5} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <Title>Country</Title>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                  >
                    <Icon onClick={() => onAddCountryOpen()}>add</Icon>
                  </Fab>
                </CardHeader>

                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={2}>
                          Flag
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={3}>
                          Country
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={2}>
                          Currency
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={3}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {countryList &&
                        countryList.map((item, index) =>
                          <TableRow
                            key={index}
                            hover
                            style={{
                              background:
                                item.id === countryBranch.id ? "beige" : ""
                            }}
                            onClick={() => selectCountry(item)}
                          >
                            <TableCell
                              colSpan={2}
                              align="left"
                              sx={{ px: 0, textTransform: "capitalize" }}
                              
                            >
                              <Box display="flex" alignItems="center">
                                <img
                                  src={item.country_flag_image}
                                  style={{ width: "30px" }}
                                />
                              </Box>
                            </TableCell>

                            <TableCell
                              align="left"
                              colSpan={3}
                              sx={{ px: 0, textTransform: "capitalize" }}
                              onClick={() => selectCountry(item)}
                            >
                              {item.country}
                            </TableCell>

                            <TableCell
                              sx={{ px: 0 }}
                              align="left"
                              colSpan={2}
                              onClick={() => selectCountry(item)}
                            >
                              {item.currency}
                            </TableCell>

                            <TableCell sx={{ px: 0 }} colSpan={3}>
                              <IconButton
                                onClick={() => {
                                  onAddCountryOpen(item);
                                }}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  onDelete(0, item.id);
                                }}
                              >
                                <Icon style={{ color: "red" }}>delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
                </Box>
              </Card>
            </Grid>

            <Grid item lg={6} md={7} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
                <CardHeader>
                  <div className="row-flex-center">
                    {countryBranch &&
                      <img
                        src={countryBranch.country_flag_image}
                        style={{ width: "30px" }}
                      />}
                    <Title style={{ marginLeft: "10px" }}>
                      {countryBranch && countryBranch.country}
                    </Title>
                  </div>

                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                  >
                    <Icon onClick={() => onAddBranchOpen()}>add</Icon>
                  </Fab>
                </CardHeader>

                <Box overflow="auto">
                  <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={2}>
                          Image
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={3}>
                          Location
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={3}>
                          Status
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={2}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {branchData &&
                        branchData.map((product, index) =>
                          <TableRow key={index} hover>
                            <TableCell
                              colSpan={2}
                              align="left"
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <Box display="flex" alignItems="center">
                                <img
                                  src={BASE_URL + product.location_image}
                                  style={{ width: "50px" }}
                                />
                              </Box>
                            </TableCell>

                            <TableCell
                              align="left"
                              colSpan={3}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              {product.area + ", " + product.city}
                            </TableCell>

                            <TableCell
                              sx={{ px: 0 }}
                              align="left"
                              colSpan={3}
                              onClick={() => ActiveLocation(product)}
                            >
                              {product.id.toString() == CLID.toString()
                                ? <a className="text-green">Active</a>
                                : <a className="text-secondary">In Active</a>}
                            </TableCell>

                            <TableCell sx={{ px: 0 }} colSpan={2}>
                              <IconButton>
                                <Icon
                                  color="primary"
                                  onClick={() => onAddBranchOpen(product)}
                                >
                                  edit
                                </Icon>
                              </IconButton>
                              <IconButton>
                                <Icon
                                  color="secondary"
                                  onClick={() => onDelete(1, product.id)}
                                >
                                  delete
                                </Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
                  {!branchData && <h1 className="no-data">No Data Found</h1>}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </ContentBox>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
            {countryID ? "Update " : "Add "} Country
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">Select Country</div>
            <select
              className="select-drop"
              value={country}
              onChange={handleSelectChange}
            >
              {countrydata.map((item, index) =>
                <option value={item.name}>
                  {item.name}
                </option>
              )}
            </select>

            <div className="mt-20">Currency</div>
            <TextField
              fullWidth
              
              id="name"
              type="text"
              margin="dense"
              placeholder="Currency"
              onChange={e => setCurrency(e.target.value)}
              value={currency}
              focused={false}
            />

            <div className="mt-20">Currency Value (to OMR)</div>
            <TextField
              fullWidth
              
              id="number"
              type="number"
              margin="dense"
              placeholder="Currency Value (to OMR)"
              onChange={e => setOmrCurrency(e.target.value)}
              value={omrCurrency}
              focused={false}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => onAddUpdate()}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>

          <span style={{ textAlign: "center", marginBottom:"5px", color:"red" }}>
            {countryerror}
          </span>
        </Dialog>

        <Dialog
          open={branchOpen}
          onClose={setBranchOpen}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
            {branchUpdateID ? "Update " : "Add "} Branch
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">Area</div>
            <TextField
              fullWidth
              
              id="name"
              type="text"
              margin="dense"
              placeholder="Area"
              onChange={e => setArea(e.target.value)}
              value={area}
              focused={false}
            />
            <div className="mt-20">City</div>
            <TextField
              fullWidth
              
              id="name"
              type="text"
              margin="dense"
              placeholder="City"
              onChange={e => setCity(e.target.value)}
              value={city}
              focused={false}
            />

            <div className="mt-20">Location</div>
            <TextField
              fullWidth
              
              id="name"
              type="text"
              margin="dense"
              placeholder="Location"
              value={latlong}
              disabled
            />

            <div>
              <MapPicker
                defaultLocation={defaultLocation}
                zoom={1}
                style={{ height: "200px" }}
                onChangeLocation={handleLocationChange}
                apiKey="AIzaSyAYIVMUAZe5dlkDQXZDH-ZEy6jeV1JlKn8"
              />
            </div>

            <div className="mt-20">Remark</div>
            <TextField
              fullWidth
              
              id="remark"
              type="text"
              margin="dense"
              placeholder="Remark"
              onChange={e => setRemark(e.target.value)}
              value={remark}
              focused={false}
            />

            <div className="mt-20">Branch Image</div>
            <TextField
              fullWidth
              
              id="image"
              type="file"
              margin="dense"
              onChange={e => setLocationImg(e.target.files[0])}
              focused={false}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleBranchClose}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => onAddBranch()}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
          <span style={{ textAlign: "center", marginBottom:"5px", color:"red" }}>
            {brancherror}
          </span>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default CountryBranch;
