// const HOST = "http://localhost:2000/api";
const HOST = "http://31.220.54.38/fattank/api";

module.exports = {
  
  // BASE_URL: "http://localhost:2000/",
  BASE_URL: "http://31.220.54.38/fattank/",
  LOGIN: HOST + "/user/login",
  ADD_COUNTRY: HOST + "/Country/add",
  LIST_COUNTRY: HOST + "/Country/list",
  DELETE_COUNTRY: HOST + "/Country/delete/",
  UPDATE_COUNTRY: HOST + "/Country/update",
  ADD_BRANCH: HOST + "/Location/add",
  LIST_BRANCH: HOST + "/Location/list/",
  UPDATE_BRANCH: HOST + "/Location/update/",
  DELETE_BRANCH: HOST + "/Location/delete/",
  SUPPLIER_ADD: HOST + "/suplier/add",
  SUPPLIER_LIST: HOST + "/suplier/list",
  SUPPLIER_DELETE: HOST + "/suplier/delete/",
  SUPPLIER_UPDATE: HOST + "/suplier/update/",
  SUPPLIERDETAIL_ADD: HOST + "/suplierContact/add",
  SUPPLIERDETAIL_LIST: HOST + "/suplierContact/list/",
  SUPPLIERDETAIL_DELETE: HOST + "/suplierContact/delete/",
  SUPPLIERDETAIL_UPDATE: HOST + "/suplierContact/update/",
  CATEGORY_ADD: HOST + "/add_category",
  CATEGORY_LIST: HOST + "/list_category",
  CATEGORY_UPDATE: HOST + "/update_category/",
  CATEGORY_DELETE: HOST + "/delete_category/",
  CLIENT_ADD: HOST + "/client/add",
  CLIENT_LIST: HOST + "/client/list",
  CLIENT_UPDATE: HOST + "/client/update/",
  CLIENT_DELETE: HOST + "/client/delete/",
  BRANCH_ADD: HOST + "/client_branch/add",
  BRANCH_LIST: HOST + "/client_branch/list/",
  BRANCH_UPDATE: HOST + "/client_branch/update/",
  BRANCH_DELETE: HOST + "/client_branch/delete/",
  
  // CONTACT

  CONTACT_ADD: HOST + "/client_contact/add",
  CONTACT_LIST: HOST + "/client_contact/list/",
  CONTACT_UPDATE: HOST + "/client_contact/update/",
  CONTACT_DELETE: HOST + "/client_contact/delete/",

  // Chemical

  CHEMICAL_ADD: HOST + "/Chemical/add",
  CHEMICAL_UPDATE: HOST + "/Chemical/update/",
  CHEMICAL_LIST: HOST + "/Chemical/list",
  CHEMICAL_DELETE: HOST + "/Chemical/delete/",
  GET_CHEMICAL_LIST: HOST + "/Chemical/list/",
  CHEMICAL_TAKEN: HOST + "/Chemical_transaction/add",
  LIST_CHEMICAL_HISTORY: HOST + "/Chemical_transaction/list/",

  // JobReceipt

  GET_JR_NO: HOST + "/jobreceipt/getJobReceipt",
  ADD_JR_PREFIX: HOST + "/jobreceipt/addJobreceiptNo",
  ADD_JOB_RECEIPT: HOST + "/jobreceipt/addJobreceipt",
  UPDATE_JOB_RECEIPT: HOST + "/jobreceipt/UpdateJobReceipt/",
  LIST_JOB_RECEIPT: HOST + "/jobreceipt/ClientJobReceipt/",

  // AGREEMENT

  ADD_AGREEMENT: HOST + "/Agreement/add",
  UPDATE_AGREEMENT: HOST + "/Agreement/update/",
  LIST_AGREEMENT: HOST + "/Agreement/list/",
  DELETE_AGREEMENT: HOST + "/Agreement/delete/",

  // Tankdetails

  GET_TANK: HOST+"/Tank/list",
  ADD_TANK: HOST+"/Tank/add",
  ADD_TANKDETAILS: HOST+"/Tankdetails/add",
  LIST_TANKDETAILS: HOST+"/Tankdetails/list/",
  LIST_TANKTRACT: HOST+"/Tanktrack/list/",
  UPDATE_TANK: HOST+"/Tank/update/",
  TANKDETAIL_DELETE: HOST+"/Tankdetails/delete/",
  TANKDETAIL_UPDATE: HOST+"/Tankdetails/update/",
  TANK_DELETE: HOST+"/Tank/delete/",
  GET_TANK_DETAILS: HOST+"/Tank/detailsByTank",
  ADD_TANK_TRACK: HOST+"/Tanktrack/add",
  LIST_TANK_TRACK: HOST+"/Tanktrack/list/",

  // Feedback
  FEEDBACK_ADD: HOST + "/client_feed/add",
  FEEDBACK_LIST: HOST + "/client_feed/list",
  FEEDBACK_LISTALL: HOST + "/client_feed/listAll",
  FEEDBACK_UPDATE: HOST + "/client_feed/update/",
  FEEDBACK_DELETE: HOST + "/client_feed/delete/",
   
  // UNIT
  UNIT_ADD: HOST + "/spareunit/add",
  UNIT_LIST: HOST + "/spareunit/list",
  UNIT_DELETE: HOST + "/spareunit/delete/",
  UNIT_UPDATE: HOST + "/spareunit/update/",

  // SPAREPARTS
  SPARE_ADD: HOST + "/spare/add",
  SPARE_LIST: HOST + "/spare/list/",
  SPARE_DELETE: HOST + "/spare/delete/",
  SPARE_UPDATE: HOST + "/spare/update/",
  SPARE_ADD_OR_TAKEN: HOST + "/sparetaken/addtakenspare",
  SPARE_HISTORY: HOST + "/sparetaken/sparehistory/",

  // Role
  ROLE_ADD: HOST + "/users/roleadd",
  ROLE_LIST: HOST + "/users/rolelist",
  ROLE_DELETE: HOST + "/users/roledelete/",
  ROLE_UPDATE: HOST + "/users/roleupdate/",

  // USER 
  USER_ADD: HOST + "/users/add",
  USER_UPDATE: HOST + "/users/update/",
  USER_LIST: HOST + "/users/list",
  USER_DELETE: HOST + "/users/delete/",
  
  // Quotation
  QUOTATION_ADD: HOST + "/Quotation/add",
  QUOTATION_DELETE: HOST + "/Quotation/delete/",
  QUOTATION_LIST: HOST+"/Quotation/list",

  // PAYMENTS
  ADD_PAYMENT: HOST + "/Payment/add",
  LIST_PAYMENT_BY_JR: HOST + "/Payment/jrlist/",
  LIST_PAYMENT_BY_LOC: HOST + "/Payment/list/",
  LIST_FINANCE_BY_LOC: HOST + "/PeriodPayment/list/",

};
