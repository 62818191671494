import React, { useState, useEffect } from "react";
import { styled, darken, alpha, lighten } from "@mui/material/styles";
import {
  IconButton,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  Fab
} from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import {
  ROLE_ADD,
  ROLE_LIST,
  ROLE_DELETE,
  ROLE_UPDATE
} from "app/utils/ApiRoutes.js";
import Swal from "sweetalert2";
import axios from "axios";
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));
const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const jrdata = [
  {
    jrno: 1,
    serviceno: 1,
    client: "LULU MUSCAT HYPERMARKETING",
    branch: "Muscat",
    tank: "Large",
    tanktype: "XL 234",
    date: "20-07-2023",
    status: 0
  },
  {
    jrno: 2,
    serviceno: 2,
    client: "Al Kawther Bakery",
    branch: "Al Kawther - Liwa",
    tank: "Small",
    tanktype: "EN0936",
    date: "29-07-2023",
    status: 0
  },
  {
    jrno: 2,
    serviceno: 2,
    client: "Al Kawther Bakery",
    branch: "Al Kawther - Liwa",
    tank: "Small",
    tanktype: "EN0936",
    date: "29-07-2023",
    status: 0
  },
  {
    jrno: 2,
    serviceno: 2,
    client: "Al Kawther Bakery",
    branch: "Al Kawther - Liwa",
    tank: "Small",
    tanktype: "EN0936",
    date: "29-07-2023",
    status: 0
  },
  {
    jrno: 2,
    serviceno: 2,
    client: "Al Kawther Bakery",
    branch: "Al Kawther - Liwa",
    tank: "Small",
    tanktype: "EN0936",
    date: "29-07-2023",
    status: 0
  },
  {
    jrno: 2,
    serviceno: 2,
    client: "Al Kawther Bakery",
    branch: "Al Kawther - Liwa",
    tank: "Small",
    tanktype: "EN0936",
    date: "29-07-2023",
    status: 0
  },
  {
    jrno: 2,
    serviceno: 2,
    client: "Al Kawther Bakery",
    branch: "Al Kawther - Liwa",
    tank: "Small",
    tanktype: "EN0936",
    date: "29-07-2023",
    status: 0
  },
  {
    jrno: 2,
    serviceno: 2,
    client: "Al Kawther Bakery",
    branch: "Al Kawther - Liwa",
    tank: "Small",
    tanktype: "EN0936",
    date: "29-07-2023",
    status: 0
  },
  {
    jrno: 2,
    serviceno: 2,
    client: "Al Kawther Bakery",
    branch: "Al Kawther - Liwa",
    tank: "Small",
    tanktype: "EN0936",
    date: "29-07-2023",
    status: 0
  }
];
const Role = () => {

  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);
  const [open, setOpen] = useState(false);
  const [OnEdit, setOnEdit] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [role, setRole] = useState([]);
  const [roledata, setRoledata] = useState([]);
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    ApiFetch();
  }, []);
  const ApiFetch = () => {
    
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: ROLE_LIST
    };

    axios.request(config)
      .then(response => {
        const data = response.data.data;
        setRoledata(data);
      }).catch(error => {
        console.log(error);
      });
  };
  

  const onDelete =(id)=>{ 

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url:  ROLE_DELETE +id
    };

    axios.request(config)
      .then(response => {
        ApiFetch();
      }).catch(error => {
        console.log(error);
      });
  }

const onAddRoleOpen = data =>{
 
  if(data){
    setRole(data)
  }else{
    setRole("") 
  }  

  handleClickOpen();

}


  const onAddUpdate = () =>{
    
    var data = {
      role: role
    };

    const API_URL = OnEdit == 0 ? ROLE_ADD : ROLE_UPDATE + OnEdit;
   
    var config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: API_URL,
      data: data
    };

    setLoading(true)
    axios.request(config)
      .then(response => {
        setLoading(false)
        ApiFetch();
        handleClose();

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Added or Updated sucessfully',
          showConfirmButton: false,
          timer: 1500
        })

      }).catch(error =>{

        setLoading(false)
        Swal.fire(error.response.data.message)
        handleClose();
      
      }); 

    // alert(role)
    // role
  }

  return (
    <Container>
      <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
            <CardHeader>
              <Title>Role</Title>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    className="button"
                    onClick={() => {
                      setOnEdit(0);
                      onAddRoleOpen()
                    }}
                  >
                    <Icon>add</Icon>
                  </Fab>
            </CardHeader>
            <Box overflow="auto">
            <ProductTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ px: 3 }} colSpan={2}>
                          Role
                        </TableCell>
                        <TableCell sx={{ px: 0 }} colSpan={2}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {roledata &&
                        roledata.map(item =>
                          <TableRow hover>
                            <TableCell
                              align="left"
                              colSpan={2}
                              sx={{ px: 0, textTransform: "capitalize" }}
                            >
                              <div className="flex-column">
                                {item.role}
                              </div>
                            </TableCell>

                            <TableCell sx={{ px: 0 }} colSpan={2}>
                              <IconButton
                                onClick={() => {
                                  setOnEdit(item.id);
                                  onAddRoleOpen(item.role);
                                }}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  onDelete(item.id);
                                }}
                              >
                                <Icon style={{ color: "red" }}>delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </ProductTable>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "300px" }}>
            Role
          </DialogTitle>

          <DialogContent>
            <div className="mt-20">Role Name</div>
            <TextField
              fullWidth
              autoFocus
              id="name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Role"
              onChange={e => setRole(e.target.value)}
              value={role}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => onAddUpdate()}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
    </Container>
  );
}

export default Role