import React, { useState, useEffect } from "react";
import { styled, darken, alpha, lighten } from "@mui/material/styles";
import {
  IconButton,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  Fab
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {  
  USER_ADD,
  USER_UPDATE,
  USER_LIST,
  USER_DELETE,
  ROLE_LIST,
  LIST_BRANCH,
  LIST_COUNTRY
} from "app/utils/ApiRoutes.js";
import Swal from "sweetalert2";
import axios from "axios";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));


const Users = () => {
  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [OnEdit, setOnEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Userdata, setUserdata] = useState([]);
  const [name,setName]=useState()
  const [role,setRole]=useState()
  const [country_id,setCountry_id]=useState()
  const [location_id,setLocation_id]=useState()
  const [phone,setPhone]=useState()
  const [email,setEmail]=useState()
  const [password,setPassword]=useState()
  const [Countrylist,setCountrylist]=useState()
  const [Locationlist,setLocationlist]=useState()
  const [Rolelist,setRolelist]=useState()

  useEffect(() => {
    ApiFetch();
  }, []);

  const ApiFetch = ()=>{
    let userlist = {
      method: "GET",
      url:  USER_LIST
    };

    let rolelist = {
      method: "GET",
      url:  ROLE_LIST
    };

    axios
      .request(userlist)
      .then(response => {
        // setUserdata(response.response.data)
        setUserdata(response.data.response.data)
        // alert("response")
      })
      .catch(error => {
        console.log(error);
      });
      axios
      .request(rolelist)
      .then(response => {
        setRolelist(response.data.data)
      })
      .catch(error => {
        console.log(error);
      });
      onListCountry()
  }

  const onListCountry = async () => {
    fetch(LIST_COUNTRY, {
      method: "POST"
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        handleClose();
        var resdata = data.response.data;
        if (resdata.length > 0) {
          onListBranch(resdata[0].id);
          setCountrylist(resdata);
          setCountry_id(resdata[0].id)
       
        } else {
          setLocationlist([]);
          setCountrylist([]);
        }
      })
      .catch(error => console.log("error", error));
  }

  const onListBranch = async id => {
    setLocationlist([]);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: LIST_BRANCH + id
    };
    axios
      .request(config)
      .then(response => {
        try {
          var data = response.data.response.data;
          console.log(data);
          if (data && data.length > 0) {
            setLocationlist(data);
            setLocation_id(data[0].id)
          }
        } catch (error) {
          console.log(error);
        }

        console.log(JSON.stringify(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onAddRoleOpen = data =>{
 
    if(data){
     setName(data)
    }else{
     setName("") 
    }  
  
    handleClickOpen();
  
  }
  
  const onDeleteUser = id=>{
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: USER_DELETE + id
    };
    axios
      .request(config)
      .then(response => {
        setLoading(false)
        ApiFetch();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.data.statusText,
          showConfirmButton: false,
          timer: 1500
        })
      }).catch(error =>{
        setLoading(false)
        Swal.fire(error.response.data.message)
        handleClose();
      
      }); 
  }
  
  const onAddUpdate = () =>{
      var data = {
        name: name,
        role:role,
        country_id:country_id,
        location_id:location_id,
        phone:phone,
        email:email,
        password:password,
        created_by:1
      };

    
  
      const API_URL = OnEdit == 0 ? USER_ADD : USER_UPDATE + OnEdit;
     
      var config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: API_URL,
        data: data
      };
  
      setLoading(true)
      axios.request(config)
        .then(response => {
          setLoading(false)
          ApiFetch();
          handleClose();
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Added or Updated sucessfully',
            showConfirmButton: false,
            timer: 1500
          })
        }).catch(error =>{
          setLoading(false)
          Swal.fire(error.response.data.message)
          handleClose();
        
        }); 
    }

  return (
    <Container>
      <Grid container spacing={3} mt={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card elevation={3} sx={{ pt: "20px", mb: 3 }}>
            <CardHeader>
              <Title>Users</Title>
              <Fab
                size="small"
                color="secondary"
                aria-label="Add"
                className="button"
              >
                <Icon
                  onClick={() => {
                    setOnEdit(0);
                    onAddRoleOpen()
                  }}
                >
                  add
                </Icon>
              </Fab>
            </CardHeader>
            <Box overflow="auto">
              <ProductTable>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ px: 0 }} align="center" colSpan={1}>
                      NO
                    </TableCell>
                    <TableCell sx={{ px: 0 }} align="center" colSpan={2}>
                      Name
                    </TableCell>
                    <TableCell sx={{ px: 2 }} colSpan={2}>
                      Role
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Country
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      area
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      phone
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      email
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

         
                  {Userdata && Userdata.map((item, index) =>
                    <TableRow hover>


<TableCell sx={{ px: 0 }} align="center" colSpan={1}>
{index + 1}
                    </TableCell>
                    <TableCell sx={{ px: 0 }} align="center" colSpan={2}>
                    {item.name}
                    </TableCell>
                    <TableCell sx={{ px: 2 }} colSpan={2}>
                    {item.role}
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                    {item.country}
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                    {item.city}
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                    {item.phone}
                    </TableCell>
                    <TableCell sx={{ px: 0 }} colSpan={2}>
                    {item.email}
                    </TableCell>

                      <TableCell sx={{ px: 0 }} colSpan={2}>
                      
                        <IconButton
                          onClick={() => {
                              onDeleteUser(item.id);
                          }}
                        >

{/* { item.status} */}
                          {item.status == 0?<VisibilityOffIcon/>:<VisibilityIcon />}
                        
                       </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </ProductTable>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ width: "450px" }}>
            Users
          </DialogTitle>



  
          <DialogContent>

            <div className="mt-20">Name</div>
            <TextField
              fullWidth
              autoFocus
              id="Name"
              type="text"
              margin="dense"
              focused={false}
              placeholder="Name"
              onChange={e => setName(e.target.value)}
              value={name}
            />
          
        
         

            <div className="mt-20">Role</div>
            <select
              className="select-drop"
              value={role}
              onChange={e => setRole(e.target.value)}
            >
             <option value="">
                Select Role
                </option>
              {Rolelist && Rolelist.map((item, index) =>
                <option value={item.id}>
                  {item.role}
                </option>
              )}
            </select>
          
            <div className="mt-20">Country</div>
            <select
              className="select-drop"
              value={country_id}
              onChange={e =>{
                onListBranch(e.target.value)
                setCountry_id(e.target.value)
              } }
            >
              {Countrylist && Countrylist.map((item, index) =>
                <option value={item.id}>
                  {item.country}
                </option>
              )}
            </select>
          
            

            <div className="mt-20">City</div>
            <select
              className="select-drop"
              value={location_id}
              onChange={(e)=>{
                setLocation_id(e.target.value)
              }}
            >
              {Locationlist && Locationlist.map((item, index) =>
                <option value={item.id}>
                  {item.city}
                </option>
              )}
            </select>
          
         

            <div className="mt-20">Email</div>
            <TextField
              fullWidth
              autoFocus
              id="email"
              type="text"
              margin="dense"
              focused={false}
              placeholder="email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          
          <div className="mt-20">Phone</div>
            <TextField
              fullWidth
              autoFocus
              id="number"
              type="number"
              margin="dense"
              focused={false}
              placeholder="Number"
              onChange={e => setPhone(e.target.value)}
              value={phone}
            />
          

            <div className="mt-20">Password</div>
            <TextField
              fullWidth
              autoFocus
              id="password"
              type="password"
              margin="dense"
              focused={false}
              placeholder="password"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
            </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <LoadingButton
              variant="outlined"
              onClick={() => onAddUpdate()}
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Users