import { useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';

const LineChart = ({ height, color = [] },{id=0,data}) => {
  const theme = useTheme();
   var option = {

   }

  if(id == 0){
  option = {
    grid: { top: '10%', bottom: '10%', left: '5%', right: '5%' },
    legend: {
      itemGap: 20,
      icon: 'circle',
      textStyle: {
        fontSize: 13,
        color: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily
    }
    },
    label: {
      fontSize: 13,
      color: theme.palette.text.secondary,
      fontFamily: theme.typography.fontFamily
    },
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        fontSize: 14,
        fontFamily: 'roboto',
        color: theme.palette.text.secondary
      }
    },
    yAxis: {
      type: 'value',
      axisLine: { show: false },
      axisTick: { show: false },
      splitLine: {
        lineStyle: { color: theme.palette.text.secondary, opacity: 0.15 }
      },
      axisLabel: { color: theme.palette.text.secondary, fontSize: 13, fontFamily: 'roboto' }
    },
    series: [
      {
        data: [30, 40, 20, 50, 40, 80, 90],
        type: 'line',
        stack: 'This month',
        name: 'This month',
        smooth: true,
        symbolSize: 4,
        lineStyle: { width: 4 }
      },
      {
        data: [20, 50, 15, 50, 30, 70, 95],
        type: 'line',
        stack: 'Last month',
        name: 'Last month',
        smooth: true,
        symbolSize: 4,
        lineStyle: { width: 4 }
      }
    ]
  };

}else{

  option = {
      grid: { top: '10%', bottom: '10%', left: '5%', right: '5%' },
      legend: {
        itemGap: 20,
        icon: 'circle',
        textStyle: {
          fontSize: 13,
          color: theme.palette.text.secondary,
          fontFamily: theme.typography.fontFamily
        }
      },
      label: {
        fontSize: 13,
        color: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily
      },
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          fontSize: 14,
          fontFamily: 'roboto',
          color: theme.palette.text.secondary
        }
      },
      yAxis: {
        type: 'value',
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: {
          lineStyle: { color: theme.palette.text.secondary, opacity: 0.15 }
        },
        axisLabel: { color: theme.palette.text.secondary, fontSize: 13, fontFamily: 'roboto' }
      },
      series:[
        {
          data: [30, 40, 20, 50, 40, 80, 90],
          type: 'line',
          stack: 'This month',
          name: 'This month',
          smooth: true,
          symbolSize: 4,
          lineStyle: { width: 4 }
        },
        {
          data: [20, 50, 15, 50, 30, 70, 95],
          type: 'line',
          stack: 'Last month',
          name: 'Last month',
          smooth: true,
          symbolSize: 4,
          lineStyle: { width: 4 }
        }
      ]
    };
  }

  return <ReactEcharts style={{ height: height }} option={{ ...option, color: [...color] }} />;
};

export default LineChart;
