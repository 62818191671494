import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MapPicker from "react-google-map-picker";

import { Fragment } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { BRANCH_ADD, BRANCH_UPDATE } from "app/utils/ApiRoutes";
const AddBranch = ({
  open,
  handleClose,
  handleOpen,
  callBack,
  type,
  setType,
  cid,
  udata
}) => {
  const [branchname, setBranchName] = useState();
  const [contactno, setContactNo] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [postalcode, setPostalCode] = useState();
  const [pobox, setPoBox] = useState();
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [latlong, setLatLong] = useState();
  const [defaultLocation, setDefaultLocation] = useState({ lat: 0, lng: 0 });
  const [error, setError] = useState("");
  const handleLocationChange = (lat, lng) => {
    setLat(lat);
    setLong(lng);
    setLatLong("Lat: " + lat + " Long: " + lng);
  };

  const clearData = () => {
    setBranchName("");
    setContactNo("");
    setAddress("");
    setCity("");
    setState("");
    setCountry("");
    setPostalCode("");
    setPoBox("");
    setLat("");
    setLong("");
    setLatLong("");
  };

  useEffect(
    () => {
      if (type == 1) {
        console.log(udata);
        if (udata.branch_name) {
          setBranchName(udata.branch_name);
        }
        if (udata.branch_number) {
          setContactNo(udata.branch_number);
        }
        if (udata.branch_address) {
          setAddress(udata.branch_address);
        }
        if (udata.city) {
          setCity(udata.city);
        }
        if (udata.state) {
          setState(udata.state);
        }
        if (udata.country) {
          setCountry(udata.country);
        }
        if (udata.postal_code) {
          setPostalCode(udata.postal_code);
        }
        if (udata.po_box) {
          setPoBox(udata.po_box);
        }
        if (udata.lat) {
          setLat(udata.lat);
        }
        if (udata.lang) {
          setLong(udata.lang);
        }
      } else {
        clearData();
      }
    },
    [type]
  );

  const onClose = () => {
    callBack("close");
    handleClose();
  };

  const isNotValidate = () => {
    if (
      !branchname ||
      !city ||
      !address ||
      !contactno ||
      !country ||
      !pobox ||
      !postalcode
    ) {
      return true;
    } else {
      return false;
    }
  };
  const onAddUpdate = async () => {
    if (isNotValidate()) {
      setError("Required all fields");
      return;
    } else {
      setError("");
    }

    var userdata = await localStorage.getItem("user_data");
    userdata = JSON.parse(userdata);
    let data = {
      branch_name: branchname,
      branch_location: city,
      branch_address: address,
      branch_number: contactno,
      lat: lat,
      lang: long,
      client_id: cid,
      city: city,
      country: country,
      po_box: pobox,
      postal_code: postalcode,
      created_by: userdata.id,
      alternate_website : "",
      building_no: "",
    };

    const API_URL = type == 0 ? BRANCH_ADD : BRANCH_UPDATE + udata.client_id;
    let config = {
      method: "POST",
      url: API_URL,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data);
        onClose();
        callBack(1);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
        Branch
      </DialogTitle>

      <DialogContent>
        <div className="mt-20">Branch Name</div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          type="text"
          margin="dense"
          placeholder="Branch Name"
          onChange={e => setBranchName(e.target.value)}
          value={branchname}
          focused={false}
        />
        <div className="mt-20">Contact Number</div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          type="number"
          margin="dense"
          placeholder="Contact Number"
          onChange={e => setContactNo(e.target.value)}
          value={contactno}
          focused={false}
        />

        <div className="mt-20">Address</div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          type="text"
          margin="dense"
          placeholder="Address"
          onChange={e => setAddress(e.target.value)}
          value={address}
          focused={false}
        />
        <div className="mt-20">City</div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          type="text"
          margin="dense"
          placeholder="City"
          onChange={e => setCity(e.target.value)}
          value={city}
          focused={false}
        />
        <div className="mt-20">State</div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          type="text"
          margin="dense"
          placeholder="State"
          onChange={e => setState(e.target.value)}
          value={state}
          focused={false}
        />

        <div className="mt-20">Country</div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          type="text"
          margin="dense"
          placeholder="Country"
          onChange={e => setCountry(e.target.value)}
          value={country}
          focused={false}
        />

        <div className="mt-20">Postal Code</div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          type="text"
          margin="dense"
          placeholder="Postal Code"
          onChange={e => setPostalCode(e.target.value)}
          value={postalcode}
          focused={false}
        />

        <div className="mt-20">Postal Box</div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          type="text"
          margin="dense"
          placeholder="Postal Box"
          onChange={e => setPoBox(e.target.value)}
          value={pobox}
          focused={false}
        />

        <div className="mt-20">Location</div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          type="text"
          margin="dense"
          placeholder="Location"
          value={latlong}
          disabled
        />

        <div>
          <MapPicker
            defaultLocation={defaultLocation}
            zoom={14}
            style={{ height: "200px" }}
            onChangeLocation={handleLocationChange}
            apiKey="AIzaSyAYIVMUAZe5dlkDQXZDH-ZEy6jeV1JlKn8"
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          onClick={() => onAddUpdate()}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>

      <span style={{ textAlign: "center", marginBottom: "5px", color: "red" }}>
        {error}
      </span>
    </Dialog>
  );
};

export default AddBranch;
