 const contrydata =  [
    {
        "id": 4,
        "icon": "assets/images/cimg/af.png",
        "code": "afg",
        "name": "Afghanistan"
    },
    {
        "id": 8,
        "icon": "assets/images/cimg/al.png",
        "code": "alb",
        "name": "Albania"
    },
    {
        "id": 12,
        "icon": "assets/images/cimg/dz.png",
        "code": "dza",
        "name": "Algeria"
    },
    {
        "id": 20,
        "icon": "assets/images/cimg/ad.png",
        "code": "and",
        "name": "Andorra"
    },
    {
        "id": 24,
        "icon": "assets/images/cimg/ao.png",
        "code": "ago",
        "name": "Angola"
    },
    {
        "id": 28,
        "icon": "assets/images/cimg/ag.png",
        "code": "atg",
        "name": "Antigua and Barbuda"
    },
    {
        "id": 32,
        "icon": "assets/images/cimg/ar.png",
        "code": "arg",
        "name": "Argentina"
    },
    {
        "id": 51,
        "icon": "assets/images/cimg/am.png",
        "code": "arm",
        "name": "Armenia"
    },
    {
        "id": 36,
        "icon": "assets/images/cimg/au.png",
        "code": "aus",
        "name": "Australia"
    },
    {
        "id": 40,
        "icon": "assets/images/cimg/at.png",
        "code": "aut",
        "name": "Austria"
    },
    {
        "id": 31,
        "icon": "assets/images/cimg/az.png",
        "code": "aze",
        "name": "Azerbaijan"
    },
    {
        "id": 44,
        "icon": "assets/images/cimg/bs.png",
        "code": "bhs",
        "name": "Bahamas"
    },
    {
        "id": 48,
        "icon": "assets/images/cimg/bh.png",
        "code": "bhr",
        "name": "Bahrain"
    },
    {
        "id": 50,
        "icon": "assets/images/cimg/bd.png",
        "code": "bgd",
        "name": "Bangladesh"
    },
    {
        "id": 52,
        "icon": "assets/images/cimg/bb.png",
        "code": "brb",
        "name": "Barbados"
    },
    {
        "id": 112,
        "icon": "assets/images/cimg/by.png",
        "code": "blr",
        "name": "Belarus"
    },
    {
        "id": 56,
        "icon": "assets/images/cimg/be.png",
        "code": "bel",
        "name": "Belgium"
    },
    {
        "id": 84,
        "icon": "assets/images/cimg/bz.png",
        "code": "blz",
        "name": "Belize"
    },
    {
        "id": 204,
        "icon": "assets/images/cimg/bj.png",
        "code": "ben",
        "name": "Benin"
    },
    {
        "id": 64,
        "icon": "assets/images/cimg/bt.png",
        "code": "btn",
        "name": "Bhutan"
    },
    {
        "id": 68,
        "icon": "assets/images/cimg/bo.png",
        "code": "bol",
        "name": "Bolivia (Plurinational State of)"
    },
    {
        "id": 70,
        "icon": "assets/images/cimg/ba.png",
        "code": "bih",
        "name": "Bosnia and Herzegovina"
    },
    {
        "id": 72,
        "icon": "assets/images/cimg/bw.png",
        "code": "bwa",
        "name": "Botswana"
    },
    {
        "id": 76,
        "icon": "assets/images/cimg/br.png",
        "code": "bra",
        "name": "Brazil"
    },
    {
        "id": 96,
        "icon": "assets/images/cimg/bn.png",
        "code": "brn",
        "name": "Brunei Darussalam"
    },
    {
        "id": 100,
        "icon": "assets/images/cimg/bg.png",
        "code": "bgr",
        "name": "Bulgaria"
    },
    {
        "id": 854,
        "icon": "assets/images/cimg/bf.png",
        "code": "bfa",
        "name": "Burkina Faso"
    },
    {
        "id": 108,
        "icon": "assets/images/cimg/bi.png",
        "code": "bdi",
        "name": "Burundi"
    },
    {
        "id": 132,
        "icon": "assets/images/cimg/cv.png",
        "code": "cpv",
        "name": "Cabo Verde"
    },
    {
        "id": 116,
        "icon": "assets/images/cimg/kh.png",
        "code": "khm",
        "name": "Cambodia"
    },
    {
        "id": 120,
        "icon": "assets/images/cimg/cm.png",
        "code": "cmr",
        "name": "Cameroon"
    },
    {
        "id": 124,
        "icon": "assets/images/cimg/ca.png",
        "code": "can",
        "name": "Canada"
    },
    {
        "id": 140,
        "icon": "assets/images/cimg/cf.png",
        "code": "caf",
        "name": "Central African Republic"
    },
    {
        "id": 148,
        "icon": "assets/images/cimg/td.png",
        "code": "tcd",
        "name": "Chad"
    },
    {
        "id": 152,
        "icon": "assets/images/cimg/cl.png",
        "code": "chl",
        "name": "Chile"
    },
    {
        "id": 156,
        "icon": "assets/images/cimg/cn.png",
        "code": "chn",
        "name": "China"
    },
    {
        "id": 170,
        "icon": "assets/images/cimg/co.png",
        "code": "col",
        "name": "Colombia"
    },
    {
        "id": 174,
        "icon": "assets/images/cimg/km.png",
        "code": "com",
        "name": "Comoros"
    },
    {
        "id": 178,
        "icon": "assets/images/cimg/cg.png",
        "code": "cog",
        "name": "Congo"
    },
    {
        "id": 180,
        "icon": "assets/images/cimg/cd.png",
        "code": "cod",
        "name": "Congo, Democratic Republic of the"
    },
    {
        "id": 188,
        "icon": "assets/images/cimg/cr.png",
        "code": "cri",
        "name": "Costa Rica"
    },
    {
        "id": 384,
        "icon": "assets/images/cimg/ci.png",
        "code": "civ",
        "name": "Côte d'Ivoire"
    },
    {
        "id": 191,
        "icon": "assets/images/cimg/hr.png",
        "code": "hrv",
        "name": "Croatia"
    },
    {
        "id": 192,
        "icon": "assets/images/cimg/cu.png",
        "code": "cub",
        "name": "Cuba"
    },
    {
        "id": 196,
        "icon": "assets/images/cimg/cy.png",
        "code": "cyp",
        "name": "Cyprus"
    },
    {
        "id": 203,
        "icon": "assets/images/cimg/cz.png",
        "code": "cze",
        "name": "Czechia"
    },
    {
        "id": 208,
        "icon": "assets/images/cimg/dk.png",
        "code": "dnk",
        "name": "Denmark"
    },
    {
        "id": 262,
        "icon": "assets/images/cimg/dj.png",
        "code": "dji",
        "name": "Djibouti"
    },
    {
        "id": 212,
        "icon": "assets/images/cimg/dm.png",
        "code": "dma",
        "name": "Dominica"
    },
    {
        "id": 214,
        "icon": "assets/images/cimg/do.png",
        "code": "dom",
        "name": "Dominican Republic"
    },
    {
        "id": 218,
        "icon": "assets/images/cimg/ec.png",
        "code": "ecu",
        "name": "Ecuador"
    },
    {
        "id": 818,
        "icon": "assets/images/cimg/eg.png",
        "code": "egy",
        "name": "Egypt"
    },
    {
        "id": 222,
        "icon": "assets/images/cimg/sv.png",
        "code": "slv",
        "name": "El Salvador"
    },
    {
        "id": 226,
        "icon": "assets/images/cimg/gq.png",
        "code": "gnq",
        "name": "Equatorial Guinea"
    },
    {
        "id": 232,
        "icon": "assets/images/cimg/er.png",
        "code": "eri",
        "name": "Eritrea"
    },
    {
        "id": 233,
        "icon": "assets/images/cimg/ee.png",
        "code": "est",
        "name": "Estonia"
    },
    {
        "id": 748,
        "icon": "assets/images/cimg/sz.png",
        "code": "swz",
        "name": "Eswatini"
    },
    {
        "id": 231,
        "icon": "assets/images/cimg/et.png",
        "code": "eth",
        "name": "Ethiopia"
    },
    {
        "id": 242,
        "icon": "assets/images/cimg/fj.png",
        "code": "fji",
        "name": "Fiji"
    },
    {
        "id": 246,
        "icon": "assets/images/cimg/fi.png",
        "code": "fin",
        "name": "Finland"
    },
    {
        "id": 250,
        "icon": "assets/images/cimg/fr.png",
        "code": "fra",
        "name": "France"
    },
    {
        "id": 266,
        "icon": "assets/images/cimg/ga.png",
        "code": "gab",
        "name": "Gabon"
    },
    {
        "id": 270,
        "icon": "assets/images/cimg/gm.png",
        "code": "gmb",
        "name": "Gambia"
    },
    {
        "id": 268,
        "icon": "assets/images/cimg/ge.png",
        "code": "geo",
        "name": "Georgia"
    },
    {
        "id": 276,
        "icon": "assets/images/cimg/de.png",
        "code": "deu",
        "name": "Germany"
    },
    {
        "id": 288,
        "icon": "assets/images/cimg/gh.png",
        "code": "gha",
        "name": "Ghana"
    },
    {
        "id": 300,
        "icon": "assets/images/cimg/gr.png",
        "code": "grc",
        "name": "Greece"
    },
    {
        "id": 308,
        "icon": "assets/images/cimg/gd.png",
        "code": "grd",
        "name": "Grenada"
    },
    {
        "id": 320,
        "icon": "assets/images/cimg/gt.png",
        "code": "gtm",
        "name": "Guatemala"
    },
    {
        "id": 324,
        "icon": "assets/images/cimg/gn.png",
        "code": "gin",
        "name": "Guinea"
    },
    {
        "id": 624,
        "icon": "assets/images/cimg/gw.png",
        "code": "gnb",
        "name": "Guinea-Bissau"
    },
    {
        "id": 328,
        "icon": "assets/images/cimg/gy.png",
        "code": "guy",
        "name": "Guyana"
    },
    {
        "id": 332,
        "icon": "assets/images/cimg/ht.png",
        "code": "hti",
        "name": "Haiti"
    },
    {
        "id": 340,
        "icon": "assets/images/cimg/hn.png",
        "code": "hnd",
        "name": "Honduras"
    },
    {
        "id": 348,
        "icon": "assets/images/cimg/hu.png",
        "code": "hun",
        "name": "Hungary"
    },
    {
        "id": 352,
        "icon": "assets/images/cimg/is.png",
        "code": "isl",
        "name": "Iceland"
    },
    {
        "id": 356,
        "icon": "assets/images/cimg/in.png",
        "code": "ind",
        "name": "India"
    },
    {
        "id": 360,
        "icon": "assets/images/cimg/id.png",
        "code": "idn",
        "name": "Indonesia"
    },
    {
        "id": 364,
        "icon": "assets/images/cimg/ir.png",
        "code": "irn",
        "name": "Iran (Islamic Republic of)"
    },
    {
        "id": 368,
        "icon": "assets/images/cimg/iq.png",
        "code": "irq",
        "name": "Iraq"
    },
    {
        "id": 372,
        "icon": "assets/images/cimg/ie.png",
        "code": "irl",
        "name": "Ireland"
    },
    {
        "id": 376,
        "icon": "assets/images/cimg/il.png",
        "code": "isr",
        "name": "Israel"
    },
    {
        "id": 380,
        "icon": "assets/images/cimg/it.png",
        "code": "ita",
        "name": "Italy"
    },
    {
        "id": 388,
        "icon": "assets/images/cimg/jm.png",
        "code": "jam",
        "name": "Jamaica"
    },
    {
        "id": 392,
        "icon": "assets/images/cimg/jp.png",
        "code": "jpn",
        "name": "Japan"
    },
    {
        "id": 400,
        "icon": "assets/images/cimg/jo.png",
        "code": "jor",
        "name": "Jordan"
    },
    {
        "id": 398,
        "icon": "assets/images/cimg/kz.png",
        "code": "kaz",
        "name": "Kazakhstan"
    },
    {
        "id": 404,
        "icon": "assets/images/cimg/ke.png",
        "code": "ken",
        "name": "Kenya"
    },
    {
        "id": 296,
        "icon": "assets/images/cimg/ki.png",
        "code": "kir",
        "name": "Kiribati"
    },
    {
        "id": 408,
        "icon": "assets/images/cimg/kp.png",
        "code": "prk",
        "name": "Korea (Democratic People's Republic of)"
    },
    {
        "id": 410,
        "icon": "assets/images/cimg/kr.png",
        "code": "kor",
        "name": "Korea, Republic of"
    },
    {
        "id": 414,
        "icon": "assets/images/cimg/kw.png",
        "code": "kwt",
        "name": "Kuwait"
    },
    {
        "id": 417,
        "icon": "assets/images/cimg/kg.png",
        "code": "kgz",
        "name": "Kyrgyzstan"
    },
    {
        "id": 418,
        "icon": "assets/images/cimg/la.png",
        "code": "lao",
        "name": "Lao People's Democratic Republic"
    },
    {
        "id": 428,
        "icon": "assets/images/cimg/lv.png",
        "code": "lva",
        "name": "Latvia"
    },
    {
        "id": 422,
        "icon": "assets/images/cimg/lb.png",
        "code": "lbn",
        "name": "Lebanon"
    },
    {
        "id": 426,
        "icon": "assets/images/cimg/ls.png",
        "code": "lso",
        "name": "Lesotho"
    },
    {
        "id": 430,
        "icon": "assets/images/cimg/lr.png",
        "code": "lbr",
        "name": "Liberia"
    },
    {
        "id": 434,
        "icon": "assets/images/cimg/ly.png",
        "code": "lby",
        "name": "Libya"
    },
    {
        "id": 438,
        "icon": "assets/images/cimg/li.png",
        "code": "lie",
        "name": "Liechtenstein"
    },
    {
        "id": 440,
        "icon": "assets/images/cimg/lt.png",
        "code": "ltu",
        "name": "Lithuania"
    },
    {
        "id": 442,
        "icon": "assets/images/cimg/lu.png",
        "code": "lux",
        "name": "Luxembourg"
    },
    {
        "id": 450,
        "icon": "assets/images/cimg/mg.png",
        "code": "mdg",
        "name": "Madagascar"
    },
    {
        "id": 454,
        "icon": "assets/images/cimg/mw.png",
        "code": "mwi",
        "name": "Malawi"
    },
    {
        "id": 458,
        "icon": "assets/images/cimg/my.png",
        "code": "mys",
        "name": "Malaysia"
    },
    {
        "id": 462,
        "icon": "assets/images/cimg/mv.png",
        "code": "mdv",
        "name": "Maldives"
    },
    {
        "id": 466,
        "icon": "assets/images/cimg/ml.png",
        "code": "mli",
        "name": "Mali"
    },
    {
        "id": 470,
        "icon": "assets/images/cimg/mt.png",
        "code": "mlt",
        "name": "Malta"
    },
    {
        "id": 584,
        "icon": "assets/images/cimg/mh.png",
        "code": "mhl",
        "name": "Marshall Islands"
    },
    {
        "id": 478,
        "icon": "assets/images/cimg/mr.png",
        "code": "mrt",
        "name": "Mauritania"
    },
    {
        "id": 480,
        "icon": "assets/images/cimg/mu.png",
        "code": "mus",
        "name": "Mauritius"
    },
    {
        "id": 484,
        "icon": "assets/images/cimg/mx.png",
        "code": "mex",
        "name": "Mexico"
    },
    {
        "id": 583,
        "icon": "assets/images/cimg/fm.png",
        "code": "fsm",
        "name": "Micronesia (Federated States of)"
    },
    {
        "id": 498,
        "icon": "assets/images/cimg/md.png",
        "code": "mda",
        "name": "Moldova, Republic of"
    },
    {
        "id": 492,
        "icon": "assets/images/cimg/mc.png",
        "code": "mco",
        "name": "Monaco"
    },
    {
        "id": 496,
        "icon": "assets/images/cimg/mn.png",
        "code": "mng",
        "name": "Mongolia"
    },
    {
        "id": 499,
        "icon": "assets/images/cimg/me.png",
        "code": "mne",
        "name": "Montenegro"
    },
    {
        "id": 504,
        "icon": "assets/images/cimg/ma.png",
        "code": "mar",
        "name": "Morocco"
    },
    {
        "id": 508,
        "icon": "assets/images/cimg/mz.png",
        "code": "moz",
        "name": "Mozambique"
    },
    {
        "id": 104,
        "icon": "assets/images/cimg/mm.png",
        "code": "mmr",
        "name": "Myanmar"
    },
    {
        "id": 516,
        "icon": "assets/images/cimg/na.png",
        "code": "nam",
        "name": "Namibia"
    },
    {
        "id": 520,
        "icon": "assets/images/cimg/nr.png",
        "code": "nru",
        "name": "Nauru"
    },
    {
        "id": 524,
        "icon": "assets/images/cimg/np.png",
        "code": "npl",
        "name": "Nepal"
    },
    {
        "id": 528,
        "icon": "assets/images/cimg/nl.png",
        "code": "nld",
        "name": "Netherlands"
    },
    {
        "id": 554,
        "icon": "assets/images/cimg/nz.png",
        "code": "nzl",
        "name": "New Zealand"
    },
    {
        "id": 558,
        "icon": "assets/images/cimg/ni.png",
        "code": "nic",
        "name": "Nicaragua"
    },
    {
        "id": 562,
        "icon": "assets/images/cimg/ne.png",
        "code": "ner",
        "name": "Niger"
    },
    {
        "id": 566,
        "icon": "assets/images/cimg/ng.png",
        "code": "nga",
        "name": "Nigeria"
    },
    {
        "id": 807,
        "icon": "assets/images/cimg/mk.png",
        "code": "mkd",
        "name": "North Macedonia"
    },
    {
        "id": 578,
        "icon": "assets/images/cimg/no.png",
        "code": "nor",
        "name": "Norway"
    },
    {
        "id": 512,
        "icon": "assets/images/cimg/om.png",
        "code": "omn",
        "name": "Oman"
    },
    {
        "id": 586,
        "icon": "assets/images/cimg/pk.png",
        "code": "pak",
        "name": "Pakistan"
    },
    {
        "id": 585,
        "icon": "assets/images/cimg/pw.png",
        "code": "plw",
        "name": "Palau"
    },
    {
        "id": 591,
        "icon": "assets/images/cimg/pa.png",
        "code": "pan",
        "name": "Panama"
    },
    {
        "id": 598,
        "icon": "assets/images/cimg/pg.png",
        "code": "png",
        "name": "Papua New Guinea"
    },
    {
        "id": 600,
        "icon": "assets/images/cimg/py.png",
        "code": "pry",
        "name": "Paraguay"
    },
    {
        "id": 604,
        "icon": "assets/images/cimg/pe.png",
        "code": "per",
        "name": "Peru"
    },
    {
        "id": 608,
        "icon": "assets/images/cimg/ph.png",
        "code": "phl",
        "name": "Philippines"
    },
    {
        "id": 616,
        "icon": "assets/images/cimg/pl.png",
        "code": "pol",
        "name": "Poland"
    },
    {
        "id": 620,
        "icon": "assets/images/cimg/pt.png",
        "code": "prt",
        "name": "Portugal"
    },
    {
        "id": 634,
        "icon": "assets/images/cimg/qa.png",
        "code": "qat",
        "name": "Qatar"
    },
    {
        "id": 642,
        "icon": "assets/images/cimg/ro.png",
        "code": "rou",
        "name": "Romania"
    },
    {
        "id": 643,
        "icon": "assets/images/cimg/ru.png",
        "code": "rus",
        "name": "Russian Federation"
    },
    {
        "id": 646,
        "icon": "assets/images/cimg/rw.png",
        "code": "rwa",
        "name": "Rwanda"
    },
    {
        "id": 659,
        "icon": "assets/images/cimg/kn.png",
        "code": "kna",
        "name": "Saint Kitts and Nevis"
    },
    {
        "id": 662,
        "icon": "assets/images/cimg/lc.png",
        "code": "lca",
        "name": "Saint Lucia"
    },
    {
        "id": 670,
        "icon": "assets/images/cimg/vc.png",
        "code": "vct",
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "id": 882,
        "icon": "assets/images/cimg/ws.png",
        "code": "wsm",
        "name": "Samoa"
    },
    {
        "id": 674,
        "icon": "assets/images/cimg/sm.png",
        "code": "smr",
        "name": "San Marino"
    },
    {
        "id": 678,
        "icon": "assets/images/cimg/st.png",
        "code": "stp",
        "name": "Sao Tome and Principe"
    },
    {
        "id": 682,
        "icon": "assets/images/cimg/sa.png",
        "code": "sau",
        "name": "Saudi Arabia"
    },
    {
        "id": 686,
        "icon": "assets/images/cimg/sn.png",
        "code": "sen",
        "name": "Senegal"
    },
    {
        "id": 688,
        "icon": "assets/images/cimg/rs.png",
        "code": "srb",
        "name": "Serbia"
    },
    {
        "id": 690,
        "icon": "assets/images/cimg/sc.png",
        "code": "syc",
        "name": "Seychelles"
    },
    {
        "id": 694,
        "icon": "assets/images/cimg/sl.png",
        "code": "sle",
        "name": "Sierra Leone"
    },
    {
        "id": 702,
        "icon": "assets/images/cimg/sg.png",
        "code": "sgp",
        "name": "Singapore"
    },
    {
        "id": 703,
        "icon": "assets/images/cimg/sk.png",
        "code": "svk",
        "name": "Slovakia"
    },
    {
        "id": 705,
        "icon": "assets/images/cimg/si.png",
        "code": "svn",
        "name": "Slovenia"
    },
    {
        "id": 90,
        "icon": "assets/images/cimg/sb.png",
        "code": "slb",
        "name": "Solomon Islands"
    },
    {
        "id": 706,
        "icon": "assets/images/cimg/so.png",
        "code": "som",
        "name": "Somalia"
    },
    {
        "id": 710,
        "icon": "assets/images/cimg/za.png",
        "code": "zaf",
        "name": "South Africa"
    },
    {
        "id": 728,
        "icon": "assets/images/cimg/ss.png",
        "code": "ssd",
        "name": "South Sudan"
    },
    {
        "id": 724,
        "icon": "assets/images/cimg/es.png",
        "code": "esp",
        "name": "Spain"
    },
    {
        "id": 144,
        "icon": "assets/images/cimg/lk.png",
        "code": "lka",
        "name": "Sri Lanka"
    },
    {
        "id": 729,
        "icon": "assets/images/cimg/sd.png",
        "code": "sdn",
        "name": "Sudan"
    },
    {
        "id": 740,
        "icon": "assets/images/cimg/sr.png",
        "code": "sur",
        "name": "Suriname"
    },
    {
        "id": 752,
        "icon": "assets/images/cimg/se.png",
        "code": "swe",
        "name": "Sweden"
    },
    {
        "id": 756,
        "icon": "assets/images/cimg/ch.png",
        "code": "che",
        "name": "Switzerland"
    },
    {
        "id": 760,
        "icon": "assets/images/cimg/sy.png",
        "code": "syr",
        "name": "Syrian Arab Republic"
    },
    {
        "id": 762,
        "icon": "assets/images/cimg/tj.png",
        "code": "tjk",
        "name": "Tajikistan"
    },
    {
        "id": 834,
        "icon": "assets/images/cimg/tz.png",
        "code": "tza",
        "name": "Tanzania, United Republic of"
    },
    {
        "id": 764,
        "icon": "assets/images/cimg/th.png",
        "code": "tha",
        "name": "Thailand"
    },
    {
        "id": 626,
        "icon": "assets/images/cimg/tl.png",
        "code": "tls",
        "name": "Timor-Leste"
    },
    {
        "id": 768,
        "icon": "assets/images/cimg/tg.png",
        "code": "tgo",
        "name": "Togo"
    },
    {
        "id": 776,
        "icon": "assets/images/cimg/to.png",
        "code": "ton",
        "name": "Tonga"
    },
    {
        "id": 780,
        "icon": "assets/images/cimg/tt.png",
        "code": "tto",
        "name": "Trinidad and Tobago"
    },
    {
        "id": 788,
        "icon": "assets/images/cimg/tn.png",
        "code": "tun",
        "name": "Tunisia"
    },
    {
        "id": 792,
        "icon": "assets/images/cimg/tr.png",
        "code": "tur",
        "name": "Türkiye"
    },
    {
        "id": 795,
        "icon": "assets/images/cimg/tm.png",
        "code": "tkm",
        "name": "Turkmenistan"
    },
    {
        "id": 798,
        "icon": "assets/images/cimg/tv.png",
        "code": "tuv",
        "name": "Tuvalu"
    },
    {
        "id": 800,
        "icon": "assets/images/cimg/ug.png",
        "code": "uga",
        "name": "Uganda"
    },
    {
        "id": 804,
        "icon": "assets/images/cimg/ua.png",
        "code": "ukr",
        "name": "Ukraine"
    },
    {
        "id": 784,
        "icon": "assets/images/cimg/ae.png",
        "code": "are",
        "name": "United Arab Emirates"
    },
    {
        "id": 826,
        "icon": "assets/images/cimg/gb.png",
        "code": "gbr",
        "name": "United Kingdom of Great Britain and Northern Ireland"
    },
    {
        "id": 840,
        "icon": "assets/images/cimg/us.png",
        "code": "usa",
        "name": "United States of America"
    },
    {
        "id": 858,
        "icon": "assets/images/cimg/uy.png",
        "code": "ury",
        "name": "Uruguay"
    },
    {
        "id": 860,
        "icon": "assets/images/cimg/uz.png",
        "code": "uzb",
        "name": "Uzbekistan"
    },
    {
        "id": 548,
        "icon": "assets/images/cimg/vu.png",
        "code": "vut",
        "name": "Vanuatu"
    },
    {
        "id": 862,
        "icon": "assets/images/cimg/ve.png",
        "code": "ven",
        "name": "Venezuela (Bolivarian Republic of)"
    },
    {
        "id": 704,
        "icon": "assets/images/cimg/vn.png",
        "code": "vnm",
        "name": "Viet Nam"
    },
    {
        "id": 887,
        "icon": "assets/images/cimg/ye.png",
        "code": "yem",
        "name": "Yemen"
    },
    {
        "id": 894,
        "icon": "assets/images/cimg/zm.png",
        "code": "zmb",
        "name": "Zambia"
    },
    {
        "id": 716,
        "icon": "assets/images/cimg/zw.png",
        "code": "zwe",
        "name": "Zimbabwe"
    }
]

export default  contrydata;