import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  styled,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  IconButton
} from "@mui/material";

import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SignatureCanvas from "react-signature-canvas";
import html2canvas from "html2canvas";

import {
  BRANCH_LIST,
  CLIENT_LIST,
  GET_JR_NO,
  GET_TANK,
  LIST_JOB_RECEIPT,
  GET_TANK_DETAILS,
  GET_CHEMICAL_LIST,
  ADD_JOB_RECEIPT,
  UPDATE_JOB_RECEIPT,
  ADD_JR_PREFIX,
  CHEMICAL_TAKEN,
  ADD_TANK_TRACK
} from "app/utils/ApiRoutes.js";

import Swal from "sweetalert2";
import axios from "axios";
import AddClient from "../Common/AddClient";
import AddBranch from "../Common/AddBranch";
import AddTank from "../Common/AddTank";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
  }
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize"
}));

const CardFooder = styled(Box)(()=>({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "center"
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: "pre",
  "& small": {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)"
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1)
}));

function AddJobReceipt() {
  const navigations = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [tankselected, setTankSelected] = useState(false);
  const [tankdisabled, setTankDisablsed] = useState(false);
  const [tanktypename, setTankTypeName] = useState("");
  const [tanksnoname, setTankSNoName] = useState("");
  const [tanksdata, setTanksn] = useState("");

  const [price, setPrice] = useState("");
  useEffect(() => {
   
    if(location && location.state) {
      console.log(location.state);
      if(location.state.from == 0) {
        setSelected(true);
        setDisabled(false);
        setTankSelected(true);
        setTankDisablsed(false);
        setServiceType("Demo");
        setDate(new Date().toISOString().split("T")[0]);
      }

      if(location.state.from == 1) { 
        setSelected(false);
        setDisabled(true);
        var data = location.state.data;
        setPrice(data.price);
        setClientID(data.client_id);
        setBranchID(data.branch_id);
        setClientName(data.client_name);
        setBranchName(data.branch_name);
        getJRNo(data.client_id, data.branch_id);
        setServiceType(data.type);
        setTemprature(data.temperature);
        const nextDate = new Date(data.date);
        nextDate.setDate(nextDate.getDate() + 1);
        setDate(new Date(nextDate).toISOString().split("T")[0]);    
        if(data.type.toLowerCase() == "service"){
          setTankType(data.tank_type);
          setTankSN(data.tank_serial_no);
          getChemicalSize(data.tank_type, data.tank_serial_no);
          setTankTypeName(data.name);
          setTankSNoName(data.serial_no);
          setTankSelected(false);
          setTankDisablsed(true);
        }else{
          setTankSelected(true);
          setTankDisablsed(false);
        }
      }
    }
  }, []);
useEffect(()=>{
      if(callback){
        setCallBack("");
      }
      if (callback == 1) {
        ListClient();
      }  
      if (callback == 2) {
        ListTank();
      }  
},[callback]);

useEffect(() => {
  if(location && location.state.from == 0) {
      ListClient();
      ListTank();
  }
  ListChemical();
}, []);

  var userdata = localStorage.getItem("user_data");
  userdata = JSON.parse(userdata);
  var locationdata = localStorage.getItem("location-data");
  locationdata = JSON.parse(locationdata);

  const captureRef = useRef(null);

  const [finished, setFinished] = useState(false);

  const [steps, setSteps] = useState([
    {
      key: 1,
      label: "JR Details",
      isDone: true
    },
    {
      key: 2,
      label: "Client Details",
      isDone: false
    },
    {
      key: 3,
      label: "Tank Details",
      isDone: false
    },
    {
      key: 4,
      label: "Chemical Details",
      isDone: false
    },
    {
      key: 5,
      label: "Office Use",
      isDone: false
    }
  ]);

  const [activeStep, setActiveStep] = useState(steps[0]);
  const [error, setError] = useState("");

  const handleNext = () => {

if(location.state.from == 1){
  var data = location.state.data;
  const nextDate = new Date(data.date);
  nextDate.setDate(nextDate.getDate() + 1);
  var formdate = new Date(nextDate).toISOString().split("T")[0];
  var newdate = new Date().toISOString().split("T")[0];
  setError("");
  
  if((newdate != formdate || formdate > newdate || date != formdate) && serviceType == 'Service'){
    setError(`The service date is ${formdate} you Cannot able to Select before`);
    return;
  }  
}

    const index = steps.findIndex(x => x.key === activeStep.key);
    setError("");
    if (index == 1) {
      if (!jrno) {
        setError("(Required all fields)");
        return;
      }
    }
 

    if (index == 2) {
      if (!tankType || !tanksn || !temprature) {
        setError("(Required all fields)");
        return;
      }
    }

    if (index == 3 && serviceType != "Removal") {
      if (reqchemical && overallTotal && chemicladata.length > 0) {
        if (parseInt(reqchemical) > parseInt(overallTotal)) {
          setError(
            "(Must be chemical total is equal to required chemical or greater than)"
          );
          return;
        }
      } else {
        setError("(Required all fields)");
        return;
      }
      if (chemicladata.length == 0) {
        setError("(Required all fields)");
        return;
      }
    }

    if (index == 4) {
      if (!comname || !composition || !accname || !accposition) {
        setError("(Required all fields)");
        return;
      }
    }

    if (index == 1) {
      if (
        location &&
        location.state &&
        location.state.from == 1 &&
        location.state.data.type.toLowerCase() == "service"
      ) {
      } else {
        ListTank();
      }
    }

    setSteps(prevStep =>
      prevStep.map(x => {
        if (x.key === activeStep.key) x.isDone = true;
        return x;
      })
    );
    // setActiveStep(steps[index + 1]);
    if (index == 2 && serviceType == "Removal") {
      setActiveStep(steps[4]);
    }else{
      setActiveStep(steps[index + 1]);
    }
    // setActiveStep(steps[index + 1]);

    if (steps[steps.length - 1].key === activeStep.key) {
      setFinished(true);
      saveSignature();
      return;
    }
  };

  const handleBack = () => {
    setError("");
    const index = steps.findIndex(x => x.key === activeStep.key);
    if (index === 0) return;

    setSteps(prevStep =>
      prevStep.map(x => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    if (index == 4 && serviceType == "Removal") {
      setActiveStep(steps[2]);
    }else{
      setActiveStep(steps[index - 1]);
    }
    // setActiveStep(steps[index - 1]);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setNoPacktes("1");
    setPacketStock("0");
    setBagNo("");
    setChemicalError("");
    ListChemical();
  };

  const handleClose = () => setOpen(false);

  // JR Details
  const [serviceType, setServiceType] = useState("");
  const [date, setDate] = useState();
  const [removaldate, setRemovalDate] = useState(
    new Date(new Date().getTime() + 28 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );

  // Client Details
  const [clientId, setClientID] = useState("");
  const [branchId, setBranchID] = useState("");
  const [clientName, setClientName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [jrno, setJrNo] = useState("");
  const [jrprfix, setJrPrefix] = useState("");

  // Tank Details
  const [tankType, setTankType] = useState("");
  const [tanksn, setTankSN] = useState("");
  const [temprature, setTemprature] = useState("");
  const [tankdata, setTankData] = useState([]);
  const [serialnodata, setSerialNodata] = useState([]);

  // Chemical Details
  const [reqchemical, setReqChemical] = useState();
  const [overallchemical, setOverllChemical] = useState();
  const [chemicladata, setChemicalData] = useState([]);
  const [overallTotal, setOverallTotal] = useState(0);

  const [packetsize, setPacketSize] = useState("");
  const [packetstock, setPacketStock] = useState("");
  const [nopackets, setNoPacktes] = useState("");
  const [bagno, setBagNo] = useState("");
  const [chemicalerror, setChemicalError] = useState("");
  const [chemicallist, setChemicalList] = useState([]);
  // Office Use
  const [comname, setComName] = useState("");
  const [composition, setComPosition] = useState("");
  const [comsign, setComSign] = useState("");

  const [accname, setAccName] = useState("");
  const [accposition, setAccPosition] = useState("");
  const [accsign, setAccSign] = useState("");

  const signatureRef = useRef(null);
  const signatureRef1 = useRef(null);
  const [callback, setCallBack] = useState("");

  const [clientopen, setClientOpen] = useState();
  const [tankopen, setTankopen] = useState(false);
  const [tankdetailopen, setTankdetailopen] = useState(false);

  const onTankopen = () => setTankopen(!tankopen);
  const onTankdetailopen= () => setTankdetailopen(!tankdetailopen);

  const onClientOpen = () => setClientOpen(true);
  const onClientClose = () => setClientOpen(false);

  const [branchopen, setBranchOpen] = useState();
  const onBranchOpen = () => setBranchOpen(true);
  const onBranchClose = () => setBranchOpen(false);

  const [remark, setRemark] = useState("");
  const [clientdata, setClientData] = useState([]);
  const [branchdata, setBranchData] = useState([]);
  const [type, setType] = useState("");

  const saveSignature = () => {
    if(signatureRef.current){
      const signatureDataURL = signatureRef.current.toDataURL();
      setComSign(signatureDataURL);
    }
    if(signatureRef1.current) {
      const signatureDataURL = signatureRef1.current.toDataURL();
      setAccSign(signatureDataURL);
    }
  };

  const clearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  const clearSignature1 = () => {
    if (signatureRef1.current) {
      signatureRef1.current.clear();
    }
  };

  // API
  const addChemical = () => {
    if (packetsize && nopackets && bagno) {
      if (parseInt(nopackets) > parseInt(packetstock)) {
        setChemicalError(
          "No. of packets shouldn't be greater than the stock !"
        );
        return;
      }

      var data = chemicladata;

      var datafilter = chemicladata.filter(
        item => item.packetsize == packetsize
      );
      console.log();
      if (datafilter && datafilter.length > 0) {
        setChemicalError("Packet size already selected !");
        return;
      }
      data.push({
        packetsize: packetsize,
        nopackets: nopackets,
        bagno: bagno,
        total: parseInt(packetsize) * parseInt(nopackets)
      });

      setChemicalData(data);

      const total = data.reduce((sum, item) => sum + item.total, 0);
      setOverallTotal(total);
      console.log(chemicladata);
      handleClose();
    }
  };

  const deleteChemical = index => {
    const data = chemicladata.filter((_,i)=>i !== index);
    const total = data.reduce((sum, item) => sum + item.total, 0);
    setOverallTotal(total);
    setChemicalData(data);
  };

  const ListClient = () => {
    let config = {
      method: "GET",
      url: CLIENT_LIST
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          ListBranch(resdata[0].client_id);
          setClientID(resdata[0].client_id);
          setClientName(resdata[0].client_name);
          setClientData(resdata);
        } else {
          setClientData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setClientData([]);
      });
  };

  const ListBranch = id => {
    setBranchName("");
    setBranchName("");
    setJrNo("");
    let config = {
      method: "GET",
      url: BRANCH_LIST + id
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.response.data);
        var resdata = response.data.response.data;
        if (resdata.length > 0) {
          setBranchData(resdata);
          setBranchID(resdata[0].branch_id);
          setBranchName(resdata[0].branch_name);
          getJRNo(id, resdata[0].branch_id);
        } else {
          setBranchData([]);
        }
      })
      .catch(error => {
        console.log(error);
        setBranchData([]);
      });
  };

  const getJRNo = (client_id, branch_id) => {
    var data = {
      client_id: client_id,
      branch_id: branch_id
    };
    let config = {
      method: "POST",
      url: GET_JR_NO,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.jobrecipt_data.jr_no);
        setJrNo(response.data.jobrecipt_data.jr_no);
        setJrPrefix(response.data.jobrecipt_data.jr_prefix);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const ListTank = () => {
    // alert(tanksn)
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    var data = {
      location_id: locid
    };
    let config = {
      method: "POST",
      url: GET_TANK,
      data: data
    };
  
    axios.request(config)
      .then(response => {
        var resdata = response.data.data;
        if (resdata.length > 0) {
          if (
            (location && location.state.from == 0) ||
            !location.state.data.tank_type
          ) {
            ListSerialNo(resdata[0].id);
            setTankType(resdata[0].id);
            setReqChemical(resdata[0].chemicals);
          } else if (location && location.state.from == 1) {
            var data = resdata.filter(
              item => item.id === parseInt(location.state.data.tank_type)
            );
            if (data && data.length > 0) {
              ListSerialNo(data[0].id);
              setReqChemical(data[0].chemicals);
              setTankType(data[0].id);
            }
          }
          if(serviceType == "Removal"){
            RemovalType(locid)
          }else{
            setTankData(resdata);
          }
        } else {
          setTankData([]);
        }
      }).catch(error=>{
        console.log(error);
        setTankData([]);
      });
  };

  const RemovalType = (locid)=>{
      let config = {
        method: "GET",
        url: LIST_JOB_RECEIPT + locid
      };

      axios.request(config)
        .then(response => {
          var data = response.data.jobrecipt_data;
          data = data.filter((item)=>item.client_id == clientId && item.branch_id == branchId)
          data = data.filter((item) => [item.name, item.chemicals, item.tank_type,item.tank_serial_no].every((prop) => prop !== null))
          var tank = data.map((item) => ({
          name: item.name,
          chemicals: item.chemicals,
          id: item.tank_type
        }));
        var tank_details = data.map((item) => ({
          serial_no: item.serial_no,
          id: item.tank_serial_no,
          tank_type: item.tank_type,
        }));
        tank = removeDuplicates(tank);
        tank_details = removeDuplicates(tank_details);
           setSerialNodata(tank_details);
           setTankData(tank)
        })
  }

  function removeDuplicates(tanks) {
   
    const uniqueTanks = [];
    const seenTanks = {};
    for (const tank of tanks) {
      const key = JSON.stringify(tank);
      if (!seenTanks[key]) {
        uniqueTanks.push(tank);
        seenTanks[key] = true;
      }
    }
    return uniqueTanks;
  
  }
  
  const ListSerialNo = ID => {

    var locid = locationdata && locationdata.id ? locationdata.id : "";  
    var data = {
      tank_id: ID,
      date: date,
      location_id: locid
    };

    if (
      location &&
      location.state &&
      location.state.from == 1 &&
      location.state.data.type.toLowerCase() == "service"
    ){
      data.date = "";
    }
    let config = {
      method: "POST",
      url: GET_TANK_DETAILS,
      data: data
    };
    axios
      .request(config)
      .then(response => {
        var resdata = response.data.data;
        if (resdata.length > 0) {
          setSerialNodata(resdata);
          if ((location && location.state.from == 0) || tanksn) {
            setTankSN(resdata[0].id);
            // setReqChemical(resdata[0].chemicals);
          } else if (location && location.state.from == 1) {
            if (location.state.data && !location.state.data.tank_serial_no) {
              setTankSN(resdata[0].id);
              // setReqChemical(resdata[0].chemicals);
            } else {
              var data = resdata.filter(
                item => item.id === parseInt(location.state.data.tank_serial_no)
              );
              if (data && data.length > 0) {
                setTankSN(data[0].id);
                // setReqChemical(data[0].chemicals);
              } else {
                setTankSN(resdata[0].id);
                // setReqChemical(resdata[0].chemicals);
              }
            }
          }
        } else {
          setSerialNodata([]);
        }
      })
      .catch(error => {
        console.log(error);
        setSerialNodata([]);
      });
  };

  const ListChemical = () => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    let config = {
      method: "GET",
      url: GET_CHEMICAL_LIST + locid
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.data);
        var resdata = response.data.data;
        if (resdata.length > 0) {
          setChemicalList(resdata);
          setPacketSize(resdata[0].packet_size);
          setPacketStock(resdata[0].stock);
        } else {
          setChemicalList([]);
        }
      })
      .catch(error => {
        console.log(error);
        setChemicalList([]);
      });
  };


  function dataURLtoBlob(dataURL) {
    const base64Data = dataURL.split(",")[1];
    const byteString = atob(base64Data);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  const AddJobReceipt = () => {
    const formData = new FormData();
    formData.append("comp_by_sign", dataURLtoBlob(comsign), "image.png");
    formData.append("accepted_by_sign", dataURLtoBlob(accsign), "image.png");

    var data = {
      employee_id: userdata.id,
      location_id: locationdata.id,
      receipt_no: jrno,

      type: serviceType,
      date: date,
      removal_date: serviceType == "Demo" ? removaldate : "",

      client_id: clientId,
      branch_id: branchId,

      tank_type: tankType,
      tank_serial_no: tanksn,
      temperature: temprature,
      chemicals: overallTotal,

      comp_by_name: comname,
      comp_by_position: composition,

      accepted_by_name: accname,
      accepted_by_position: accposition,
      remark: remark,
      b_integer: "",
      payment: 0,
      status: 1,
      price: price
    };

    formData.append("data", JSON.stringify(data));

    var isAdd = true;
    var updateID = 0;
    if (location && location.state.from == 1 && location.state.data) {
      var udata = location.state.data;
      if (
        udata.type.toLowerCase() == "installation" ||
        udata.type.toLowerCase() == "demo"
      ) {
        isAdd = false;
        updateID = udata.id;
      }
    }

    let config = {
      method: "POST",
      url: isAdd ? ADD_JOB_RECEIPT : UPDATE_JOB_RECEIPT + updateID,
      data: formData
    };

    setLoading(true);
    axios
      .request(config)
      .then(response => {
        setLoading(false);
        console.log(response.data);
        if (response.data.insertid) {
          AddJrPrefix(response.data.insertid);
          if (serviceType != "Service") {
            TankTracking(response.data.insertid, false);
          }
          if (
            location & (location.state.from == 1) &&
            serviceType == "Service" &&
            tanksn != location.state.data.tank_serial_no
          ) {
            TankTracking(response.data.insertid, true);
            TankTracking(response.data.insertid, false);
          }
        } 
      }).catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const TankTracking = (ID, UpdateService) => {
    var data = {
      location_id: locationdata.id,
      date: date,
      client_id: clientId,
      branch_id: branchId,
      tank_id: tankType,
      tank_detail_id: tanksn,
      remark: serviceType,
      jr_id: ID,
      add_or_taken: serviceType.toLowerCase() == "removal" ? 0 : 1
    };

    if (UpdateService && location && location.state.data) {
      data.tank_id = location.state.data.tank_type;
      data.tank_detail_id = location.state.data.tank_serial_no;
      data.add_or_taken = 0;
      data.remark = "Service Updated";
    }

    let config = {
      method: "POST",
      url: ADD_TANK_TRACK,
      data: data
    };

    axios.request(config)
      .then(response => {
        navigations("/job-receipt");
      })
      .catch(error => {
        console.log(error);
      });
  };

  const AddJrPrefix = id => {
    var data = {
      user_id: userdata.id,
      branch_id: branchId,
      client_id: clientId,
      jr_id: id,
      date: date,
      jr_prefix: jrprfix
    };

    let config = {
      method: "POST",
      url: ADD_JR_PREFIX,
      data: data
    };

    setLoading(true);
    axios
      .request(config)
      .then(response => {
        setLoading(false);
        TakeChemicalData(id);
        console.log(response.data);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const TakeChemicalData = id => {
    var data = chemicladata;
    for (let index = 0; index < data.length; index++) {
      var item = data[index];
      var isfinal = index === data.length - 1;
      TakeChemical(id, item.nopackets, item.bagno, item.packetsize, isfinal);
    }
  };

  const TakeChemical = (jrId, no_of_pieces, bag_no, packet_size, final) => {
    var data = {
      date: date,
      employee_id: userdata.id,
      location_area_id: locationdata.id,
      add_or_taken: 1,
      purpose: serviceType,
      tank_serial_no: tanksn,
      no_of_pieces: no_of_pieces,
      bag_no: bag_no,
      packet_size: packet_size,
      jr_id: jrId
    };

    let config = {
      method: "POST",
      url: CHEMICAL_TAKEN,
      data: data
    };

    setLoading(true);
    axios
      .request(config)
      .then(response => {
        setLoading(false);
        console.log(response.data);
        if (final) {
          navigations("/job-receipt");
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleCapture = () => {
    if (captureRef.current) {
      html2canvas(captureRef.current)
        .then(canvas => {
          const imgData = canvas.toDataURL();
          const link = document.createElement("a");
          link.href = imgData;
          link.download = "screenshot.png";
          link.click();
        })
        .catch(error => {
          console.error("Error capturing image:", error);
        });
    }
  };

  const getChemicalSize = (ID, SNO) => {
    var locid = locationdata && locationdata.id ? locationdata.id : "";
    var data = {
      location_id: locid
    };
    let config = {
      method: "POST",
      url: GET_TANK,
      data: data
    };

    axios
      .request(config)
      .then(response => {
        console.log(response.data.data);
        var resdata = response.data.data;
        if (resdata.length > 0) {
          var data = resdata.filter(item => item.id === parseInt(ID));
          if (data && data.length > 0) {
            setReqChemical(data[0].chemicals);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const setRemovalDateFromInput = (inputDate) => {
    // ... your logic to calculate the removal date from the input date
    setRemovalDate(
      new Date(new Date(inputDate).getTime() +28 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0]
    );
  };

  const dateCurrent = (id) => {
    var today = new Date();
    if(id == 1){  
        return today.toISOString().split("T")[0];
    }else{
      var maxDate = new Date(today);
      maxDate.setDate(today.getDate() + 2);
      return maxDate.toISOString().split("T")[0]
    }
  }

 return(
    <div className="steper">
        {finished
        ?<Container>
            <Card
              className="job-form"
              ref={captureRef}
              style={{ background: "#fbfdff", padding: "10px" }}
            >
              <form className="form">
                <table className="service">
                  <tr>
                    <th>Service Type</th>
                    <th>
                      {serviceType}
                    </th>
                    <th>Date</th>
                    <th>
                      {date}
                    </th>
                  </tr>
                </table>
                <table className="cliend">
                  <tr>
                    <th colspan="2"> Client</th>
                  </tr>
                  <tr>
                    <td style={{ width: "5%" }}>Client Name</td>
                    <td>
                      {clientName}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "5%" }}>Branch Name</td>
                    <td>
                      {branchName}
                    </td>
                  </tr>
                </table>
                <div className="display">
                  <table className="tank">
                    <tr>
                      <th colspan="2">Tank</th>
                    </tr>
                    <tr>
                      <td>Tank Type</td>
                      <td>
                        {tankType}
                      </td>
                    </tr>
                    <tr>
                      <td>Tank SI No</td>
                      <td>
                        {tanksn}
                      </td>
                    </tr>
                    <tr>
                      <td>Temperature</td>
                      <td>
                        {temprature}
                      </td>
                    </tr>
                  </table>
                  <table className="detergent">
                    <tr>
                      <th colspan="3">Detergent</th>
                    </tr>
                    <tr>
                      <td>KG</td>
                      <td>Bags No</td>
                      <td>Total</td>
                    </tr>

                    {chemicladata &&
                      chemicladata.map(item =>
                        <tr>
                          <td>
                            {item.packetsize} Kg
                          </td>
                          <td>
                            {item.bagno}
                          </td>
                          <td>
                            {item.total}
                          </td>
                        </tr>
                      )}
                    <tr>
                      <td colspan="2">Total</td>
                      <td>
                        {overallTotal}kg
                      </td>
                    </tr>
                  </table>
                </div>
                <table className="Remark">
                  <tr>
                    <th colspan="2">Remark</th>
                  </tr>
                  <tr>
                    <td style={{ width: "5%" }}>
                      JR Service as {serviceType}
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Enter Your Remarks"
                        value={remark}
                        onChange={e => setRemark(e.target.value)}
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none",
                          outline: "none"
                        }}
                      />
                    </td>
                  </tr>
                </table>
                <table className="complete">
                  <tr>
                    <th />
                    <th>Completed By</th>
                    <th>Received By</th>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>
                      {accname}
                    </td>
                    <td>
                      {comname}
                    </td>
                  </tr>
                  <tr>
                    <td>Position</td>
                    <td>
                      {accposition}
                    </td>
                    <td>
                      {composition}
                    </td>
                  </tr>
                  <tr>
                    <td>Signature</td>
                    <td>
                      <img src={accsign} width={24} />
                    </td>
                    <td>
                      <img src={comsign} width={24} />
                    </td>
                  </tr>
                </table>
                <table className="checkbox">
                  <tr>
                    <th>For Office Use Only</th>
                  </tr>
                  <tr>
                    <input type="checkbox" />
                    <lable>C</lable>
                    <input type="checkbox" />
                    <lable>T</lable>
                    <input type="checkbox" />
                    <lable>F</lable>
                    <input type="checkbox" />
                    <lable>B</lable>
                    <input type="checkbox" />
                    <lable>O</lable>
                    <input type="checkbox" />
                    <lable>W</lable>
                  </tr>
                  <tr>
                    <td>Batch#</td>
                  </tr>
                </table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "15px"
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    onClick={() => handleCapture()}
                    color="primary"
                    style={{ height: "40px" }}
                  >
                    Print
                  </LoadingButton>

                  <LoadingButton
                    variant="contained"
                    onClick={() => AddJobReceipt()}
                    color="secondary"
                    style={{ height: "40px", marginLeft: "10px" }}
                    loading={loading}
                  >
                    Submit
                  </LoadingButton>
                </div>
              </form>
            </Card>
        </Container>
        :<Container>
            <Card style={{ background: "#fbfdff" }}>
              <div className="box">
                <div className="steps">
                  <ul className="nav">
                    {steps.map((step, i) => {
                     if(serviceType != "Removal" || step.key != '4'){
                      return (
                        <li
                          key={i}
                          className={`${activeStep.key === step.key? "active":""} ${step.isDone ? "done" : ""}`}
                        >
                          <div>
                            Step {step.key}
                            <br/>
                          <span>{step.label}</span>
                          </div>
                        </li>
                      );
                    } })}
                  </ul>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                {activeStep.key === 1
                  ? <Container style={{ width: "80%" }}>
                      <Title mb={4} className="text-orange">
                        Job Receipt Details
                      </Title>
                      <div className="mt-20">Service Type</div>
                      <TextField
                        id="service-type"
                        name="service-type"
                        fullWidth
                        // select={selected}
                        // disabled={disabled}
                        select
                        margin="dense"
                        focused={false}
                        SelectProps={{
                          native: true
                        }}
                        value={serviceType}
                        onChange={e => setServiceType(e.target.value)}
                        style={{ width: "60%" }}
                      >
                        <option value={"Demo"}> Demo </option>
                        <option value={"Installation"}> Installation </option>
                        <option value={"Service"}> Service </option>
                        <option value={"Removal"}> Removal </option>
                      </TextField>

                      <div className="mt-20">Date</div>
                      <TextField
                        fullWidth
                        autoFocus
                        type="date"
                        margin="dense"
                        focused={false}
                        // disabled={disabled}
                        onChange={e => { 
                        const inputDate = e.target.value;
                        setDate(inputDate);
                        setRemovalDateFromInput(inputDate);
                        }}
                        value={date}
                        style={{ width: "60%" }}
                        inputProps={{
                          min:dateCurrent(1)
                        }}
                      />

                      {serviceType == "Demo" &&
                        <div>
                          <div className="mt-20">Removal Date</div>
                          <TextField
                            fullWidth
                            autoFocus
                            type="date"
                            margin="dense"
                            focused={false}
                            onChange={e => setRemovalDate(e.target.value)}
                            value={removaldate}
                            disabled
                            style={{ width: "60%" }}
                          />
                        </div>}
                    </Container>
                  : activeStep.key === 2
                    ? <Container style={{ width: "80%" }}>
                        <Title mb={4} className="text-orange">
                          Client Details
                        </Title>

                        <div className="mt-20">Client Name</div>
                        <div style={{ display: "flex" }}>
                          
                          <TextField
                            id="client-name"
                            name="client-name"
                            select={selected}
                            disabled={disabled}
                            margin="dense"
                            focused={false}
                            SelectProps={{
                              native: true
                            }}
                            value={selected ? clientId : clientName}
                            onChange={e => {
                              setClientID(e.target.value);
                              var data = clientdata.filter(
                                item =>
                                  item.client_id === parseInt(e.target.value)
                              );
                              setClientName(data[0].client_name);
                              ListBranch(e.target.value);
                            }}
                            style={{ width: "60%" }}
                          >
                            {clientdata.map(item =>
                              <option value={item.client_id}>
                                {" "}{item.client_name}{" "}
                              </option>
                            )}
                          </TextField>
                          {serviceType == "Demo" &&
                          <StyledButton
                            variant="outlined"
                            color="secondary"
                            onClick={() => onClientOpen()}
                          >
                            Add Client
                          </StyledButton>}
                        </div>
                        <div className="mt-20">Branch Name</div>
                        <div style={{ display: "flex" }}>
                          <TextField
                            id="branch-name"
                            name="branch-name"
                            fullWidth
                            select={selected}
                            disabled={disabled}
                            margin="dense"
                            focused={false}
                            SelectProps={{
                              native: true
                            }}
                            value={selected ? branchId : branchName}
                            onChange={e => {
                              setBranchID(e.target.value);
                              var data = branchdata.filter(
                                item =>
                                  item.branch_id === parseInt(e.target.value)
                              );
                              setBranchName(data[0].branch_name);
                              getJRNo(clientId, e.target.value);
                            }}
                            style={{ width: "60%" }}
                          >
                            {branchdata.map(item =>
                              <option value={item.branch_id}>
                                {" "}{item.branch_name}{" "}
                              </option>
                            )}
                          </TextField>
                          {serviceType == "Demo" &&
                          <StyledButton
                            variant="outlined"
                            color="secondary"
                            onClick={() => onBranchOpen()}
                          >
                            Add Branch
                          </StyledButton> 
                          }                          
                        </div>
                        
                      <div className="mt-20">Job Receipt No</div>
                        
                        <TextField
                          fullWidth
                          autoFocus
                          type="text"
                          margin="dense"
                          focused={false}
                          disabled
                          onChange={e => setJrNo(e.target.value)}
                          value={jrno}
                          style={{ width: "60%" }}
                        />
                      </Container>
                      :activeStep.key === 3? 
                       <Container style={{ width: "80%" }}>
                          <Title mb={4} className="text-orange">
                            Tank Details
                          </Title>
                          <div className="mt-20">Tank Type</div>
                          <div class="d-flex"> 
                          <TextField
                            id="tank-type"
                            name="tank-type"
                            fullWidth
                            select={tankselected}
                            disabled={tankdisabled}
                            margin="dense"
                            focused={false}
                            SelectProps={{
                              native: true
                            }}
                            value={tankselected ? tankType : tanktypename}
                            onChange={e => {
                              var id = e.target.value
                              setTankType(id);
                              ListSerialNo(id);
                              var data = tankdata.filter(
                                item => item.id === parseInt(id)
                                );
                                if(data.length>0){
                                  setTanksn(data[0])
                                  setReqChemical(data[0].chemicals);
                                }
                                  
                            }}
                            style={{ width: "60%" }}>

                            <option value="">Select Tank</option>

                            {tankdata && tankdata.map(item =>
                                <option value={item.id}>
                                  {" "}{item.name}{" "}
                                </option>
                              )}
                          </TextField>
    
                          <StyledButton
                            variant="outlined"
                            color="secondary"
                            onClick={() => onTankopen()} >Add Tank Type</StyledButton>
                        </div>

                        <div className="mt-20">Tank Serial No</div>
                          <div class="d-flex"> 
                          <TextField
                            id="tank-sn"
                            name="tank-sn"
                            fullWidth
                            select={tankselected}
                            disabled={tankdisabled}
                            margin="dense"
                            focused={false}
                            SelectProps={{
                              native: true
                            }}
                            value={tankselected ? tanksn : tanksnoname}
                            onChange={e => {
                              setTankSN(e.target.value);
                              
                            }}
                            style={{ width: "60%" }}>
                            {serialnodata &&
                              serialnodata.map(item =>
                                <option value={item.id}>
                                  {" "}{item.serial_no}{" "}
                                </option>
                              )}
                          </TextField>
                          <StyledButton
                            variant="outlined"
                            color="secondary"
                            onClick={() => onTankdetailopen()}
                          >
                            Add Tank details
                          </StyledButton>
                        </div>
                          <div className="mt-20">Temperature</div>
                          <TextField
                            id="temprature"
                            name="temprature"
                            fullWidth
                            type="number"
                            margin="dense"
                            focused={false}
                            value={temprature}
                            onChange={e => setTemprature(e.target.value)}
                            style={{ width: "60%" }}
                          />
                        </Container>
                      : activeStep.key === 4 && serviceType != "Removal"
                        ? <Container style={{ width: "80%" }}>
                            <Title mb={4} className="text-orange">
                              Chemical Details
                            </Title>

                            <div className="flex-row">
                              <div style={{ width: "50%" }}>
                                <div className="mt-20">Required Chemicals</div>
                                <TextField
                                  fullWidth
                                  autoFocus
                                  type="text"
                                  margin="dense"
                                  focused={false}
                                  onChange={e => setReqChemical(e.target.value)}
                                  value={reqchemical}
                                  disabled
                                />
                              </div>
                            
                              <Button
                                sx={{ margin:'50px',height: '40px',}}
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleClickOpen()}
                              >
                                Add
                              </Button>
                            </div>

                            <div className="flex-between">
                              <Title mb={4} className="text-orange">
                                Details
                              </Title>
                            </div>

                            <Box overflow="auto">
                              <ProductTable>
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ px: 1 }} colSpan={1}>
                                      No
                                    </TableCell>
                                    <TableCell
                                      className="pl-0"
                                      sx={{ px: 3 }}
                                      colSpan={2}
                                    >
                                      Pack Size
                                    </TableCell>
                                    <TableCell
                                      className="pl-0"
                                      sx={{ px: 3 }}
                                      colSpan={2}
                                    >
                                      Request No
                                    </TableCell>
                                    <TableCell
                                      className="pl-0"
                                      sx={{ px: 3 }}
                                      colSpan={2}
                                    >
                                      Bag No
                                    </TableCell>
                                    <TableCell
                                      className="pl-0"
                                      sx={{ px: 0 }}
                                      colSpan={2}
                                    >
                                      Total
                                    </TableCell>
                                    <TableCell sx={{px:3}} colSpan={1} />
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {chemicladata.length > 0 &&
                                    chemicladata.map((item, index) =>
                                      <TableRow hover>
                                        <TableCell
                                          align="left"
                                          colSpan={1}
                                          sx={{
                                            px: 0,
                                            textTransform: "capitalize"
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          colSpan={2}
                                          sx={{
                                            px: 0,
                                            textTransform: "capitalize"
                                          }}
                                        >
                                        <a className="text-secondory text-blue">
                                            {item.packetsize}kg
                                        </a>
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          colSpan={2}
                                          sx={{ px: 0 }}
                                        >
                                          {item.nopackets}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          colSpan={2}
                                          sx={{
                                            px: 0,
                                            textTransform: "capitalize"
                                          }}
                                        >
                                          {item.bagno}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          colSpan={2}
                                          sx={{
                                            px: 0,
                                            textTransform: "capitalize"
                                          }}
                                        >
                                          {item.total}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          colSpan={1}
                                          sx={{
                                            px: 0,
                                            textTransform: "capitalize"
                                          }}
                                        >
                                          <IconButton>
                                            <Icon
                                              className="text-red"
                                              onClick={() =>
                                                deleteChemical(index)}
                                            >
                                              clear
                                            </Icon>
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                </TableBody>
                              </ProductTable>
                            </Box>
                            <div
                              className="flex-between"
                              style={{
                                borderTop: "1px solid gray",
                                padding: "0px 20px"
                              }} >
                              <Title mb={4}>Total</Title>
                              <Title mb={4} className="text-green">
                                {overallTotal} kg
                              </Title>
                            </div>
                          </Container>
                        : activeStep.key === 5 || (activeStep.key === 4 && serviceType == "Removal")
                          ? <Container style={{ width: "80%" }}>
                              <Title mb={4} className="text-orange">
                                Office Use
                              </Title>
                              <div
                                className="flex-row"
                                style={{ marginTop: "10px" }}
                              >
                                <div style={{ width: "50%" }}>
                                  <Title mb={4}>Completed By</Title>
                                  <div className="mt-20">Name</div>
                                  <TextField
                                    fullWidth
                                    autoFocus
                                    type="text"
                                    margin="dense"
                                    focused={false}
                                    onChange={e => setComName(e.target.value)}
                                    value={comname}
                                  />
                                  <div className="mt-20">Position</div>
                                  <TextField
                                    fullWidth
                                    autoFocus
                                    type="text"
                                    margin="dense"
                                    focused={false}
                                    onChange={e =>
                                      setComPosition(e.target.value)}
                                    value={composition}
                                  />

                                  <div className="mt-20">Sign Here</div>
                                  <SignatureCanvas
                                    ref={signatureRef}
                                    penColor="blue"
                                    canvasProps={{
                                      height: 150,
                                      className: "sign-canvas"
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end"
                                    }}
                                  >
                                    <button
                                      onClick={clearSignature}
                                      className="clr-btn"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                                <div
                                  style={{ marginLeft: "10px", width: "50%" }}
                                >
                                  <Title mb={4}>Accepted By</Title>
                                  <div className="mt-20">Name</div>
                                  <TextField
                                    fullWidth
                                    autoFocus
                                    type="text"
                                    margin="dense"
                                    focused={false}
                                    onChange={e => setAccName(e.target.value)}
                                    value={accname}
                                  />
                                  <div className="mt-20">Position</div>
                                  <TextField
                                    fullWidth
                                    autoFocus
                                    type="text"
                                    margin="dense"
                                    focused={false}
                                    onChange={e =>
                                      setAccPosition(e.target.value)}
                                    value={accposition}
                                  />

                                  <div className="mt-20">Sign Here</div>
                                  <SignatureCanvas
                                    ref={signatureRef1}
                                    penColor="blue"
                                    canvasProps={{
                                      height: 150,
                                      className: "sign-canvas"
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end"
                                    }}
                                  >
                                    <button
                                      onClick={clearSignature1}
                                      className="clr-btn"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Container>
                          : null}
              </div>
              
              <span
                style={{
                  textAlign: "center",
                  marginBottom: "5px",
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {error}
              </span>

              <CardFooder>
                <Box>
                  <Box pt={2}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleBack}
                      disabled={steps[0].key === activeStep.key}
                    >
                      Back
                    </Button>

                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      color="primary"
                      onClick={handleNext}
                    >
                      {steps[steps.length - 1].key !== activeStep.key
                        ? "Next"
                        : "Submit"}
                    </Button>
                  </Box>
                </Box>
              </CardFooder>
            </Card>
            <AddClient
              open={clientopen}
              handleClose={onClientClose}
              callBack={setCallBack}
              type={0}
              setType={setType}
            />

            <AddTank
              tankopen={tankopen}
              tankdetailopen={tankdetailopen}
              tanksdata ={tanksdata}
              handleTank = {onTankopen}
              handleTankD = {onTankdetailopen}
              callBack={setCallBack}
              type={0}
              setType={setType}
            />

            <AddBranch
              open={branchopen}
              handleClose={onBranchClose}
              callBack={setCallBack}
              type={0}
              setType={setType}
              cid={clientId}
            />

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title" style={{ width: "400px" }}>
                Add Chemical
              </DialogTitle>
              <DialogContent>
                <div className="mt-20">Add Packet Size</div>
                <TextField
                  id="tank-type"
                  name="tank-type"
                  fullWidth
                  select
                  margin="dense"
                  focused={false}
                  SelectProps={{
                    native: true
                  }}
                  value={packetsize}
                  onChange={e => {
                    setPacketSize(e.target.value);
                    var data = chemicallist.filter(
                      item => item.packet_size === e.target.value
                    );
                    setPacketStock(data[0].stock);
                  }}
                >
                  {chemicallist &&
                    chemicallist.map(item =>
                      <option value={item.packet_size}>
                        {item.packet_size} Kg
                      </option>
                    )}
                </TextField>

                <div className="mt-20">Required No of Packets</div>
                <TextField
                  fullWidth
                  autoFocus
                  id="number"
                  type="number"
                  margin="dense"
                  placeholder="Required No of Packets"
                  onChange={e => setNoPacktes(e.target.value)}
                  value={nopackets}
                  focused={false}
                />

                <div className="mt-20">Bag No</div>
                <TextField
                  fullWidth
                  autoFocus
                  id="number"
                  type="text"
                  margin="dense"
                  placeholder="Bag No"
                  onChange={e => setBagNo(e.target.value)}
                  value={bagno}
                  focused={false}
                />
              </DialogContent>

              <DialogActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => addChemical()}
                  color="primary"
                >
                  Submit
                </Button>
              </DialogActions>

              <span className="text-red" style={{ textAlign: "center" }}>
                {chemicalerror}
              </span>
            </Dialog>
        </Container>}
    </div>
);

}

export default AddJobReceipt;
