import React, { useState, useEffect } from 'react';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const events = [
  {
    title: "Event 1",
    date: new Date(2023, 6, 5),
    color: "#f9097d"
  },
  {
    title: "Event 2",
    date: new Date(2023, 6, 5),
    color: "#f9097d"
  },
  {
    title: "Event 1",
    date: new Date(2023, 6, 7),
    color: "#f9097d"
  },
  {
    title: "Event 2",
    date: new Date(2023, 6, 9),
    color: "#f9097d"
  },
  {
    title: "Event 1",
    date: new Date(2023, 6, 18),
    color: "#f9097d"
  },
  {
    title: "Event 2",
    date: new Date(2023, 6, 23),
    color: "#f9097d"
  },
];

const getColorCode = id => {
  switch (id) {
    case 1:
      return "#d94a28";
    case 2:
      return "#3e995b";
    case 3:
      return "#5844aa";
    default:
      return "crimson";
  }
};
const EventCalendar = ({ event }) => {

    const [selectedDate, setSelectedDate] = useState(null);

  const tileContent = ({ date }) => {
    // console.log(event)
    // console.log(date)

    const filteredEvents = event.filter((eve) => new Date(eve.date).getTime() === date.getTime())
    // const filteredEvents = event.filter(
    //   evn => date.toDateString() === new Date(evn.date).toDateString()
    // );

    // console.log(filteredEvents);
    // if (filteredEvents.length === 0) {
    //   return null;
    // }

    

    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent:"center" }}>
        {filteredEvents.map((event, index) =>
          <div
            key={index}
            style={{
              backgroundColor: "crimson",
              width: "5px",
              height: "5px",
              borderRadius: "90%",
              marginRight: "4px"
            }}
          />
        )}
      </div>
    );
  };

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
        return date.toDateString() === selectedDate?.toDateString() ? 'selected-date' : null;
      }
  
    if (view === 'month') {
      return date.toDateString() === new Date().toDateString() ? 'current-date' : null;
    }
    return null;
  };


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Calendar
      onChange={handleDateChange}
      tileContent={tileContent}
      tileClassName={tileClassName}
      // showNavigation={false}
      // showNeighboringMonth={true}
    />
  );
};

export default EventCalendar;
